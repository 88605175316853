import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExternalRatesQuery } from 'generated/graphql';
import { formatAmount, getCurrencySymbol, graphqlOnError, valueToCurrency } from 'utils';
import { useAuth, useSettings } from 'contexts';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { PlusIcon, Warning } from 'icons';
import { addDays, areIntervalsOverlapping, format, isAfter, isBefore, isSameDay, subDays } from 'date-fns';
import { DD_MMM_YYYY } from 'consts';
import { ActionsType, ExternalRateDataFragment, MemberSeniority, RateUnit, Role } from 'generated/types';
import { IconButton } from '@material-ui/core';
import { CreateAssignmentRateModal } from '../CreateAssignmentRateModal';

import styles from './styles.module.scss';

interface Props {
  contractId: string;
  projectId: string;
  role: Pick<Role, 'id' | 'name'> | null;
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  currency: string;
  unit: RateUnit;
  startDate?: string | Date;
  endDate?: string | Date;
}

interface EmptyRate {
  start_date: Date;
  end_date: Date;
}

const getPeriodLabel = (startDate?: string | Date, endDate?: string | Date): string => {
  const start = startDate ? new Date(startDate) : new Date();
  const end = endDate ? new Date(endDate) : null;

  if (!end || isSameDay(start, end)) {
    return `${format(start, DD_MMM_YYYY)}`;
  }

  return `${format(start, DD_MMM_YYYY)} - ${format(end!, DD_MMM_YYYY)}`;
};

export const AssignmentRate: FC<Props> = ({
  contractId,
  projectId,
  role,
  seniority,
  currency,
  unit,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { isFinancialsHidden } = useSettings();
  const { hasAccess, isPermissionsUpdatingLoading } = usePermissions({ projectId });

  const [newRate, setNewRate] = useState<EmptyRate | null>(null);
  const onCancelAddingRate = useCallback(() => setNewRate(null), []);

  const { data: { externalRates = [] } = {}, loading: ratesLoading } = useExternalRatesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId,
      contractId,
      isPast: true,
    },
    skip: isPermissionsUpdatingLoading || !hasAccess(ActionsType.ViewProjectRatecards),
  });

  const getIsRateInRange = (rateStart: string, rateEnd: string) => {
    const checkNoFilters = !startDate && !endDate;

    const checkFilterOnlyByAssignmentEndDate =
      !startDate && endDate && isBefore(new Date(rateStart), new Date(endDate));

    const checkFilterOnlyByAssignmentStartDate =
      !endDate && startDate && (!rateEnd || isAfter(new Date(rateEnd), new Date(startDate)));

    const checkFilterRateWithoutEndDateInAssignmentRange = endDate
      ? !rateEnd &&
        startDate &&
        endDate &&
        isAfter(new Date(rateStart), new Date(startDate)) &&
        isBefore(new Date(rateStart), new Date(endDate))
      : !rateEnd && startDate && endDate && isAfter(new Date(rateStart), new Date(startDate));

    const checkFilterRateWithoutEndDateAfterAssignmentStart =
      !rateEnd && startDate && endDate && isBefore(new Date(rateStart), new Date(endDate));

    const checkFilterAssignmentIntervalOverlapRate =
      startDate &&
      endDate &&
      rateEnd &&
      isBefore(new Date(startDate), new Date(endDate)) &&
      areIntervalsOverlapping(
        { start: new Date(startDate), end: new Date(endDate) },
        {
          start: new Date(rateStart),
          end: new Date(rateEnd),
        },
      );

    return (
      checkNoFilters ||
      checkFilterOnlyByAssignmentEndDate ||
      checkFilterOnlyByAssignmentStartDate ||
      checkFilterRateWithoutEndDateInAssignmentRange ||
      checkFilterRateWithoutEndDateAfterAssignmentStart ||
      checkFilterAssignmentIntervalOverlapRate
    );
  };

  const roleRates = useMemo(
    () =>
      externalRates.filter((rate) => {
        const isSameRole = rate.roleId === role?.id;
        const isSameSeniority = seniority?.id ? rate.seniorityId === seniority.id : !rate.seniorityId;

        return isSameRole && isSameSeniority;
      }),
    [externalRates, role, seniority],
  );

  const rates: ExternalRateDataFragment[] = roleRates
    .filter((rate) => getIsRateInRange(rate.start_date, rate.end_date))
    .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

  const ratesPeriods = rates.reduce<(ExternalRateDataFragment | EmptyRate)[]>((acc, rate, currentIndex) => {
    let result: (ExternalRateDataFragment | EmptyRate)[] = [];
    const isFirstRate = !currentIndex;
    if (isFirstRate) {
      const rateStart = rate.start_date;
      const isNoRatesFromStart =
        !startDate || !rateStart
          ? false
          : !isSameDay(new Date(rateStart), new Date(startDate)) && isAfter(new Date(rateStart), new Date(startDate));
      const emptyFirstRate = {
        start_date: startDate ? new Date(startDate) : new Date(),
        end_date: subDays(new Date(rateStart), 1),
      };
      result = isNoRatesFromStart ? [emptyFirstRate] : [];
    }

    const prevRate = acc[acc.length - 1];
    const isEmptyBetweenRates = !prevRate
      ? false
      : !isSameDay(addDays(new Date(prevRate.end_date), 1), new Date(rate.start_date));
    const emptyRate = {
      start_date: addDays(new Date(prevRate?.end_date), 1),
      end_date: subDays(new Date(rate.start_date), 1),
    };
    result = isEmptyBetweenRates ? [...result, emptyRate, rate] : [...result, rate];

    const isLastRate = currentIndex === rates.length - 1;
    if (isLastRate) {
      const rateEnd = rate.end_date;
      const isNoRatesToEnd =
        !endDate || !rateEnd
          ? false
          : !isSameDay(new Date(endDate), new Date(rateEnd)) && isAfter(new Date(endDate), new Date(rateEnd));

      const emptyLastRate = {
        start_date: addDays(new Date(rate.end_date), 1),
        end_date: endDate ? new Date(endDate) : new Date(),
      };
      result = isNoRatesToEnd ? [...result, emptyLastRate] : [...result];
    }

    return [...acc, ...result];
  }, []);

  if (ratesLoading || !hasAccess(ActionsType.ViewProjectRatecards)) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      <span>{t('rateCard.roleRates')}</span>
      {ratesPeriods.reverse().map((rate) => {
        const startDateValue =
          startDate && isAfter(new Date(startDate), new Date(rate.start_date)) ? startDate : rate.start_date;
        const endDateValue =
          endDate && (!rate.end_date || isAfter(new Date(rate.end_date), new Date(endDate))) ? endDate : rate.end_date;
        const onOpenAddingRate = () => !('rate_amount' in rate) && setNewRate(rate);

        return (
          <div key={rate.start_date} className="flex flex-column gap-4">
            {'rate_amount' in rate ? (
              <span className={styles.rateLabel}>
                {getCurrencySymbol(currency)}
                {formatAmount(valueToCurrency(rate.rate_amount), isFinancialsHidden)} / {rate.unit}.
              </span>
            ) : (
              <span className={styles.rateLabel}>
                <Warning className="mr-4" />
                {t('rateCard.noRate')}
                <IconButton className={styles.button} size="small" onClick={onOpenAddingRate}>
                  <PlusIcon className={styles.icon} />
                </IconButton>
              </span>
            )}
            <span className={styles.periodLabel}>
              {endDateValue
                ? getPeriodLabel(startDateValue, endDateValue)
                : `${getPeriodLabel(startDateValue)} - ${t('rateCard.noEndDate')}`}
            </span>
          </div>
        );
      })}
      {!rates.length && (
        <span className={styles.periodLabel}>
          <Warning className="mr-4" />
          {t('rateCard.noRates')}.
        </span>
      )}

      <CreateAssignmentRateModal
        isOpen={!!newRate}
        onClose={onCancelAddingRate}
        rates={roleRates}
        projectId={projectId}
        contractId={contractId}
        role={role}
        seniority={seniority}
        currency={currency}
        start_date={newRate?.start_date}
        end_date={newRate?.end_date}
        unit={unit}
      />
    </div>
  );
};
