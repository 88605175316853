import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommissionDataFragment } from 'generated/types';
import { graphqlOnError } from 'utils';
import { useDeleteCommissionMutation, useEditCommissionMutation } from 'generated/graphql';
import { toast } from 'react-toastify';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ViewCommission } from '../ViewCommission';
import { CommissionForm, FormValues } from '../CommissionForm';
import { getCommissionsRefetchQueries } from '../../utils/getCommissionsRefetchQueries';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  contractId: string;
  projectId: string;
  projectCommission: CommissionDataFragment;
  onToggleEditing?: (id: string) => void;
}

export const CommissionItem: FC<Props> = ({ contractId, projectId, projectCommission, onToggleEditing }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isEdit, setIsEdit] = useState(false);
  const { id, commission, effectiveFrom } = projectCommission;

  const [deleteProjectCommission] = useDeleteCommissionMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.commissions.deletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteCommission) });
        cache.gc();
      }
    },
    variables: { companyId: userData!.company.id, commissionId: id, projectId },
    refetchQueries: getCommissionsRefetchQueries(userData!.company.id, projectId, contractId),
  });

  const [editProjectCommission, { loading }] = useEditCommissionMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.commissions.editSuccessfully'));
      setIsEdit(false);
      onToggleEditing?.(id);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: getCommissionsRefetchQueries(userData!.company.id, projectId, contractId),
  });

  const handleEdit = useCallback(async (values: FormValues) => {
    await editProjectCommission({
      variables: {
        data: {
          effectiveFrom: format(new Date(values?.effectiveFrom || ''), DEFAULT_DATE_FORMAT),
          commission: +(values?.commission || 0),
        },
        companyId: userData!.company.id,
        commissionId: id,
        projectId,
      },
    });
  }, []);

  return isEdit ? (
    <CommissionForm
      onSubmit={handleEdit}
      onCancel={() => {
        setIsEdit(false);
        onToggleEditing?.(id);
      }}
      loading={loading}
      initialValue={{
        commission,
        effectiveFrom,
      }}
    />
  ) : (
    <ViewCommission
      commission={commission}
      effectiveFrom={effectiveFrom}
      onDelete={() => deleteProjectCommission()}
      onEdit={() => {
        setIsEdit(true);
        onToggleEditing?.(id);
      }}
    />
  );
};
