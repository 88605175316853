import { useExportReportLazyQuery, useGenerateXeroInvoiceLazyQuery } from 'generated/graphql';
import { downloadExcelXLS, graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { ExportTarget, ReportDataFragment } from 'generated/types';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Props {
  onCompleted?: () => void;
  reportData?: ReportDataFragment | null;
}

export const useExportReport = ({ onCompleted, reportData }: Props) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [exportReportFile, { loading: loadingExportReport }] = useExportReportLazyQuery({
    onCompleted() {
      onCompleted?.();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  const skip = !reportData?.id || !reportData?.contract?.projectId;
  const exportReport = skip
    ? () => void 0
    : async () => {
        const { data } = await exportReportFile({
          variables: {
            companyId: userData!.company.id,
            reportId: reportData?.id || '',
            projectId: reportData?.contract?.projectId || '',
            exportType: ExportTarget.File,
          },
        });

        if (data?.exportReport) {
          mixpanel.track('Report exported', { Type: 'billing-report', From: 'report-modal', Target: 'excel' });
          downloadExcelXLS(data.exportReport, reportData?.title || '');
        }
      };

  const exportInGoogleReport = async () => {
    toast.success(t('billing.export.googleSheetsMessage'));
    const { data } = await exportReportFile({
      variables: {
        companyId: userData!.company.id,
        reportId: reportData?.id || '',
        projectId: reportData?.contract?.projectId || '',
        exportType: ExportTarget.Google,
      },
    });

    mixpanel.track('Report exported', { Type: 'billing-report', From: 'report-modal', Target: 'google-spreadsheets' });
    window.open(data?.exportReport, '_blank');
  };

  const [generateXeroInvoice] = useGenerateXeroInvoiceLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  const exportInXeroInvoice = async () => {
    toast.success(t('billing.export.xeroInvoiceMessage'));
    const { data } = await generateXeroInvoice({
      variables: {
        companyId: userData!.company.id,
        reportId: reportData?.id || '',
        projectId: reportData?.contract?.projectId || '',
      },
    });

    if (!data?.generateXeroInvoice) return;

    mixpanel.track('Report exported', { Type: 'billing-report', From: 'report-modal', Target: 'xero' });
    window.open(data?.generateXeroInvoice, '_blank');
  };

  return {
    exportReport,
    exportInGoogleReport,
    exportInXeroInvoice,
    loadingExportReport,
  };
};
