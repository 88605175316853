import { SortByFn } from 'react-table';
import { useCallback } from 'react';
import {
  AssignmentCalculationType,
  ContractAssignmentTimeLogDataFragment,
  MemberContractDataFragment,
  TimeLogDataFragment,
} from 'generated/types';
import { isAfter } from 'date-fns';

type ProjectTimeLogData = {
  member_id: string;
  assignment?: ContractAssignmentTimeLogDataFragment;
};
type MemberTimeLogData = {
  contract:
    | (MemberContractDataFragment & {
        bill_amount_calculation_type: AssignmentCalculationType;
      })
    | null;
};
export const useTimeLogTableSorts = <T extends TimeLogDataFragment>() => {
  const sortDateType = useCallback<SortByFn<T>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.date), new Date(rowB.original.date)) ? 1 : -1;
  }, []);

  const sortMemberType = useCallback<SortByFn<T & ProjectTimeLogData>>((rowA, rowB) => {
    return `${rowA.original.assignment?.member.first_name} ${rowA.original.assignment?.member.last_name}`.localeCompare(
      `${rowB.original.assignment?.member.first_name} ${rowB.original.assignment?.member.last_name}`,
      'en',
    );
  }, []);

  const sortContractType = useCallback<SortByFn<T & MemberTimeLogData>>((rowA, rowB) => {
    if ('contract' in rowA.original && 'contract' in rowB.original) {
      return (rowA.original?.contract?.name || '').localeCompare(rowB.original?.contract?.name || '', 'en');
    }

    return 0;
  }, []);

  const sortNotesType = useCallback<SortByFn<T>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original?.notes) {
      return desc ? -1 : 1;
    }
    if (!rowB.original?.notes) {
      return desc ? 1 : -1;
    }

    return (rowA.original?.notes || '').localeCompare(rowB.original?.notes || '', 'en');
  }, []);

  return {
    sortDateType,
    sortNotesType,
    sortMemberType,
    sortContractType,
  };
};
