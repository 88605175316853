import { addTimezoneOffset, currencyToValue } from 'utils';
import { ContractFormValues } from 'components';
import { ProjectType, RateUnit } from 'generated/types';

export const getContractChangeData = ({
  type,
  fee_amount,
  unit,
  fee_currency,
  start_date,
  end_date,
  costBudgetAmount,
  billableLeaves,
  overtimeMultiplier,
  ...values
}: ContractFormValues) => {
  const isTimeAndMaterialOrRetainerType = type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer;

  return {
    ...values,
    start_date: addTimezoneOffset(new Date(start_date)),
    end_date: addTimezoneOffset(new Date(end_date)),
    type: type.id,
    unit: isTimeAndMaterialOrRetainerType ? unit.id : RateUnit.Hour,
    billableLeaves: isTimeAndMaterialOrRetainerType ? billableLeaves : false,
    overtimeMultiplier: isTimeAndMaterialOrRetainerType && overtimeMultiplier ? Number(overtimeMultiplier) : undefined,
    ...(type.id === ProjectType.FixedPrice
      ? {
          fee_amount: currencyToValue(fee_amount || 0),
          feeCurrencyId: fee_currency.id,
        }
      : { costBudgetAmount: !!costBudgetAmount ? currencyToValue(costBudgetAmount) : null }),
    ...(isTimeAndMaterialOrRetainerType && {
      feeCurrencyId: fee_currency.id,
    }),
    commission: +(values?.commission || 0),
    project: undefined,
  };
};
