import React, { FC, memo, ReactElement } from 'react';
import { Scalars } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Avatar, Link, SIZES } from 'components';
import {
  formatCurrency,
  getAcronym,
  getAllocationTimeAmount,
  replaceAmountWithHiddenSymbol,
  valueToCurrency,
} from 'utils';
import { Field } from './Field';
import { useSettings } from 'contexts';
import { ItemFieldTooltip } from './ItemFieldsTooltip';

import styles from './styles.module.scss';

interface Props {
  data: Scalars['JSON'];
  currencyCode?: string;
}

export const ItemDisplayFields: FC<Props> = memo(({ data, currencyCode }) => {
  const { t } = useTranslation();
  const { isFinancialsHidden } = useSettings();

  const shownProperty = {
    email: 'email',
    title: 'title',
    jobTitle: 'job_title',
    contractName: 'contractName',
    projectRole: 'role',
    assignmentRole: 'assignmentRole',
    client: 'client',
    seniority: 'seniority',
    assignmentSeniority: 'assignmentSeniority',
    specialization: 'specialization',
    leaveType: 'leaveType',
    documentName: 'documentName',
  };

  const fields = Object.entries(shownProperty).reduce<ReactElement[]>((acc, [key, value]) => {
    if (!data[value]) {
      return acc;
    }

    const field = (
      <Field key={`${key}-${data[value]}`} label={t(`activityHistory.logLabels.${key}`)} value={data[value]} />
    );

    return [...acc, field];
  }, []);

  const projectAssignmentId = data['assignmentId'] && data['projectName'];
  const projectAssignment = projectAssignmentId && (
    <Field
      label={t('activityHistory.logLabels.project')}
      value={
        <Link disableNavigation to="" className={styles.link} color="inherit">
          <span className={styles.projectColor} style={{ backgroundColor: data['projectColor'] }} />
          {data['projectName']}
        </Link>
      }
    />
  );

  const feeCurrency = data['feeCurrency'] && (
    <Field label={t('activityHistory.logLabels.feeCurrency')} value={data['feeCurrency']?.toUpperCase()} />
  );

  const startDate = data['start_date'] || data['startDate'];
  const endDate = data['end_date'] || data['endDate'];
  const startEndDate = startDate && (
    <Field
      label={endDate ? t('activityHistory.logLabels.startEndDate') : t('activityHistory.logLabels.startDate')}
      value={startDate && format(new Date(startDate), 'dd/MM/yyyy')}
      newValue={endDate && format(new Date(endDate), 'dd/MM/yyyy')}
    />
  );

  const joinDate = data['join_date'] && (
    <Field label={t('activityHistory.logLabels.joinDate')} value={format(new Date(data['join_date']), 'dd/MM/yyyy')} />
  );

  const leaveDate = data['leave_date'] && (
    <Field
      label={t('activityHistory.logLabels.leaveDate')}
      value={format(new Date(data['leave_date']), 'dd/MM/yyyy')}
    />
  );

  const effectiveFromDate = data['effectiveFrom'] && (
    <Field
      label={t('activityHistory.logLabels.effectiveFrom')}
      value={format(new Date(data['effectiveFrom']), 'dd/MM/yyyy')}
    />
  );

  const date = data['date'] && (
    <Field label={t('activityHistory.logLabels.date')} value={format(new Date(data['date']), 'dd/MM/yyyy')} />
  );

  const paymentDate = data['paymentDate'] && (
    <Field
      label={t('activityHistory.logLabels.paymentDate')}
      value={format(new Date(data['paymentDate']), 'dd/MM/yyyy')}
    />
  );

  const reportingTo = data['reportingToFirstName'] && (
    <Field
      label={t('activityHistory.logLabels.reportingTo')}
      value={
        <>
          <Avatar
            avatarTitle={getAcronym(data['reportingToFirstName'], data['reportingToLastName'])}
            size={SIZES.xs}
            className="mr-4"
            color={data['reportingToColor']}
          />
          <ItemFieldTooltip>{`${data['reportingToFirstName']} ${data['reportingToLastName']}`}</ItemFieldTooltip>
        </>
      }
    />
  );

  const pm = data['pmFirstName'] && (
    <Field
      label={t('activityHistory.logLabels.pm')}
      value={
        <>
          <Avatar
            avatarTitle={getAcronym(data['pmFirstName'], data['pmLastName'])}
            size={SIZES.xs}
            className="mr-4"
            color={data['pmColor']}
          />
          <ItemFieldTooltip>{`${data['pmFirstName']} ${data['pmLastName']}`}</ItemFieldTooltip>
        </>
      }
    />
  );

  const allocationTime = data['allocation_time_amount'] || data['allocationTimeAmount'];
  const allocation = allocationTime && (
    <Field
      label={t('activityHistory.logLabels.allocation')}
      value={`${getAllocationTimeAmount(allocationTime)} ${t('activityHistory.hrsPerDay')}`}
    />
  );

  const type = (data['typeName'] || data['type']) && (
    <Field label={t('activityHistory.logLabels.type')} value={data['typeName'] || t(`projectType.${data['type']}`)} />
  );

  const pmFinAccess = data['pmFinAccess'] && (
    <Field
      label={t('activityHistory.logLabels.pmFinAccess')}
      value={t(`activityHistory.financialAccess.${data['pmFinAccess']}`)}
    />
  );

  const dataCurrency = (data['currency'] && data['currency']['code']) || data['currency'] || currencyCode;
  const amount = (data['rate_amount'] || data['amount']) && (
    <Field
      label={t('activityHistory.logLabels.amount')}
      value={formatCurrency(valueToCurrency(data['rate_amount'] || data['amount']), dataCurrency, isFinancialsHidden)}
    />
  );

  const billableAmount = data['billable_amount'] ? (
    <Field
      label={t('activityHistory.logLabels.billableAmount')}
      value={formatCurrency(valueToCurrency(data['billable_amount']), dataCurrency, isFinancialsHidden)}
    />
  ) : (
    ''
  );

  const isBillable = typeof data['is_billable'] === 'boolean' && (
    <Field
      label={t('activityHistory.logLabels.isBillable')}
      value={t(`activityHistory.condition.${data['is_billable']}`)}
    />
  );

  const nonBillable = typeof data['nonBillable'] === 'boolean' && (
    <Field
      label={t('activityHistory.logLabels.nonBillable')}
      value={t(`activityHistory.condition.${data['nonBillable']}`)}
    />
  );

  const billable = typeof data['billable'] === 'boolean' && (
    <Field
      label={t('activityHistory.logLabels.isBillable')}
      value={t(`activityHistory.condition.${data['billable']}`)}
    />
  );

  const billingType = data['bill_amount_calculation_type'] && (
    <Field
      label={t('activityHistory.logLabels.unit')}
      value={t(`activityHistory.billingType.${data['bill_amount_calculation_type']}`)}
    />
  );

  const billingCalculationType = data['calculationType'] && (
    <Field
      label={t('activityHistory.logLabels.billingType')}
      value={t(`activityHistory.billingType.${data['calculationType']}`)}
    />
  );

  const commission = !!data['commission'] && (
    <Field
      label={t('activityHistory.logLabels.commission')}
      value={`${replaceAmountWithHiddenSymbol(valueToCurrency(data['commission']), isFinancialsHidden)}%`}
    />
  );

  const unit = data['unit'] && (
    <Field label={t('activityHistory.logLabels.unit')} value={t(`activityHistory.unit.${data['unit']}`)} />
  );

  const employmentType = data['employment_type'] && (
    <Field label={t('activityHistory.logLabels.employeeType')} value={t(`employmentType.${data['employment_type']}`)} />
  );

  const capacity = !!data['capacity'] && (
    <Field
      label={t('activityHistory.logLabels.capacity')}
      value={`${data['capacity']} ${t('activityHistory.hrsPerDay')}`}
    />
  );

  const notes = data['notes'] && (
    <Field label={t('activityHistory.logLabels.notes')} value={<ItemFieldTooltip>{data['notes']}</ItemFieldTooltip>} />
  );

  return (
    <>
      {projectAssignment}
      {fields}
      {feeCurrency}
      {employmentType}
      {startEndDate}
      {reportingTo}
      {pm}
      {type}
      {pmFinAccess}
      {date}
      {paymentDate}
      {joinDate}
      {leaveDate}
      {effectiveFromDate}
      {allocation}
      {amount}
      {billableAmount}
      {isBillable}
      {nonBillable}
      {billable}
      {billingType}
      {billingCalculationType}
      {commission}
      {unit}
      {capacity}
      {notes}
    </>
  );
});

ItemDisplayFields.displayName = 'ItemDisplayFields';
