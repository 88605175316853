import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MilestoneDataFragment } from 'generated/types';
import { graphqlOnError, updateProjectTotalByMilestones } from 'utils';
import {
  ContractAssignmentsListDocument,
  MilestonesDocument,
  MilestonesSectionDocument,
  useDeleteMilestoneMutation,
  useEditMilestoneMutation,
} from 'generated/graphql';
import { toast } from 'react-toastify';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ViewMilestone } from '../ViewMilestone';
import { FormValues, MilestoneForm } from '../MilestoneForm';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  milestone: MilestoneDataFragment;
  onToggleEditing?: (id: string) => void;
}

export const MilestoneItem: FC<Props> = ({ milestone, onToggleEditing }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isEdit, setIsEdit] = useState(false);
  const { id, amount, notes, paymentDate, title, projectId, contractId } = milestone;

  const [deleteProjectMilestone] = useDeleteMilestoneMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.milestones.deletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteMilestone) });
        cache.gc();
        updateProjectTotalByMilestones({ contractId: contractId || '', companyId: userData!.company.id, cache });
      }
    },
    variables: { companyId: userData!.company.id, milestoneId: id },
    refetchQueries: [
      ContractAssignmentsListDocument,
      {
        query: MilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: true,
          projectId,
          contractId,
        },
      },
      {
        query: MilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: false,
          projectId,
          contractId,
        },
      },
    ],
  });

  const [editProjectMilestone, { loading }] = useEditMilestoneMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.milestones.editSuccessfully'));
      setIsEdit(false);
      onToggleEditing?.(id);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        updateProjectTotalByMilestones({ contractId: contractId || '', companyId: userData!.company.id, cache });
      }
    },
    refetchQueries: [
      {
        query: MilestonesDocument,
        variables: {
          companyId: userData!.company.id,
          contractId,
        },
      },
      {
        query: MilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: true,
          projectId,
          contractId,
        },
      },
      {
        query: MilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: false,
          projectId,
          contractId,
        },
      },
      ContractAssignmentsListDocument,
    ],
  });

  const handleEdit = useCallback(async (values: FormValues) => {
    await editProjectMilestone({
      variables: {
        data: {
          title: values?.title || '',
          paymentDate: format(new Date(values?.paymentDate || ''), DEFAULT_DATE_FORMAT),
          notes: values?.notes,
          amount: +(values?.amount || 0),
        },
        companyId: userData!.company.id,
        milestoneId: id,
      },
    });
  }, []);

  return isEdit ? (
    <MilestoneForm
      onSubmit={handleEdit}
      onCancel={() => {
        setIsEdit(false);
        onToggleEditing?.(id);
      }}
      loading={loading}
      initialValue={{
        title,
        amount,
        paymentDate,
        notes: notes || '',
      }}
    />
  ) : (
    <ViewMilestone
      amount={amount}
      notes={notes}
      paymentDate={paymentDate}
      title={title}
      onDelete={() => deleteProjectMilestone()}
      onEdit={() => {
        setIsEdit(true);
        onToggleEditing?.(id);
      }}
    />
  );
};
