import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AbsoluteSpinner,
  AssignmentForm,
  AssignmentFormValues,
  BillingTypes,
  EmptyState,
  RequestInfoModal,
} from 'components';
import { useAssignmentQuery } from 'generated/graphql';
import { getAssignmentRequestFormattedFormData, graphqlOnError, removeUTCTimezone } from 'utils';
import { useAuth } from 'contexts';
import { useCreateRequest, useErrorMsgBuilder, useIsOpen, usePermissions } from 'hooks';
import { ActionsType, RequestAction, RequestDataFragment, RequestDomains } from 'generated/types';
import { addTimezoneOffset } from 'utils/date';
import { Button, IconButton, TextField } from '@material-ui/core';
import { ArrowDown, PlusIcon } from 'icons';
import styles from './styles.module.scss';
import { localStorageManager } from 'services';
import clsx from 'clsx';
import { IS_HIDE_REQUEST_CONFIRM } from 'consts';

interface CreateAssignmentProps {
  id: string;
  memberId?: string;
  projectId?: string;
  onCancel: () => void;
  onHide?: () => void;
  onCreate?: () => void;
  memberDisabled?: boolean;
  projectDisabled?: boolean;
  submitLabel?: string;
}

const INITIAL_ALLOCATION_VALUE = 8.0;
const MINUTES_IN_HOUR = 60;

export const CreateEditAssignmentRequest: FC<CreateAssignmentProps> = ({
  id,
  memberId,
  projectId,
  onCancel,
  onHide,
  onCreate,
  memberDisabled,
  projectDisabled,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [note, setNote] = useState<string>('');
  const [isShowNote, onShowNote, onHideNote] = useIsOpen(false);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen(false);

  const { data: { assignment } = {}, loading: assignmentLoading } = useAssignmentQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company?.id,
      assignmentId: id!,
    },
    skip: !userData?.company?.id || !id,
  });

  const initialValue = useMemo<AssignmentFormValues>(
    () => ({
      member: assignment?.member || null,
      project: assignment?.contract
        ? { id: assignment?.contract.projectId, name: assignment?.contract.projectName }
        : null,
      contract: assignment?.contract || null,
      role: assignment?.role || null,
      seniority:
        assignment?.seniority && assignment!.seniorityId
          ? { name: assignment!.seniority, id: assignment!.seniorityId }
          : null,
      startDate: assignment?.startDate ? removeUTCTimezone(assignment.startDate) : new Date(),
      endDate: assignment?.endDate ? removeUTCTimezone(assignment.endDate) : new Date(),
      billingType: BillingTypes.find((type) => type.id === assignment?.bill_amount_calculation_type) || BillingTypes[0],
      allocationTimeAmount: assignment?.allocationTimeAmount
        ? Math.round(assignment.allocationTimeAmount * 100) / 100
        : INITIAL_ALLOCATION_VALUE,
      billable: assignment?.billable ?? false,
    }),
    [assignment],
  );

  const previousValue = useMemo<RequestDataFragment['data']>(() => {
    return {
      memberId: assignment?.member.id ?? '',
      memberFirstName: assignment?.member.first_name ?? '',
      memberLastName: assignment?.member.last_name ?? '',
      memberColor: assignment?.member.color ?? '',
      roleId: assignment?.role?.id ?? '',
      role: assignment?.role?.name ?? '',
      projectId: assignment?.contract?.projectId ?? '',
      project: assignment?.contract?.projectName ?? '',
      projectColor: assignment?.contract?.projectColor ?? '',
      contractId: assignment?.contract?.id ?? '',
      contractName: assignment?.contract?.name ?? '',
      seniorityId: assignment?.seniorityId ?? '',
      seniority: assignment?.seniority ?? '',
      allocationTimeAmount:
        Math.round((assignment?.allocationTimeAmount ?? INITIAL_ALLOCATION_VALUE) * MINUTES_IN_HOUR * 100) / 100,
      startDate: addTimezoneOffset(new Date(assignment?.startDate)),
      endDate: addTimezoneOffset(new Date(assignment?.endDate)),
      timeTracking: false,
      calculationType: assignment?.bill_amount_calculation_type,
      billable: assignment?.billable ?? false,
    };
  }, [assignment]);

  const handleCloseConfirm = () => {
    onCloseConfirm();
    localStorageManager.setItem(IS_HIDE_REQUEST_CONFIRM, true);
  };

  const [createAssignmentRequest, { loading }] = useCreateRequest({
    onCompleted: () => {
      const isShowConfirm = !localStorageManager.getItem(IS_HIDE_REQUEST_CONFIRM);
      onCreate?.();
      onHide?.();
      isShowConfirm ? onOpenConfirm() : onCancel();
    },
  });

  const onCreateAssignment = useCallback(
    (formValues: AssignmentFormValues) => {
      const data = {
        variables: {
          data: {
            note: note,
            data: previousValue,
            changes: getAssignmentRequestFormattedFormData(formValues),
          },
          requestAction: RequestAction.Update,
          entityId: id,
          entityType: RequestDomains.Assignment,
          companyId: userData!.company.id,
        },
      };
      createAssignmentRequest(data);
    },
    [note, previousValue],
  );

  if (isPermissionsLoading || assignmentLoading) {
    return <AbsoluteSpinner />;
  }

  if (!hasAccess(ActionsType.SubmitAssignmentRequest)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <AssignmentForm
        id={id}
        memberId={memberId}
        projectId={projectId}
        onCancel={onCancel}
        onSubmit={onCreateAssignment}
        initialValue={initialValue}
        memberDisabled={memberDisabled}
        projectDisabled={projectDisabled}
        submitLabel={t('request.button.sendRequest')}
        submitDisabled={loading}
        showRequestDescription
        additionalControlsContent={
          <div className={clsx('relative', !isShowNote && 'd-none')}>
            <TextField
              className={styles.noteTextarea}
              id="note-text-field"
              placeholder={t('request.notePlaceholder')}
              multiline
              rows={5}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <IconButton className={styles.hideNoteButton} onClick={onHideNote}>
              <ArrowDown />
            </IconButton>
          </div>
        }
        additionalButton={
          <Button
            variant="text"
            className={clsx(styles.noteButton, isShowNote && 'd-none')}
            classes={{
              startIcon: styles.addIcon,
            }}
            startIcon={note ? <ArrowDown className={styles.showNoteIcon} /> : <PlusIcon />}
            onClick={onShowNote}
          >
            {t('request.button.noteForAdmin')}
          </Button>
        }
      />

      <RequestInfoModal
        isOpen={isOpenConfirm}
        onClose={() => {
          handleCloseConfirm();
          onCancel();
        }}
      />
    </>
  );
};
