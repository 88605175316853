import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import { ConfirmModal, DatePicker } from 'components';
import { useErrorMsgBuilder, useIsOpen, useArchiveContract } from 'hooks';
import { useAssignmentsQuery, useEditAssignmentsMutation } from 'generated/graphql';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { format } from 'date-fns';

import styles from './styles.module.scss';

interface Props {
  id: string;
  projectId: string;
  isActiveAssignment: boolean;
  isOpenConfirm: boolean;
  onCloseConfirm: () => void;
}

export const ArchiveContractConfirm: FC<Props> = ({
  id,
  projectId,
  isActiveAssignment,
  isOpenConfirm,
  onCloseConfirm,
}) => {
  const { t } = useTranslation();
  const handleContractArchive = useArchiveContract();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isOpenArchiveConfirm, onOpenArchiveConfirm, onCloseArchiveConfirm] = useIsOpen();
  const [isOpenEditAssignmentModal, onOpenEditAssignmentModal, onCloseEditAssignmentModal] = useIsOpen();
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (isOpenConfirm) {
      isActiveAssignment ? onOpenEditAssignmentModal() : onOpenArchiveConfirm();
    }
  }, [isOpenConfirm]);

  const { data: { assignments = [] } = {} } = useAssignmentsQuery({
    variables: {
      companyId: userData!.company.id,
      data: {
        contractId: id,
      },
      filters: {
        isActive: true,
      },
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    skip: !isOpenEditAssignmentModal,
  });

  const [editAssignments, { loading }] = useEditAssignmentsMutation({
    onCompleted() {
      toast.success(t('contracts.assignmentUpdatedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const onEditAssignmentsEndDate = () => {
    if (!endDate) {
      return;
    }

    editAssignments({
      variables: {
        assignmentsIds: assignments.map(({ id }) => id),
        companyId: userData!.company.id,
        data: {
          endDate: format(endDate, DEFAULT_DATE_FORMAT),
        },
      },
    });
    setEndDate(null);
  };

  return (
    <>
      <ConfirmModal
        title={t('contracts.archiveContract')}
        submitButtonTitle={t('contracts.archiveContract')}
        isOpen={isOpenArchiveConfirm}
        isLoadingSubmitButton={loading}
        onSubmit={() => {
          handleContractArchive(id, projectId);
          onCloseArchiveConfirm();
          onCloseConfirm();
        }}
        onClose={() => {
          onCloseArchiveConfirm();
          onCloseConfirm();
        }}
      >
        {t('contracts.archiveConfirmation')}
      </ConfirmModal>

      <ConfirmModal
        title={t('contracts.archiveContractEndDate.title')}
        submitButtonTitle={t('forms.save')}
        isOpen={isOpenEditAssignmentModal}
        onSubmit={() => {
          onEditAssignmentsEndDate();
          onCloseEditAssignmentModal();
          onOpenArchiveConfirm();
        }}
        onClose={() => {
          onCloseEditAssignmentModal();
          onCloseConfirm();
        }}
        disabledSubmitButton={!endDate}
      >
        {t('contracts.archiveContractEndDate.description')}
        <InputLabel className="mt-16">{t('contracts.archiveContractEndDate.endDate')}</InputLabel>
        <DatePicker
          placeholder="DD/MM/YYYY"
          className={styles.datePicker}
          value={endDate}
          onChange={(date) => setEndDate(date as Date)}
        />
      </ConfirmModal>
    </>
  );
};
