import React, { FC } from 'react';
import { ContractDataFragment } from 'generated/types';
import { RightDrawer } from 'components';
import styles from './styles.module.scss';
import { Rates } from './Rates';
import { ProjectHeader } from './components/ProjectHeader';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  contract: ContractDataFragment;
  clientName?: string;
}

export const RateCard: FC<Props> = ({ isOpen, onClose, contract, clientName }) => {
  return (
    <RightDrawer
      paperClassName={styles.paper}
      open={isOpen}
      onClose={onClose}
      title={<ProjectHeader contract={contract} clientName={clientName} />}
      titleClassName="w-100"
      headerClassName={styles.header}
      boxClassName={styles.box}
    >
      <Rates
        contractId={contract.id}
        projectId={contract.projectId}
        currency={contract.fee_currency!}
        unit={contract.unit}
      />
    </RightDrawer>
  );
};
