import React, { FC } from 'react';
import { Tooltip } from 'components';
import { ProjectDataFragment } from 'generated/types';

import styles from './styles.module.scss';

interface Props {
  project: ProjectDataFragment;
}

export const Heading: FC<Props> = ({ project }) => {
  return (
    <div className="flex align-items-center">
      <Tooltip title={project?.name || ''} placement="bottom" textClassName={styles.projectName} alwaysShowTooltip>
        {project?.name}
      </Tooltip>
      <span className={styles.divider} />
      <Tooltip
        title={project?.client?.name || ''}
        placement="bottom"
        textClassName={styles.clientName}
        alwaysShowTooltip
      >
        {project?.client?.name}
      </Tooltip>
    </div>
  );
};
