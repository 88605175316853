import React from 'react';
import { Route } from 'react-router-hoc';
import { Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { ActivityLog as ActivityLogIcon, EditIcon } from 'icons';

import {
  AbsoluteSpinner,
  EmptyState,
  NewTeamMember,
  RightDrawer,
  TabItem,
  Tabs,
  TeamMemberFormValues,
  ViewHeading,
} from 'components';
import { Assignments, Financial, MemberTimeTracking, Skills, Compensations } from 'views';
import { DateRangeTypes, DrawerQueries, MemberTabs, ProjectTabs, RouteProps } from 'types';
import { ActionsType, EmploymentType, Member, MemberStatus } from 'generated/types';
import { addTimezoneOffset, graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { links } from 'App';
import { useEditTeamMemberMutation, useMemberByIdQuery, useMemberIntegrationStatusesQuery } from 'generated/graphql';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions } from 'hooks';
import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { ActivityHistory } from 'views/ActivityHistory';
import { Heading } from './components/Heading';
import { MemberDataSection } from './components/MemberDataSection';
import { AssignmentsDistribution } from './components/AssignmentsDistribution';

import styles from './ViewTeamMember.module.scss';

const ViewTeamMemberRoute = Route(
  {
    id: Route.params.string,
    tab: Route.params.oneOf(MemberTabs.financial, MemberTabs.compensations, ProjectTabs.timeTracking, MemberTabs.skills)
      .optional,
    start: Route.query.string,
    end: Route.query.string,
    month: Route.query.string,
    mode: Route.query.oneOf(DrawerQueries.activityHistoryMode, DrawerQueries.editMode),
    range: Route.query.oneOf(...Object.values(DateRangeTypes)),
  },
  ({ id, tab }) => `/team-member/view/${id}/${tab}`,
);

const ViewTeamMember = ({
  match: {
    params: { id },
    params,
    query: { mode },
    query,
  },
  history: { push },
  link,
}: RouteProps) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions();
  const { isMobileDevice } = useDeviceTypeByWidth();

  const handleDialogOpen = (mode: DrawerQueries) => push(link({ ...params, ...query, mode }));
  const handleDialogClose = () => push(link({ ...params, ...query, mode: undefined }));

  const { data: { member } = {}, loading } = useMemberByIdQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      memberId: id,
      withAssignments: false,
    },
    skip: !userData?.company.id,
  });

  const { data: { memberIntegrationStatuses } = {} } = useMemberIntegrationStatusesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      memberId: id,
    },
    skip: !userData?.company.id,
  });

  useSetAppTitle(member && `${member?.first_name} ${member?.last_name}`);

  const [editMember] = useEditTeamMemberMutation({
    onCompleted() {
      toast.success(t('viewTeamMember.teamMemberEditSuccessfully'));
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  if (loading) {
    return <AbsoluteSpinner />;
  }

  if (!member) {
    return <EmptyState className={styles.emptyState} />;
  }

  const updateMemberUser = async ({
    first_name,
    last_name,
    email,
    employment_type,
    join_date,
    exit_date,
    job_title,
    reportingTo,
    costRateManagers,
    member_role,
    specialization,
    seniority,
    capacity,
    vendor,
  }: TeamMemberFormValues) => {
    await editMember({
      variables: {
        data: {
          first_name,
          last_name,
          email,
          employment_type: employment_type?.id as EmploymentType,
          join_date: join_date && addTimezoneOffset(new Date(join_date)),
          exit_date: exit_date && addTimezoneOffset(new Date(exit_date)),
          capacity: Number(capacity),
          costRateManagers: costRateManagers.map(({ id }) => id.toString()),
          reportingTo: reportingTo ? reportingTo.id : null,
          member_role: member_role.map(({ id }) => id.toString()),
          specialization_id: specialization ? specialization.id.toString() : null,
          job_title: job_title || null,
          seniority_id: seniority ? seniority.id.toString() : null,
          vendorId: vendor && employment_type.id === EmploymentType.Contractor ? vendor.id : null,
        },
        companyId: userData!.company.id,
        memberId: id,
      },
    });
  };
  const isShowActiveMemberEditButton = member.status === MemberStatus.Active && hasAccess(ActionsType.EditMembers);
  const isShowArchivedMemberEditButton =
    member.status === MemberStatus.Archived && hasAccess(ActionsType.EditArchivedMembers);

  return (
    <>
      <ViewHeading
        headingClassName={styles.heading}
        className={styles.headingBox}
        label={
          <Heading
            first_name={member?.first_name}
            last_name={member?.last_name}
            color={member?.color}
            email={member?.email}
            employment_type={member?.employment_type}
            job_title={member?.job_title}
            memberIntegrationStatuses={memberIntegrationStatuses}
          />
        }
      >
        <div className="flex align-self-stretch">
          {hasAccess(ActionsType.ActivityHistory) && (
            <Tooltip title={t('activityHistory.title')!} placement="top">
              <IconButton
                onClick={() => handleDialogOpen(DrawerQueries.activityHistoryMode)}
                className={styles.activityHistoryButton}
              >
                <ActivityLogIcon />
              </IconButton>
            </Tooltip>
          )}
          {(isShowActiveMemberEditButton || isShowArchivedMemberEditButton) && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleDialogOpen(DrawerQueries.editMode)}
              className={styles.editButton}
              startIcon={<EditIcon className={styles.icon} />}
            >
              {t('viewTeamMember.edit')}
            </Button>
          )}
        </div>
      </ViewHeading>

      <div className={styles.memberHeader}>
        {!isMobileDevice && <AssignmentsDistribution memberId={id} />}
        <MemberDataSection member={member as Member} />
      </div>

      <div className="tabs-container">
        <Tabs className={styles.tabs}>
          {hasAccess(ActionsType.ViewMemberAssignments) && (
            <TabItem route={links.Assignments({ id })}>{t('viewTeamMember.assignments')}</TabItem>
          )}
          {hasAccess(ActionsType.ViewCostRates) && (
            <TabItem exact={false} route={links.Financial({ id })}>
              {t('viewTeamMember.financial')}
            </TabItem>
          )}
          {hasAccess(ActionsType.ViewCompensation) && (
            <TabItem exact={false} route={links.Compensations({ id })}>
              {t('viewTeamMember.compensations')}
            </TabItem>
          )}
          <TabItem exact={false} route={links.MemberTimeTracking({ id })}>
            {t('viewTeamMember.timeTracking')}
          </TabItem>
          {hasAccess(ActionsType.ViewSkills) && (
            <TabItem exact={false} route={links.Skills({ id })}>
              {t('viewTeamMember.skills')}
            </TabItem>
          )}
        </Tabs>
        <div className={clsx(styles.actionsWrapper)} id="team-member-tab-actions">
          <div id="team-member-tab-additional-actions" />
        </div>
      </div>

      <div className={clsx('layout-content-wrapper', styles.layout)}>
        <Switch>
          <Assignments exact />
          <Financial />
          <Compensations />
          <MemberTimeTracking />
          <Skills skills={member.skills} />
        </Switch>

        {member && (
          <RightDrawer
            direction="right"
            open={mode === DrawerQueries.editMode}
            onClose={handleDialogClose}
            title={t('viewTeamMember.editTeamMember')}
          >
            <NewTeamMember
              isEditing
              first_name={member.first_name}
              last_name={member.last_name}
              email={member.email}
              employment_type={member.employment_type}
              job_title={member.job_title}
              join_date={member.join_date}
              exit_date={member.exit_date}
              capacity={member.capacity || 0}
              member_role={member.member_role}
              // TODO: it could be optimized: we can replace it with costRateManagersIds and remove from query
              costRateManagers={member.costRateManagers}
              specialization={{ id: member.memberSpecializationId!, name: member.memberSpecialization! }}
              // TODO: it could be optimized: we can replace it with reportingToId and remove from query
              reportingTo={member.reportingTo}
              vendor={member.vendor}
              seniority={member.member_seniority}
              onSubmit={updateMemberUser}
              onCancel={handleDialogClose}
              submitLabel={t('actions.save')}
            />
          </RightDrawer>
        )}
      </div>

      <ActivityHistory
        isOpen={mode === DrawerQueries.activityHistoryMode}
        onClose={handleDialogClose}
        memberId={member.id}
        isShowProjectField
      />
    </>
  );
};

export default ViewTeamMemberRoute<RouteProps>(ViewTeamMember);
