import React, { FC, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { links } from 'App';
import { useContractsOptionsLazyQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { EmptyState, LoadingButton, Tooltip } from 'components';
import { ProjectsLink } from 'icons';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';

import styles from './styles.module.scss';

export interface ContractsCellProps {
  projectId: string;
  activeItemsNumber?: number;
}

export const ContractsCell: FC<ContractsCellProps> = ({ activeItemsNumber = 0, projectId }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { push } = useHistory();

  const [fetchContracts, { data: { contracts = [] } = {}, loading }] = useContractsOptionsLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      filterData: { projectId, isActive: true },
      withProject: false,
      withDates: false,
      withClient: false,
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleOpen = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (activeItemsNumber) {
      fetchContracts();
      handleClick(e);
    }
  };
  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleOpenContract = (e: MouseEvent<HTMLElement>, contractId: string, projectId: string) => {
    e.stopPropagation();
    e.preventDefault();
    push(links.ProjectDetail({ id: projectId, contractId }));
  };

  return (
    <div className={styles.cell}>
      <LoadingButton
        size="medium"
        color="secondary"
        loading={loading}
        onClick={handleOpen}
        disableRipple={!activeItemsNumber}
        startIcon={<ProjectsLink className={styles.cellIcon} />}
        className={clsx(styles.cellButton, !activeItemsNumber && styles.disabled)}
      >
        {contracts?.length || activeItemsNumber}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(anchorEl) && !(loading && !contracts?.length)}
        onClose={handleClose}
      >
        {!activeItemsNumber && !contracts?.length && (
          <EmptyState title="projectFile.emptyMessage" className={styles.emptyMessage} />
        )}
        {contracts.map(({ id, projectId, name }) => (
          <MenuItem
            key={id}
            onClick={(e) => handleOpenContract(e, id, projectId)}
            className="flex justify-content-between"
          >
            <Tooltip title={name} placement="top" textClassName={styles.contractName}>
              {name}
            </Tooltip>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
