import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsType } from 'generated/types';
import { EmptyState, RightDrawer } from 'components';
import { graphqlOnError } from 'utils';
import { useActivityHistoriesQuery } from 'generated/graphql';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { useAuth } from 'contexts';
import { ActivityLog as ActivityLogIcon, Arrow } from 'icons';
import styles from './styles.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { ActivityLog } from './components/ActivityLog';
import { useAccordionData } from './hooks/useAccordionData';
import clsx from 'clsx';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  memberId?: string;
  memberColor?: string;
  contractId?: string;
  projectColor?: string;
  currencyCode?: string;
  isShowProjectField?: boolean;
  isShowMemberField?: boolean;
}

export const ActivityHistory: FC<Props> = ({
  isOpen,
  onClose,
  memberId,
  contractId,
  currencyCode,
  isShowProjectField,
  isShowMemberField,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const {
    accordionOpenState,
    setAccordionState,
    toggleAccordion,
    changeAllAccordionsState,
    isAllCollapsed,
  } = useAccordionData();

  const { data: { activityHistories = [] } = {}, loading } = useActivityHistoriesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      historyFilter: {
        memberId: memberId,
        contractId: contractId,
      },
    },
    skip: !hasAccess(ActionsType.ActivityHistory) || !isOpen,
    fetchPolicy: 'network-only',
  });

  const sortedActivityHistory = useMemo(() => {
    return activityHistories
      .slice()
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [activityHistories]);

  useEffect(() => {
    if (!activityHistories.length) {
      return;
    }

    setAccordionState(activityHistories);
  }, [activityHistories]);

  return (
    <RightDrawer
      open={isOpen}
      onClose={onClose}
      paperClassName={styles.paper}
      boxClassName={styles.drawerBox}
      headerClassName={styles.drawerHeader}
      title={
        <div className="flex align-items-center">
          <ActivityLogIcon />
          {t('activityHistory.title')}
        </div>
      }
      additionalButton={
        <Button
          variant="text"
          className={clsx(styles.collapseButton, isAllCollapsed && styles.collapseButtonExpanded)}
          endIcon={<Arrow />}
          onClick={() => changeAllAccordionsState(isAllCollapsed)}
        >
          {isAllCollapsed ? t('activityHistory.expandAll') : t('activityHistory.collapseAll')}
        </Button>
      }
    >
      <div className={styles.container}>
        {(isPermissionsLoading || (loading && !activityHistories.length)) && (
          <div className={styles.spinnerContainer}>
            <CircularProgress size={64} style={{ color: '#24343D' }} />
          </div>
        )}

        {!hasAccess(ActionsType.ActivityHistory) && !isPermissionsLoading && (
          <EmptyState className="mt-40" title="permission.denied" />
        )}

        {sortedActivityHistory.map((log) => (
          <ActivityLog
            key={log.id}
            log={log}
            currencyCode={currencyCode}
            isOpenAccordion={accordionOpenState[log.id]}
            toggleAccordion={() => toggleAccordion(log.id)}
            isShowProjectField={isShowProjectField}
            isShowMemberField={isShowMemberField}
          />
        ))}

        {!sortedActivityHistory.length && !loading && !isPermissionsLoading && <EmptyState className="mt-40" />}
      </div>
    </RightDrawer>
  );
};
