import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { Divider, IconButton } from '@material-ui/core';
import { DragIcon, PlusIcon } from 'icons';
import { CreateRate } from '../CreateRate';
import { SeniorityRates } from '../SeniorityRates';
import { ActionsType, ExternalRateDataFragment, RateUnit } from 'generated/types';
import { usePermissions } from 'hooks';

interface Props {
  contractId: string;
  projectId: string;
  currency?: string;
  unit: RateUnit;
  role: string;
  seniorityRates: { [key: string]: ExternalRateDataFragment[] };
  allSeniorityRates?: { [key: string]: ExternalRateDataFragment[] };
  disableDragging?: boolean;
}

export const RoleRates: FC<Props> = ({
  role,
  seniorityRates,
  contractId,
  projectId,
  currency,
  unit,
  allSeniorityRates,
  disableDragging,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const { hasAccess } = usePermissions({ projectId });

  const cancelCreating = () => setIsCreating(false);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        {role}
        {hasAccess(ActionsType.CreateProjectRatecards) && (
          <IconButton className={styles.button} disabled={isCreating} onClick={() => setIsCreating(true)}>
            <PlusIcon className={styles.icon} />
          </IconButton>
        )}
        {!disableDragging && <DragIcon className={styles.dragIcon} />}
      </h4>

      {Object.entries(seniorityRates)
        .sort(([key1], [key2]) => key2.localeCompare(key1, 'en'))
        .map(([key, value]) => (
          <SeniorityRates
            key={key}
            rates={value}
            contractId={contractId}
            projectId={projectId}
            currency={currency}
            unit={unit}
            allRates={allSeniorityRates ? allSeniorityRates[key] : undefined}
          />
        ))}

      {isCreating && (
        <CreateRate
          roleId={Object.values(seniorityRates)[0][0].roleId}
          contractId={contractId}
          projectId={projectId}
          currency={currency}
          unit={unit}
          onClose={cancelCreating}
          showSenioritySelect
          seniorityRates={allSeniorityRates}
        />
      )}
      <Divider className="mt-24" />
    </div>
  );
};
