import React, { createRef, FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PlusIcon } from 'icons';
import { useAuth } from 'contexts';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder, usePermissions } from 'hooks';

import { ActionsType, ExternalRateDataFragment, RateUnit } from 'generated/types';
import { useExternalRatesQuery, useContractRolesOrderQuery } from 'generated/graphql';

import { getRateData } from '../helpers';
import { CreateRate } from '../CreateRate';
import { RatesDraggableList } from '../RatesDraggableList';

import styles from './styles.module.scss';

interface Props {
  contractId: string;
  projectId: string;
  currency?: string;
  unit: RateUnit;
}

export type SeniorityRates = {
  [key: string]: ExternalRateDataFragment[];
};

export type RateData = {
  [key: string]: SeniorityRates;
};

export type RateOrderData = {
  [key: string]: number;
};

export const Rates: FC<Props> = ({ contractId, projectId, currency, unit }) => {
  const endDrawerRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions({ projectId });
  const addRoleInputRef = useRef<HTMLDivElement | null>(null);

  const cancelCreating = () => setIsCreating(false);

  const { data: { externalRates = [] } = {}, loading: ratesLoading } = useExternalRatesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id, projectId, contractId, isPast: true },
  });

  const { data: { contractRolesOrder = [] } = {}, loading: contractRolesOrderLoading } = useContractRolesOrderQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id, projectId, contractId },
  });

  const onOpenCreateRate = () => {
    if (!isCreating) {
      setIsCreating(true);
    }

    addRoleInputRef.current?.focus();
    endDrawerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const rateRolesOrderData = useMemo<RateOrderData>(() => {
    return (
      contractRolesOrder.reduce((acc, item) => {
        return { ...acc, [item.roleId]: item.orderingIndex };
      }, {}) || {}
    );
  }, [contractRolesOrder]);

  const rateData = useMemo(() => {
    const sortedRates = [...externalRates].sort(
      (rateA, rateB) => rateRolesOrderData[rateA.roleId] - rateRolesOrderData[rateB.roleId],
    );
    return getRateData(sortedRates);
  }, [externalRates, rateRolesOrderData]);

  const getAllSeniorityRates = useCallback(
    (key: string) => {
      return externalRates.length > 0 ? getRateData(externalRates)[key] : undefined;
    },
    [externalRates.length],
  );

  if (ratesLoading || contractRolesOrderLoading) {
    return (
      <div className={styles.spinnerBox}>
        <CircularProgress size={64} style={{ color: '#24343D' }} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('rateCard.rates')}</h3>
        {hasAccess(ActionsType.CreateProjectRatecards) && (
          <Button
            variant="outlined"
            color="secondary"
            className={styles.outlinedButton}
            startIcon={<PlusIcon className={styles.icon} />}
            onClick={onOpenCreateRate}
          >
            {t('rateCard.addRole')}
          </Button>
        )}
      </div>

      <div className={styles.box}>
        <DndProvider backend={HTML5Backend}>
          <RatesDraggableList
            roleRates={Object.entries(rateData)}
            projectId={projectId}
            contractId={contractId}
            currency={currency}
            unit={unit}
            getAllSeniorityRates={getAllSeniorityRates}
            additionalContent={
              isCreating ? (
                <CreateRate
                  showRoleSelect
                  showSenioritySelect
                  contractId={contractId}
                  projectId={projectId}
                  currency={currency}
                  unit={unit}
                  onClose={cancelCreating}
                  addRoleInputRef={addRoleInputRef}
                  rateData={externalRates.length > 0 ? getRateData(externalRates, true) : undefined}
                />
              ) : (
                hasAccess(ActionsType.CreateProjectRatecards) && (
                  <Button
                    variant="text"
                    color="secondary"
                    className={styles.button}
                    startIcon={<PlusIcon className={styles.icon} />}
                    onClick={() => setIsCreating(true)}
                    disabled={isCreating}
                  >
                    {t('rateCard.addRole')}...
                  </Button>
                )
              )
            }
          />
        </DndProvider>

        <div ref={endDrawerRef} />
      </div>
    </>
  );
};
