import { ActionsType } from 'generated/types';
import { useEffect } from 'react';
import { links } from 'App';
import { usePermissions } from 'hooks';
import { useProjectMembershipQuery } from 'generated/graphql';
import { isPM } from 'utils';
import { useAuth } from 'contexts';
import { endOfDay, isAfter } from 'date-fns';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useRedirectWithoutPermissions = (projectId: string, push: (to: string) => void) => {
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId });
  const { t } = useTranslation();
  const { userData } = useAuth();
  const { data: { projectMembership } = {}, loading: loadingProjectMembership } = useProjectMembershipQuery({
    onError() {
      push(links.Projects());
    },
    variables: {
      companyId: userData!.company.id,
      projectId: projectId,
      memberId: userData!.member.id,
    },
    skip: !isPM(userData!) || !projectId,
  });

  const isPMHaveAccessByNow =
    !loadingProjectMembership && projectMembership?.endDate
      ? !isAfter(new Date(), endOfDay(new Date(projectMembership?.endDate)))
      : true;
  const isPMAlreadyHaveAccess =
    !loadingProjectMembership && projectMembership?.startDate
      ? !isAfter(new Date(projectMembership.startDate), endOfDay(new Date()))
      : true;

  useEffect(() => {
    if (
      (!isPermissionsLoading &&
        projectMembership &&
        !loadingProjectMembership &&
        !(
          hasAccess(ActionsType.ViewProfitabilityGraph) ||
          hasAccess(ActionsType.ViewTeamDynamicsGraph) ||
          hasAccess(ActionsType.ViewProjectAssignments) ||
          hasAccess(ActionsType.ViewExpenses) ||
          hasAccess(ActionsType.ViewTimelogs) ||
          hasAccess(ActionsType.ViewDocuments)
        )) ||
      !isPMHaveAccessByNow ||
      !isPMAlreadyHaveAccess
    ) {
      push(links.Projects());
      toast.error(t('permission.denied'));
    }
  }, [isPermissionsLoading, loadingProjectMembership]);

  return {
    startAccessDate: projectMembership?.startDate,
    endAccessDate: projectMembership?.endDate,
    loadingProjectMembership,
  };
};
