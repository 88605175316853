export const CONTRACTS_TABLE_HIDDEN_COLUMNS = 'CONTRACTS_TABLE_HIDDEN_COLUMNS';
export const CONTRACTS_TABLE_COLUMN_ORDER = 'CONTRACTS_TABLE_COLUMN_ORDER';

export enum ContractsTableColumns {
  Contract = 'contract',
  Project = 'project',
  PM = 'pm',
  Type = 'type',
  StartDate = 'start_date',
  EndDate = 'end_date',
  ActiveAssignments = 'activeAssignments',
  Margin = 'margin',
  ForecastMargin = 'forecastMargin',
  CurrentCommission = 'currentCommission',
}
