import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  AbsoluteSpinner,
  AssignmentInfo,
  Autocomplete,
  CreatableAutocomplete,
  DatePicker,
  LoadingButton,
  NumberTextField,
} from 'components';
import { ActionsType, Assignment, CompensationDataFragment, Currency, Scalars } from 'generated/types';
import { Button, InputLabel, TextField } from '@material-ui/core';
import { graphqlOnError, valueToCurrency } from 'utils';
import { useCurrenciesQuery } from 'generated/graphql';
import { useCreatableCompensationTypesData, useErrorMsgBuilder, usePermissions } from 'hooks';
import { useAuth } from 'contexts';

import styles from './styles.module.scss';

export interface CompensationFormValues {
  amount: number | null;
  assignment: Pick<Assignment, 'id'> | null;
  currency: Currency;
  date: string | null;
  notes?: string;
  type: { id: string; name: string } | null;
}

interface NewCostRateProps {
  onSubmit: (values: CompensationFormValues, onErrorCallback?: () => void) => void | Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  compensations?: CompensationDataFragment[];
  assignments?: Pick<Assignment, 'id'>[];
  loading?: boolean;
}

export const NewCompensation = ({
  submitLabel,
  onSubmit,
  onCancel,
  compensations,
  assignments,
  loading,
}: NewCostRateProps) => {
  const { t } = useTranslation();
  const { compensationId } = useParams<{ compensationId: string }>();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess } = usePermissions();
  const editingCompensation = compensations?.find(({ id }) => id === compensationId);

  const {
    compensationTypes,
    compensationTypeLoading,
    getCreatedCompensationType,
    onDeleteCompensationTypes,
  } = useCreatableCompensationTypesData();
  const { data: { currencies = [] as Currency[] } = {}, loading: currenciesLoading } = useCurrenciesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id },
  });

  const getCurrencyLabel = (currency: Currency) =>
    `${currency?.code?.toUpperCase()} - ${t(`currency.${currency?.code}`)}`;

  const findCurrency = (id?: string | null) => currencies.find((currency) => currency.id === id);

  const initialCurrency = useMemo(
    () =>
      (editingCompensation?.currencyId
        ? findCurrency(editingCompensation?.currencyId)
        : findCurrency(userData?.company.primaryCurrencyId))!,
    [currencies],
  );

  const initialValues = useMemo(
    () => ({
      type: editingCompensation?.type ? { id: editingCompensation.type, name: editingCompensation.typeName } : null,
      amount: editingCompensation ? valueToCurrency(editingCompensation.amount) : null,
      currency: initialCurrency,
      date: editingCompensation?.date ?? new Date(),
      notes: editingCompensation?.notes ?? '',
      assignment:
        (editingCompensation?.assignmentId && assignments?.find(({ id }) => id === editingCompensation.assignmentId)) ||
        null,
    }),
    [editingCompensation, assignments],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.object().nullable().required(t('validation.required')),
        assignment: Yup.object().nullable(),
        amount: Yup.number()
          .typeError(t('validation.validNumber'))
          .required(t('validation.required'))
          .test('maxDigitsAfterDecimal', t('validation.decimal'), (number) =>
            /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
          )
          .positive(t('validation.positive')),
        currency: Yup.object().required(t('validation.required')),
        date: Yup.string().nullable().required(t('validation.required')),
        notes: Yup.string().nullable(),
      }),
    [],
  );

  const {
    submitCount,
    handleSubmit,
    setValues,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik<CompensationFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values: CompensationFormValues) => {
      const type = values.type ? await getCreatedCompensationType(values.type) : null;
      const onDeleteCreatedCompensationType = () => {
        if (values.type && !values.type.id && type?.id) {
          onDeleteCompensationTypes(type.id);
        }
      };

      await onSubmit({ ...values, type }, onDeleteCreatedCompensationType);
    },
  });

  useEffect(() => {
    if (initialValues.type) {
      setValues(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (!values.currency && initialCurrency) {
      setFieldValue('currency', initialCurrency);
    }
  }, [initialCurrency, currencies]);

  const renderAssignmentsOption = ({ contract, seniority, role }: Assignment) => {
    if (!contract) {
      return null;
    }

    return (
      <AssignmentInfo
        data={{
          id: contract.projectId,
          contractId: contract.id,
          name: contract.projectName,
          role,
          seniority: seniority || '',
          color: contract.projectColor,
        }}
      />
    );
  };

  if (currenciesLoading || compensationTypeLoading || (!compensations && compensationId)) return <AbsoluteSpinner />;

  return (
    <form className="form">
      <div>
        <InputLabel required>{t('forms.newCompensation.compensationType')}</InputLabel>
        <CreatableAutocomplete
          className="mb-24"
          name="type"
          value={values.type}
          onBlur={handleBlur}
          placeholder={t('forms.newCompensation.compensationType')}
          options={compensationTypes}
          error={touched.type ? errors.type : undefined}
          onChange={(type: CompensationFormValues['type']) => setValues({ ...values, type })}
          enableCreation={hasAccess(ActionsType.OtherSettings)}
        />

        <div className={styles.twoItemsBox}>
          <div className="flex-1">
            <InputLabel required>{t('forms.newCompensation.amount')}</InputLabel>
            <NumberTextField
              error={Boolean((submitCount || touched.amount) && errors.amount)}
              helperText={(submitCount || touched.amount) && t(errors.amount!)}
              onBlur={handleBlur}
              onChange={handleChange}
              name="amount"
              value={values.amount}
              placeholder="0"
              className="mb-24"
            />
          </div>

          <div className="flex-1">
            <InputLabel required>{t('forms.newCompensation.currency')}</InputLabel>
            {values.currency ? (
              <Autocomplete
                placeholder={t('forms.newCompensation.currency')}
                className="mb-24"
                value={values.currency}
                name="currency"
                onBlur={handleBlur}
                disableClearable
                error={touched.currency ? errors.currency : undefined}
                getOptionLabel={getCurrencyLabel}
                options={currencies}
                onChange={(currency: CompensationFormValues['currency']) => setValues({ ...values, currency })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <InputLabel>{t('forms.newCompensation.assignment')}</InputLabel>
        <Autocomplete
          className="mb-24"
          name="assignment"
          value={values.assignment}
          onBlur={handleBlur}
          placeholder={t('forms.newCompensation.assignment')}
          options={assignments}
          error={touched.assignment ? errors.assignment : undefined}
          onChange={(assignment: Assignment) => setValues({ ...values, assignment })}
          getOptionLabel={(option: Assignment) => option.contract?.projectName || ''}
          renderOption={renderAssignmentsOption}
        />

        <InputLabel required>{t('forms.newCompensation.date')}</InputLabel>
        <DatePicker
          value={values.date}
          error={Boolean(submitCount && errors.date)}
          helperText={!!submitCount && errors.date}
          onChange={(date: Scalars['DateTime']) => setValues({ ...values, date })}
        />

        <InputLabel className="mt-24">{t('forms.newCompensation.notes')}</InputLabel>
        <TextField
          name="notes"
          size="medium"
          multiline
          rows={4}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.notes}
          placeholder={t('forms.typeNoteHere')}
          className={styles.note}
        />
      </div>
      <div className="controls">
        <LoadingButton onClick={() => handleSubmit()} loading={loading} className="mr-8">
          {submitLabel ?? t('forms.create')}
        </LoadingButton>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('forms.cancel')}
        </Button>
      </div>
    </form>
  );
};
