import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { InsightsTabs } from 'types';
import { ChartType } from 'views/Insights/Projects/consts';

export const InsightsRoute = Route(
  {
    tab: Route.params.oneOf(InsightsTabs.utilization, InsightsTabs.bench, InsightsTabs.projects).optional,
    type: Route.params.oneOf(ChartType.profitability, ChartType.teamDynamics, ChartType.revenue).optional,
    projectId: Route.query.string,
    contractId: Route.query.string,
  },
  ({ tab }) => `/insights/${tab}`,
);

export const Insights = InsightsRoute(lazy(() => import(/* webpackChunkName: "Insights" */ './Insights')));
