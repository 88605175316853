import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { graphqlOnError, useQueryParams } from 'utils';
import { useArchiveContract, useErrorMsgBuilder, useRestoreContract } from 'hooks';
import { useAuth } from 'contexts';
import { useCreateContract } from 'components/CommonActions/hooks/useCreateContract';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { ProjectByIdDocument, useContractsOptionsQuery, useEditContractMutation } from 'generated/graphql';
import { ActionsDropdown, Autocomplete, ContractFormValues, NewContract, RightDrawer } from 'components';
import { ArchiveIcon, EditIcon, PlusIcon } from 'icons';
import { ContractDataFragment } from 'generated/types';
import { ProjectDrawerQueries } from 'types';
import { projectLinks } from 'views/ProjectDetail';
import { getContractChangeData } from './getContractChangeData';

import styles from './styles.module.scss';

interface Props {
  contract?: ContractDataFragment | null;
}

export const ContractSection: FC<Props> = ({ contract }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const params = useParams<{ id: string }>();
  const queryParams = useQueryParams<{ mode: ProjectDrawerQueries }>();
  const { push } = useHistory();

  const onChangeActiveContract = (contractId: string) => {
    push(projectLinks.ProjectDetail({ ...params, ...queryParams, contractId }));
  };
  const onOpenCreateContract = () => {
    push(projectLinks.ProjectDetail({ ...params, ...queryParams, mode: ProjectDrawerQueries.createContractMode }));
  };
  const onOpenEditContract = () => {
    push(projectLinks.ProjectDetail({ ...params, ...queryParams, mode: ProjectDrawerQueries.editContractMode }));
  };
  const onCloseDrawer = () => {
    push(projectLinks.ProjectDetail({ ...params, ...queryParams, mode: undefined }));
  };

  const { data: { contracts = [] } = {} } = useContractsOptionsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      filterData: { projectId: params.id },
    },
  });

  const contractsOptions = useMemo(
    () =>
      contracts.slice().sort((a, b) => {
        if (!b.archived_at && a.archived_at) {
          return 1;
        }

        return -1;
      }),
    [contracts],
  );

  const archiveContract = useArchiveContract();
  const restoreContract = useRestoreContract();

  const createContract = useCreateContract({
    onCompleted: (data) => {
      onCloseDrawer();
      toast.success(t('contracts.createdSuccessfully'));

      if (!contract) {
        onChangeActiveContract(data.createContract?.id);
      }
    },
    refetchAction: [
      {
        query: ProjectByIdDocument,
        variables: { companyId: userData!.company.id, projectId: params.id, withCommission: true },
      },
    ],
  });
  const onCreateContract = useCallback((values: ContractFormValues) => createContract(values), [params.id]);

  const [editContract] = useEditContractMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('contracts.editedSuccessfully'));
      onCloseDrawer();
    },
    refetchQueries: ['ContractsOptions'],
  });

  const onEditContract = useCallback(
    (values: ContractFormValues) => {
      editContract({
        variables: {
          contractId: contract!.id,
          companyId: userData!.company.id,
          projectId: params.id,
          data: getContractChangeData(values),
          withCommission: false,
        },
      });
    },
    [params.id],
  );

  return (
    <div className={styles.container}>
      {!contract && (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<PlusIcon className={styles.icon} />}
          onClick={onOpenCreateContract}
        >
          {t('contracts.contract')}
        </Button>
      )}
      {contract && (
        <>
          {(contracts.length || contract) && (
            <Autocomplete
              className={styles.autocomplete}
              value={contract}
              options={contractsOptions}
              onChange={({ id }: ContractDataFragment) => onChangeActiveContract(id)}
              disableClearable
            />
          )}

          <ActionsDropdown
            className="p-6"
            menuItems={[
              {
                Icon: <PlusIcon className={styles.icon} />,
                title: t('contracts.createContract'),
                onClickFunc: onOpenCreateContract,
              },
              {
                Icon: <EditIcon />,
                title: t('contracts.editContract'),
                onClickFunc: onOpenEditContract,
              },
              contract.archived_at
                ? {
                    Icon: <ArchiveIcon />,
                    title: t('contracts.restoreContract'),
                    onClickFunc: () => restoreContract(contract?.id || '', contract?.projectId || ''),
                  }
                : {
                    Icon: <ArchiveIcon />,
                    title: t('contracts.archiveContract'),
                    onClickFunc: () => archiveContract(contract?.id || '', contract?.projectId || ''),
                  },
            ]}
          />
        </>
      )}

      <RightDrawer
        direction="right"
        open={queryParams.mode === ProjectDrawerQueries.editContractMode}
        onClose={onCloseDrawer}
        title={t('contracts.editContract')}
        paperClassName={styles.editContractPaper}
      >
        <NewContract
          onSubmit={onEditContract}
          onCancel={onCloseDrawer}
          submitLabel={t('actions.save')}
          contract={contract}
          projectId={params.id}
          projectDisabled
        />
      </RightDrawer>
      <RightDrawer
        direction="right"
        open={queryParams.mode === ProjectDrawerQueries.createContractMode}
        onClose={onCloseDrawer}
        title={t('contracts.createContract')}
        paperClassName={styles.editProjectPaper}
      >
        <NewContract onSubmit={onCreateContract} onCancel={onCloseDrawer} projectId={params.id} projectDisabled />
      </RightDrawer>
    </div>
  );
};
