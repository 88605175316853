import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AbsoluteSpinner,
  Avatar,
  Collapse,
  ContractsCell,
  EmptyState,
  ExpandedColumn,
  ProjectInfoCell,
  SIZES,
  Table,
  TableActionCell,
  TableColumnSettingDrawer,
  Tooltip,
} from 'components';
import { GroupByFilter, TableCell } from 'types';
import { getAcronym, groupProjectsByName, groupProjectsByPM, sortByField } from 'utils';
import {
  ACTIONS,
  ACTIVE_CONTRACTS,
  ASC,
  CLIENT,
  EMPTY_DATA_STATE,
  FILE,
  FIRST_NAME,
  LAST_NAME,
  PM,
  PROJECT,
  ProjectsTableColumns,
  TableColumnsWidth,
} from 'consts';
import { links } from 'App';
import { ActionsType, ManagedProjectDataFragment, ProjectType } from 'generated/types';
import { useIsOpen, usePermissions, useProjectsTableSetting, useProjectTableSorts } from 'hooks';
import { SortingRule } from 'react-table';
import { FileCell } from 'views/Projects/components/FileCell';
import { ProjectManagerCell } from 'views/Projects/components/ProjectManagerCell';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { SettingsActiveLink as SettingsIcon } from 'icons';
import { useSettings } from 'contexts';

import styles from './styles.module.scss';

interface Props {
  loading?: boolean;
  projects: ManagedProjectDataFragment[];
  groupBy?: GroupByFilter;
  getTableActions: (id: string, isActiveContracts: boolean) => ReactNode;
}

export const ProjectsTable: FC<Props> = ({ loading, projects, groupBy, getTableActions }) => {
  const { t } = useTranslation();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [tableSortBy, setTableSortBy] = useState<SortingRule<ManagedProjectDataFragment>[] | null>(null);
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { isFinancialsHidden } = useSettings();

  const changeTableSortBy = useCallback((sortBy: SortingRule<ManagedProjectDataFragment>) => {
    setTableSortBy([sortBy]);
  }, []);

  const groupedProjectsByClient = groupBy === GroupByFilter.client ? groupProjectsByName(projects) : {};
  const groupedProjectsByPM =
    groupBy === GroupByFilter.pm || groupBy === GroupByFilter.projectManager ? groupProjectsByPM(projects) : {};

  const {
    defaultActiveColumns,
    columnsOrder,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  } = useProjectsTableSetting();

  const { sortNameType, sortClientType, sortPMType } = useProjectTableSorts();

  const columns = useMemo<ExpandedColumn<ManagedProjectDataFragment>[]>(() => {
    const isGroupColumns = groupBy && groupBy !== GroupByFilter.none;

    return [
      {
        id: 'project',
        Header: (
          <span title={t('columns.projects.project')} className={isGroupColumns ? 'pl-30' : 'pl-0'}>
            {t('columns.projects.project')}
          </span>
        ),
        accessor: ({ name, color, client }) => ({ name, color, client }),
        sticky: 'left',
        sortable: true,
        sortType: sortNameType,
        Cell: function project({
          value: { name, color },
        }: TableCell<Pick<ManagedProjectDataFragment, 'name' | 'color'>>) {
          return (
            <ProjectInfoCell
              hasTooltip
              hasSubtitleTooltip
              title={name}
              color={color}
              className={clsx(styles.containerProjectCell, groupBy && groupBy !== GroupByFilter.none && 'pl-30')}
              titleClassName={styles.containerProjectTitle}
            />
          );
        },
      },
      {
        Header: t('columns.projects.client')!,
        accessor: CLIENT,
        sortable: true,
        sortType: sortClientType,
        show: activeColumns.includes(ProjectsTableColumns.Client) && groupBy !== GroupByFilter.client,
        Cell: function client({ value }) {
          return (
            <Tooltip title={value?.name || ''} placement="top" textClassName={styles.containerProjectTitle}>
              {value?.name || EMPTY_DATA_STATE}
            </Tooltip>
          );
        },
      },
      {
        Header: t('columns.projects.projectManager')!,
        accessor: PM,
        sortable: true,
        sortType: sortPMType,
        show: activeColumns.includes(ProjectsTableColumns.PM) && groupBy !== GroupByFilter.projectManager,
        Cell: function projectManager({
          row: {
            original: { id, pm },
          },
        }) {
          return <ProjectManagerCell projectId={id} pm={pm} type={ProjectType.TimeAndMaterial} />;
        },
      },
      {
        Header: t('columns.projects.contracts')!,
        id: ACTIVE_CONTRACTS,
        sortable: true,
        accessor: 'activeContracts',
        show: activeColumns.includes(ProjectsTableColumns.ActiveContracts),
        Cell: function file({
          row: {
            original: { id, activeContracts },
          },
        }) {
          return <ContractsCell projectId={id} activeItemsNumber={activeContracts} />;
        },
        ...TableColumnsWidth.countButton,
      },
      {
        Header: t('columns.projects.files')!,
        id: FILE,
        sortable: true,
        accessor: 'countFiles',
        show: activeColumns.includes(ProjectsTableColumns.File),
        Cell: function file({
          row: {
            original: { id, countFiles },
          },
        }) {
          return <FileCell projectId={id} activeItemsNumber={countFiles} />;
        },
        ...TableColumnsWidth.countButton,
      },
      {
        Header: (
          <Tooltip
            title={t('table.columnSettings.customiseColumns')!}
            placement="top"
            className={styles.settingTooltip}
            alwaysShowTooltip
          >
            <IconButton size="small" className="p-0" onClick={onOpen}>
              <SettingsIcon className={styles.settingIcon} />
            </IconButton>
          </Tooltip>
        ),
        id: ACTIONS,
        accessor: 'id',
        Cell: function action({
          row: {
            original: { id, activeContracts },
          },
          isHovered,
        }) {
          return hasAccess(ActionsType.ArchiveProjects) ? (
            <TableActionCell isHovered={isHovered}>{getTableActions(id, !!activeContracts)}</TableActionCell>
          ) : (
            ''
          );
        },
      },
    ];
  }, [groupBy, isPermissionsLoading, activeColumns, isFinancialsHidden, getTableActions]);

  const getRedirectToDetailsLink = (rowId: string) => links.ProjectDetail({ id: rowId });

  if (loading || isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!projects.length) {
    return <EmptyState className={styles.emptyState} />;
  }

  if (!hasAccess(ActionsType.ViewActiveProjects)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  const renderTable = (data: ManagedProjectDataFragment[], insideAccordion?: boolean) => (
    <div className={styles.containerTable}>
      <Table
        columns={columns}
        getRedirectRowLink={getRedirectToDetailsLink}
        data={data}
        onChangeTableSortBy={changeTableSortBy}
        sortByValue={tableSortBy}
        columnOrder={[ProjectsTableColumns.Project, ...columnsOrder]}
        insideAccordion={insideAccordion}
      />
    </div>
  );

  return (
    <>
      {!groupBy || groupBy === GroupByFilter.none ? renderTable(sortByField(projects, ASC, PROJECT)) : ''}
      {groupBy === GroupByFilter.client ? (
        <>
          {Object.keys(groupedProjectsByClient).map((key) => {
            const projectsAmount = groupedProjectsByClient[key].length;
            const title = projectsAmount ? groupedProjectsByClient[key][0].client?.name ?? t(`projects.${key}`)! : '';

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <Tooltip title={title} placement="top" textClassName={styles.containerTitle}>
                        {title}
                      </Tooltip>
                      <span className={styles.containerChip}>{projectsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(
                    sortByField(groupedProjectsByClient[key], ASC, PROJECT) as ManagedProjectDataFragment[],
                    true,
                  )}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}
      {groupBy === GroupByFilter.pm || groupBy === GroupByFilter.projectManager ? (
        <>
          {Object.keys(groupedProjectsByPM).map((key) => {
            const projectsAmount = groupedProjectsByPM[key].length;
            const { first_name, last_name, color } = groupedProjectsByPM[key][0].pm;
            const title = projectsAmount ? `${first_name} ${last_name}` ?? t(`projects.${key}`)! : '';

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <Avatar avatarTitle={getAcronym(first_name, last_name)} size={SIZES.xs} color={color} />
                      <Tooltip title={title} placement="top" textClassName={styles.containerTitle}>
                        {title}
                      </Tooltip>
                      <span className={styles.containerChip}>{projectsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(
                    sortByField(groupedProjectsByPM[key], ASC, FIRST_NAME, LAST_NAME) as ManagedProjectDataFragment[],
                    true,
                  )}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}

      <TableColumnSettingDrawer
        getColumnTitle={(column) => t(`projects.columnSettings.${column}`)}
        allColumnOptions={defaultActiveColumns}
        activeColumns={activeColumns}
        columnsOrder={columnsOrder}
        onSubmit={onChangeActiveColumns}
        onChangeOrder={onChangeColumnsOrder}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};
