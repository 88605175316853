import { getLinks, Route } from 'react-router-hoc';
import { DateRangeTypes, DrawerQueries, ProjectDrawerQueries, ProjectTabs } from 'types';
import { ProjectDetail as ProjectDetailComponent } from './ProjectDetail';
import { Assignments } from './Assignments';
import { TimeTracking } from './TimeTracking';
import { Files } from './Files';
import { Scenarios } from './Scenarios';
import { Billing } from './Billing';
import { Expenses } from './Expenses';
import { Milestones } from './Milestones';
import { idRegEx } from 'utils/regex';

export { SharedProjects } from './SharedProjects';
export { BillableLeaves } from './BillableLeaves';
export * from './utils';

export const ProjectDetailRoute = Route(
  {
    id: Route.params.string,
    tab: Route.params.oneOf(
      ProjectTabs.milestones,
      ProjectTabs.assignments,
      ProjectTabs.expenses,
      ProjectTabs.timeTracking,
      ProjectTabs.files,
      ProjectTabs.billing,
    ).optional,
    contractId: Route.params.regex(idRegEx).optional,
    start: Route.query.string,
    end: Route.query.string,
    range: Route.query.oneOf(...Object.values(DateRangeTypes)),
    month: Route.query.string,
    search: Route.query.string,
    mode: Route.query.oneOf(
      DrawerQueries.rateCardMode,
      DrawerQueries.activityHistoryMode,
      DrawerQueries.editMode,
      DrawerQueries.leavesBalanceMode,
      DrawerQueries.shareProjectsMode,
      ProjectDrawerQueries.createContractMode,
      ProjectDrawerQueries.editContractMode,
    ),
    leaveRule: Route.query.regex(idRegEx),
  },
  ({ id, tab, contractId }) => `/project/view/${id}/${contractId}/${tab}`,
);

export const ProjectDetail = ProjectDetailRoute(ProjectDetailComponent);

export const projectLinks = getLinks({
  ProjectDetail,
  Milestones,
  Assignments,
  Expenses,
  TimeTracking,
  Files,
  Scenarios,
  Billing,
});
