import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';
import { isBoolean } from 'lodash';
import { client } from 'graphql-client';

import { ExternalRateDataFragment, ContractRoleOrderDataFragment, RateUnit } from 'generated/types';
import { RoleRates } from '../RoleRates';
import { CustomDragLayer, DragItem } from 'components';
import { RoleRatesDragItem } from '../components/RoleRatesDragItem';
import { ContractRolesOrderDocument, useChangeContractRoleOrderMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import styles from './styles.module.scss';

interface Props {
  projectId: string;
  contractId: string;
  currency?: string;
  unit: RateUnit;
  roleRates: [string, SeniorityRates][];
  getAllSeniorityRates?: (key: string) => undefined | { [key: string]: ExternalRateDataFragment[] };
  additionalContent?: ReactNode;
}

export type SeniorityRates = {
  [key: string]: ExternalRateDataFragment[];
};

export const RatesDraggableList: FC<Props> = ({
  roleRates,
  getAllSeniorityRates,
  projectId,
  contractId,
  additionalContent,
  ...props
}) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);
  const [dragItemTitle, setDragItemTitle] = useState('');
  const [list, setList] = useState(roleRates);
  const [isDragging, setDragging] = useState<boolean | null>(null);
  const changeIsDragging = useCallback((value: boolean) => setDragging(value), []);
  const onChangeDragItemTitle = useCallback((title: string) => setDragItemTitle(title), []);
  const disableDragging = roleRates.length <= 1;

  useEffect(() => {
    setList(roleRates);
  }, [roleRates]);

  const [changeOrder] = useChangeContractRoleOrderMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const updateCache = (data: ContractRoleOrderDataFragment[]) => {
    client.writeQuery({
      query: ContractRolesOrderDocument,
      variables: {
        companyId: userData!.company.id,
        projectId: projectId,
      },
      data: {
        contractRolesOrder: data,
      },
    });
  };

  const handleChangeOrder = (value: [string, SeniorityRates][]) => {
    const newOrder = value.map<ContractRoleOrderDataFragment>(([key, value], index) => ({
      orderingIndex: index,
      roleName: key,
      // structure of data don't allow us to have roleId at the top level, but it is the same, so we could get roleId from the first array item
      roleId: Object.values(value)[0][0].roleId,
      __typename: 'ContractRoleOrder',
    }));

    updateCache(newOrder);

    changeOrder({
      variables: {
        companyId: userData!.company.id,
        projectId,
        data: {
          contractId,
          rolesOrder: newOrder.map(({ orderingIndex, roleId }) => ({ orderingIndex, roleId })),
        },
      },
    });
  };

  useEffect(() => {
    if (!isDragging && isBoolean(isDragging) && list.length) {
      handleChangeOrder(list);
    }
  }, [isDragging, list.length]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setList((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      }),
    );
  }, []);

  const onScrollToListTop = useCallback(() => {
    scrollAnchorRef.current?.scrollIntoView(true);
  }, []);

  return (
    <div className={styles.container}>
      <div ref={scrollAnchorRef} className={styles.scrollAnchor} />
      <CustomDragLayer className={styles.dragLayer}>
        <RoleRatesDragItem role={dragItemTitle} isDragging />
      </CustomDragLayer>
      {list.map(([key, value], index) => (
        <DragItem
          key={key}
          id={key}
          index={index}
          moveCard={moveCard}
          onEndMove={onScrollToListTop}
          changeIsDragging={changeIsDragging}
          setDragItemId={onChangeDragItemTitle}
          disableDragging={disableDragging}
          hideDragPreview
        >
          {isDragging ? (
            <RoleRatesDragItem role={key} />
          ) : (
            <RoleRates
              role={key}
              seniorityRates={value}
              contractId={contractId}
              projectId={projectId}
              allSeniorityRates={getAllSeniorityRates?.(key)}
              disableDragging={disableDragging}
              {...props}
            />
          )}
        </DragItem>
      ))}
      {!isDragging && additionalContent}
    </div>
  );
};
