import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts';
import { graphqlOnError, updateProjectTotalByMilestones } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import {
  ContractAssignmentsListDocument,
  MilestoneDataFragmentDoc,
  MilestonesSectionDocument,
  useCreateMilestoneMutation,
} from 'generated/graphql';
import { FormValues, MilestoneForm } from '../components/MilestoneForm';
import { toast } from 'react-toastify';
import { Reference } from '@apollo/client';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  contractId: string;
  projectId: string;
  onClose: () => void;
}

export const CreateMilestone: FC<Props> = ({ contractId, projectId, onClose }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [createProjectMilestone, { loading }] = useCreateMilestoneMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('viewProjectDetail.milestones.createSuccessfully'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.createMilestone) return;

      const createdMilestoneRef = cache.writeFragment({
        data: data.createMilestone,
        fragment: MilestoneDataFragmentDoc,
      });

      cache.modify({
        fields: {
          milestones: (items: Reference[] = []) => [...items, createdMilestoneRef],
        },
      });

      updateProjectTotalByMilestones({ contractId, companyId: userData!.company.id, cache });
    },
    refetchQueries: [
      ContractAssignmentsListDocument,
      {
        query: MilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: true,
          projectId: projectId,
        },
      },
      {
        query: MilestonesSectionDocument,
        variables: {
          companyId: userData!.company.id,
          companyCurrency: false,
          projectId: projectId,
        },
      },
    ],
  });

  const onCreate = useCallback(async (values?: FormValues) => {
    await createProjectMilestone({
      variables: {
        data: {
          title: values?.title || '',
          paymentDate: format(new Date(values?.paymentDate || ''), DEFAULT_DATE_FORMAT),
          notes: values?.notes,
          amount: +(values?.amount || 0),
        },
        companyId: userData!.company.id,
        projectId,
        contractId,
      },
    });
  }, []);

  return <MilestoneForm onSubmit={onCreate} loading={loading} onCancel={onClose} />;
};
