import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { AssignmentInfo, Tag } from 'components';
import { Popover } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { useTranslation } from 'react-i18next';
import { MemberContractAssignmentDataFragment } from 'generated/types';
import { ProjectsLink as ProjectIcon } from 'icons';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  assignments: MemberContractAssignmentDataFragment[];
  selectedAssignmentId?: string;
  onChange: (value: string) => void;
}

export const AssignmentsAutocomplete: FC<Props> = ({ assignments, selectedAssignmentId, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAssignment, setSelectedAssignment] = useState<MemberContractAssignmentDataFragment | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedAssignment) {
      const assignment = assignments.find(({ id }) => id === selectedAssignmentId);
      setSelectedAssignment(assignment || null);
    }

    if (selectedAssignment && !selectedAssignmentId) {
      setSelectedAssignment(null);
    }
  }, [selectedAssignmentId]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (_event: ChangeEvent<unknown>, value: MemberContractAssignmentDataFragment[]) => {
    handleClose();
    // We don't need "multiple", but it allows to customize input value(tag), so it's a reason why we use it here
    // So we get here array of values as autocomplete value
    const [firstValue, nextValue] = value;
    onChange(nextValue?.id || firstValue?.id || '');
    setSelectedAssignment(nextValue || firstValue);
  };

  const filterOptions = (
    options: MemberContractAssignmentDataFragment[],
    { inputValue }: FilterOptionsState<MemberContractAssignmentDataFragment>,
  ) => {
    return options.filter(({ contract }) =>
      contract?.projectName.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
    );
  };

  const renderOption = ({ contract, role, seniority }: MemberContractAssignmentDataFragment) => {
    if (!contract) return null;
    const { id, projectName, projectColor, projectId } = contract;

    return (
      <AssignmentInfo
        data={{
          id: projectId,
          contractId: id,
          name: projectName,
          role,
          seniority: seniority || '',
          color: projectColor,
        }}
      />
    );
  };

  const renderTags = (options: MemberContractAssignmentDataFragment[], getTagProps: AutocompleteGetTagProps) =>
    options.map(({ id, contract }, index) => {
      const tagProps = getTagProps({ index });

      return (
        <Tag
          {...tagProps}
          key={id}
          name={contract?.projectName || ''}
          startIcon={<span className={styles.projectColor} style={{ backgroundColor: contract?.projectColor || '' }} />}
          color="white"
          className={styles.tag}
          tooltipClassName={styles.tagTooltip}
        />
      );
    });

  return (
    <>
      <button aria-describedby="tag-popper" type="button" className={styles.valueButton} onClick={handleClick}>
        {selectedAssignment ? (
          <Tag
            name={selectedAssignment.contract?.projectName || ''}
            startIcon={
              <span
                className={styles.projectColor}
                style={{ backgroundColor: selectedAssignment.contract?.projectColor || '' }}
              />
            }
            color="white"
            className={clsx(styles.tag, styles.tagValue)}
            tooltipClassName={styles.tagTooltip}
          />
        ) : (
          <ProjectIcon className={styles.projectIcon} />
        )}
      </button>

      <Popover
        id="tag-popper"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContainer}>
          <Autocomplete
            multiple
            id="assignments"
            disableCloseOnSelect
            disableClearable
            disablePortal
            open
            filterOptions={(options, params) => filterOptions(options, params)}
            popupIcon={null}
            classes={{
              inputRoot: styles.inputRoot,
              popperDisablePortal: styles.autocompletePopper,
              groupLabel: styles.groupLabel,
              listbox: styles.listBox,
              option: styles.option,
              noOptions: styles.noOptions,
            }}
            debug
            groupBy={() => t('tag.selectOption')}
            noOptionsText={t('timeTracking.noAssignmentsFound')}
            options={assignments}
            value={selectedAssignment ? [selectedAssignment] : []}
            getOptionLabel={(option) => option.contract?.projectName || ''}
            onChange={handleChange}
            renderOption={renderOption}
            renderTags={renderTags}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                classes={{
                  root: styles.textFieldRoot,
                }}
              />
            )}
          />
        </div>
      </Popover>
    </>
  );
};
