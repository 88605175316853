import React, { memo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { AntSwitch, CountChip, SidebarItem, Tooltip, UserInfoNav } from 'components';
import { links } from 'App';
import {
  ActivityActiveLink,
  ActivityLink,
  BillingActiveLink,
  BillingLink,
  FileActiveLink,
  FileLink,
  FinancialHiddenIcon,
  FinancialIcon,
  InsightsActiveLink,
  InsightsLink,
  ProjectsActiveLink,
  ProjectsLink,
  ResourceActivePlanningLink,
  ResourcePlanningLink,
  ScenariosActiveLink,
  ScenariosLink,
  SettingsActiveLink,
  SettingsLink,
  SkelarMinLogoIcon,
  SkelarTitle,
  TeamActiveLink,
  TeamLink,
  TriangleIcon,
} from 'icons';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

import { useTranslation } from 'react-i18next';
import { EventBus, isAdminPermission } from 'utils';
import { useAuth, useBaseLayoutContext, useSettings } from 'contexts';
import { NAVBAR_FINISHED_RESIZE } from 'consts';

import { ActivityTabs, ProjectsTabs, ProjectsViews, ScenarioTabs } from 'types';
import { useCountPendingRequest } from 'hooks';
import { Badge } from '@material-ui/core';

import styles from './Sidebar.module.scss';

const emitNavbarTransitionEnd = debounce(() => EventBus.emit(NAVBAR_FINISHED_RESIZE), 10);
// const emitNavbarTransitionStart = debounce(() => EventBus.emit(NAVBAR_STARTED_RESIZE), 10);

export const Sidebar = memo(() => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const { isFinancialsHidden, onToggleIsFinancialsHidden } = useSettings();
  const { isNavBarExpanded, onToggle } = useBaseLayoutContext();
  const sidebarRef = useRef<null | HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { countPendingRequest } = useCountPendingRequest();

  const viewTeamMemberLink = links.ViewTeamMember({ id: '0' }).split('/')[1];
  const teamsRouteIsActive =
    pathname.includes(links.ArchivedTeamMembers()) ||
    pathname.includes(links.ActiveTeamMembers()) ||
    pathname.startsWith(viewTeamMemberLink, 1);

  const projectDetailLink = links.ProjectDetail({ id: '0' }).split('/')[1];
  const projectsRouteIsActive = pathname.startsWith(links.Projects()) || pathname.startsWith(projectDetailLink, 1);

  const scenarioDetailLink = links.ScenarioDetails({ id: '3be54aae-42f1-49b3-aa87-0da25c2de917' }).split('/')[1];
  const scenariosRouteIsActive =
    pathname.includes(links.Scenarios({ tab: ScenarioTabs.active }).split('/')[1]) ||
    pathname.startsWith(scenarioDetailLink, 1);

  // this effect is needed because react-calendar-timeline has an issue and needs a resize event when something changes viewport width
  useEffect(() => {
    const transitionEndHandler = (event: TransitionEvent) => {
      if (event.propertyName === 'width') {
        emitNavbarTransitionEnd();
      }
    };
    if (sidebarRef.current) {
      (sidebarRef.current as HTMLElement).addEventListener('transitionend', transitionEndHandler);
    }

    return () => {
      if (sidebarRef.current) {
        (sidebarRef.current as HTMLElement).removeEventListener('transitionend', transitionEndHandler);
      }
    };
  }, [isNavBarExpanded]);

  return (
    <div
      ref={sidebarRef}
      className={clsx(styles.sidebar, {
        [styles.collapsed]: !isNavBarExpanded,
      })}
    >
      <div className={clsx(styles.logoWrapper, !isNavBarExpanded && styles.closed)}>
        <div
          className={clsx(styles.sidebarToggle, !isNavBarExpanded && styles.closed)}
          onClick={onToggle}
          key={sidebarRef.current?.clientWidth}
        >
          <TriangleIcon />
        </div>
        <SkelarMinLogoIcon />
        {isNavBarExpanded && <SkelarTitle />}
      </div>
      <div>
        <SidebarItem
          route={links.Insights()}
          iconOnly={!isNavBarExpanded}
          isActive={() => pathname.includes(links.Insights())}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.insights')}
        >
          {pathname.includes(links.Insights()) ? <InsightsActiveLink /> : <InsightsLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.insights')}</span>
        </SidebarItem>

        <SidebarItem
          route={links.ActiveTeamMembers()}
          iconOnly={!isNavBarExpanded}
          isActive={() => teamsRouteIsActive}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.teams')}
        >
          {teamsRouteIsActive ? <TeamActiveLink /> : <TeamLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.teams')}</span>
        </SidebarItem>
        <SidebarItem
          route={links.Projects({ tab: ProjectsTabs.active, view: ProjectsViews.projects })}
          iconOnly={!isNavBarExpanded}
          isActive={() => projectsRouteIsActive}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.projects')}
        >
          {projectsRouteIsActive ? <ProjectsActiveLink /> : <ProjectsLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.projects')}</span>
        </SidebarItem>
        <SidebarItem
          route={links.ResourcePlanning()}
          iconOnly={!isNavBarExpanded}
          isActive={() => pathname.includes(links.ResourcePlanning())}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.resourcePlanning')}
        >
          {pathname.includes(links.ResourcePlanning()) ? (
            <ResourceActivePlanningLink />
          ) : (
            <ResourcePlanningLink className={styles.icon} />
          )}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>
            {t('navigation.resourcePlanning')}
          </span>
        </SidebarItem>
        <SidebarItem
          route={links.Billing()}
          iconOnly={!isNavBarExpanded}
          isActive={() => pathname.startsWith(links.Billing())}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.billing')}
        >
          {pathname.startsWith(links.Billing()) ? <BillingActiveLink /> : <BillingLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.billing')}</span>
        </SidebarItem>
        <SidebarItem
          className={clsx(!isAdminPermission(userData!.type) && 'd-none')}
          route={links.Files()}
          iconOnly={!isNavBarExpanded}
          isActive={() => pathname.includes(links.Files())}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.files')}
        >
          {pathname.includes(links.Files()) ? <FileActiveLink /> : <FileLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.files')}</span>
        </SidebarItem>
        <SidebarItem
          route={links.Scenarios({ tab: ScenarioTabs.active })}
          iconOnly={!isNavBarExpanded}
          isActive={() => scenariosRouteIsActive}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.scenarios')}
        >
          {scenariosRouteIsActive ? <ScenariosActiveLink /> : <ScenariosLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.scenarios')}</span>
        </SidebarItem>

        <div className={styles.navbarDivider} />

        <SidebarItem
          route={links.Activity({ tab: ActivityTabs.requestsInbox })}
          iconOnly={!isNavBarExpanded}
          isActive={() => pathname.startsWith(links.Activity())}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.activity')}
        >
          <Badge
            invisible={isNavBarExpanded || (!isNavBarExpanded && !countPendingRequest)}
            classes={{
              dot: styles.badge,
            }}
            badgeContent=" "
            variant="dot"
          >
            {pathname.startsWith(links.Activity()) ? <ActivityActiveLink /> : <ActivityLink className={styles.icon} />}
          </Badge>
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.activity')}</span>
          {!!countPendingRequest && <CountChip className="ml-auto" count={countPendingRequest} />}
        </SidebarItem>

        {/*TODO: uncomment when Payroll functionality will be ready*/}
        {/*{userData && isAdminPermission(userData.type) && (*/}
        {/*  <div className="d-none">*/}
        {/*    {isNavBarExpanded && (*/}
        {/*      <div className={clsx(styles.sidebarHeading, 'mb-16 mt-24 uppercase')}>{t('navigation.accountant')}</div>*/}
        {/*    )}*/}
        {/*    <SidebarItem*/}
        {/*      route={links.Payroll()}*/}
        {/*      iconOnly={!isNavBarExpanded}*/}
        {/*      isActive={() => pathname.includes(links.Payroll())}*/}
        {/*      activeClass={styles.active}*/}
        {/*    >*/}
        {/*      {pathname.includes(links.Payroll()) ? <TeamActiveLink /> : <TeamLink />}*/}
        {/*      <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.payroll')}</span>*/}
        {/*    </SidebarItem>*/}
        {/*    <div className={styles.navbarDivider} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      {userData && isAdminPermission(userData.type) && (
        <SidebarItem
          route={links.Users()}
          iconOnly={!isNavBarExpanded}
          isActive={() => pathname.includes(links.Settings())}
          activeClass={styles.active}
          tooltipText={isNavBarExpanded ? '' : t('navigation.settings')}
        >
          {pathname.includes(links.Settings()) ? <SettingsActiveLink /> : <SettingsLink className={styles.icon} />}
          <span className={isNavBarExpanded ? 'visible fade-animation' : 'hidden'}>{t('navigation.settings')}</span>
        </SidebarItem>
      )}

      <div className="mt-auto transition-02">
        <Tooltip
          placement="right"
          alwaysShowTooltip
          title={isFinancialsHidden ? t('hideFinancial.allFinancialsHidden')! : t('hideFinancial.allFinancialsShown')!}
          className={clsx(styles.hideFinancialTooltip, !isNavBarExpanded && 'px-0')}
        >
          <div className={styles.hideFinancial}>
            {isNavBarExpanded ? t('hideFinancial.showFinancials') : ''}
            <AntSwitch
              checked={!isFinancialsHidden}
              onChange={onToggleIsFinancialsHidden}
              name="hideFinancialInfo"
              icon={<FinancialIcon className={styles.financialIcon} />}
              checkedIcon={<FinancialHiddenIcon className={clsx(styles.financialIcon, styles.checkedFinancialIcon)} />}
            />
          </div>
        </Tooltip>

        <div className={styles.userInfoNav}>
          <UserInfoNav isFullView={isNavBarExpanded} />
        </div>
      </div>
    </div>
  );
});

Sidebar.displayName = 'Sidebar';
