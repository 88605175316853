import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useCommissionsQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'components';
import { CommissionsHeader } from './components/CommissionsHeader';
import { CommissionItem } from './components/CommissionItem';
import { CreateCommission } from './CreateCommission';
import Button from '@material-ui/core/Button';
import { ChevronIcon, PlusIcon } from 'icons';
import { isAfter, isSameMonth } from 'date-fns';

import styles from './styles.module.scss';

interface Props {
  contractId: string;
  projectId: string;
  onChangeIsCommissionsUnsaved: (value: boolean) => void;
  onChangeCurrentCommission: (value: number) => void;
  currentCommission: number;
}

export const Commissions: FC<Props> = ({
  contractId,
  projectId,
  onChangeIsCommissionsUnsaved,
  onChangeCurrentCommission,
  currentCommission,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [isCreating, setIsCreating] = useState(false);

  const { data: { commissions = [] } = {}, loading } = useCommissionsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      contractId,
    },
    fetchPolicy: 'network-only',
  });

  const sortedCommission = useMemo(
    () => [...commissions].sort((a, b) => new Date(a.effectiveFrom).getTime() - new Date(b.effectiveFrom).getTime()),
    [commissions],
  );

  useEffect(() => {
    const filteredCommissionItem = sortedCommission.filter(
      (commission) =>
        isSameMonth(new Date(), new Date(commission.effectiveFrom)) ||
        isAfter(new Date(), new Date(commission.effectiveFrom)),
    );
    const currentCommissionItem = filteredCommissionItem[filteredCommissionItem.length - 1];

    if (currentCommissionItem || currentCommission !== 0) {
      onChangeCurrentCommission(currentCommissionItem?.commission || 0);
    }
  }, [sortedCommission]);

  const isEmptyList = !commissions.length && !loading;
  const cancelCreating = () => setIsCreating(false);
  const [editingCommissions, setEditingCommissions] = useState<string[]>([]);

  const onToggleEditing = useCallback((id: string) => {
    setEditingCommissions((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
  }, []);

  useEffect(() => {
    onChangeIsCommissionsUnsaved(!!editingCommissions.length || isCreating);
  }, [editingCommissions, isCreating]);

  return (
    <div className={styles.container}>
      <Accordion
        header={
          <>
            {t('viewProjectDetail.commissions.label')}
            <div className={styles.count}>{commissions.length}</div>
          </>
        }
        expandIcon={<ChevronIcon />}
        detailsClassName={styles.accordionDetails}
        summaryClassName={styles.accordionSummary}
      >
        <>
          <div className={styles.box}>
            <CommissionsHeader />
            {sortedCommission.map((commission) => (
              <CommissionItem
                key={commission.id}
                contractId={contractId}
                projectId={projectId}
                projectCommission={commission}
                onToggleEditing={onToggleEditing}
              />
            ))}
            {(isCreating || isEmptyList) && (
              <CreateCommission projectId={projectId} contractId={contractId} onClose={cancelCreating} />
            )}
          </div>

          {!isCreating && !isEmptyList && (
            <Button
              variant="text"
              color="secondary"
              className={styles.button}
              startIcon={<PlusIcon className={styles.icon} />}
              onClick={() => setIsCreating(true)}
            >
              {t('viewProjectDetail.commissions.addNewCommission')}
            </Button>
          )}
        </>
      </Accordion>
    </div>
  );
};
