import React, { memo, useMemo } from 'react';
import { OptionalDeep } from 'ts-toolbelt/out/Object/Optional';
import { MONTH_PERIOD } from 'consts';
import { useTimelineContext } from 'contexts';
import { Assignment as AssignmentType, Scalars } from 'generated/types';
import { Boundaries, useTimelinePeriodDays } from 'hooks';

import {
  getAssignmentDate,
  getEndDateOfPreviousPeriod,
  getStartDateOfNextPeriod,
  getStartEndPeriodDates,
} from 'views/ResourcePlanning/utils';
import { AssignmentRowItem } from 'views/ResourcePlanning/types';
import {
  addDays,
  areIntervalsOverlapping,
  differenceInBusinessDays,
  differenceInCalendarDays,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
  subDays,
} from 'date-fns';
import { addTimezoneOffset } from 'utils';
import { EmptyArea } from './AssignmentAreas';
import { AssignmentsRowItem } from './AssignmentsRowItem';

import styles from './styles.module.scss';

interface Props {
  projectId?: string;
  color?: string;
  boundaries: Boundaries;
  memberId?: string;
  assignments: OptionalDeep<AssignmentType>[];
  date: Date;
}

interface AssignmentRowData {
  assigned: boolean;
  assignment?: AssignmentRowItem;
  start?: Scalars['DateTime'];
  amount: number;
  last?: boolean;
  continues?: boolean;
  hours?: number;
  color?: string;
}

export const AssignmentsRow = memo<Props>(({ projectId, color, boundaries, memberId, assignments, date }) => {
  const { collapsedWeekends, timelinePeriod } = useTimelineContext();
  const { days, monthDays } = useTimelinePeriodDays(date);
  const [startPeriodDate, endPeriodDate] = getStartEndPeriodDates(date, timelinePeriod);
  const isMonthView = timelinePeriod === MONTH_PERIOD;

  const { prevPeriod, nextPeriod } = useMemo(
    () => ({
      prevPeriod: getEndDateOfPreviousPeriod(date),
      nextPeriod: getStartDateOfNextPeriod(date),
    }),
    [date],
  );

  const firstPeriodDay = timelinePeriod === MONTH_PERIOD ? monthDays[0] : days[0];
  const lastPeriodDay = timelinePeriod === MONTH_PERIOD ? monthDays[monthDays.length - 1] : days[days.length - 1];

  const getDiff = (dayA: Date | number, dayB: Date | number) => {
    return collapsedWeekends ? differenceInBusinessDays(dayA, dayB) : differenceInCalendarDays(dayA, dayB);
  };

  const rowAssignments = assignments
    ? assignments
        .filter((assignment) =>
          areIntervalsOverlapping(
            { start: startPeriodDate, end: endPeriodDate },
            { start: getAssignmentDate(assignment.startDate), end: getAssignmentDate(assignment.endDate) },
            { inclusive: true },
          ),
        )
        .reduce<AssignmentRowData[]>((acc, rec, index) => {
          const isLastAssignment = index === assignments.length - 1;
          const prevAssignment = index > 0 ? assignments[index - 1] : undefined;
          const start = getAssignmentDate(rec?.startDate);
          const end = getAssignmentDate(rec?.endDate);
          const startToCompare = isMonthView ? prevPeriod : startPeriodDate;
          const endToCompare = isMonthView ? nextPeriod : endPeriodDate;

          const item = {
            assigned: true,
            assignment: rec,
            start: rec?.startDate,
            amount: isSameDay(getAssignmentDate(rec?.endDate), getAssignmentDate(rec?.startDate))
              ? 1
              : getDiff(endOfDay(getAssignmentDate(rec?.endDate)), getAssignmentDate(rec?.startDate)),
            last: isBefore(start, startToCompare),
            continues: isAfter(end, endToCompare),
            hours: rec?.allocationTimeAmount ?? 0,
            color,
          };

          const lastEmptyItem = {
            assigned: false,
            start: addDays(getAssignmentDate(rec.endDate), 1).toISOString(),
            end: addTimezoneOffset(lastPeriodDay).toISOString(),
            amount: getDiff(addTimezoneOffset(endOfDay(lastPeriodDay)), addDays(getAssignmentDate(rec.endDate), 1)),
          };

          if (isBefore(getAssignmentDate(rec.startDate), firstPeriodDay) && index === 0) {
            const firstItem = {
              assigned: true,
              assignment: rec,
              start: rec?.startDate,
              amount: isSameDay(getAssignmentDate(rec?.endDate), getAssignmentDate(rec?.startDate))
                ? 1
                : getDiff(endOfDay(getAssignmentDate(rec?.endDate)), getAssignmentDate(rec?.startDate)),
              last: isBefore(start, startToCompare),
              continues: isAfter(end, endToCompare),
              hours: rec?.allocationTimeAmount ?? 0,
              color,
            };
            return isLastAssignment ? [...acc, firstItem, lastEmptyItem] : [...acc, firstItem];
          }

          if (isSameDay(firstPeriodDay, getAssignmentDate(rec.startDate)) && index === 0) {
            return isLastAssignment ? [...acc, item, lastEmptyItem] : [...acc, item];
          }

          if (isAfter(getAssignmentDate(rec.startDate), firstPeriodDay) && index === 0) {
            const firstEmptyItem = {
              assigned: false,
              start: addTimezoneOffset(firstPeriodDay).toISOString(),
              end: subDays(getAssignmentDate(rec.startDate), 1).toISOString(),
              amount: getDiff(getAssignmentDate(rec.startDate), firstPeriodDay),
            };

            return isLastAssignment ? [...acc, firstEmptyItem, item, lastEmptyItem] : [...acc, firstEmptyItem, item];
          }

          if (prevAssignment) {
            const prevAssignmentStart = addDays(getAssignmentDate(prevAssignment!.endDate), 1);
            const prevAssignmentEnd = endOfDay(subDays(getAssignmentDate(rec.startDate), 1));

            const prevEmptyItem = {
              assigned: false,
              start: prevAssignmentStart.toISOString(),
              end: prevAssignmentEnd.toISOString(),
              amount: getDiff(addTimezoneOffset(prevAssignmentEnd), prevAssignmentStart),
            };

            return isLastAssignment ? [...acc, prevEmptyItem, item, lastEmptyItem] : [...acc, prevEmptyItem, item];
          }

          return acc;
        }, [])
    : [];

  return (
    <section className={styles.assignmentRow}>
      {rowAssignments.map(({ start, assigned, assignment, ...props }) =>
        assigned && assignment ? (
          <AssignmentsRowItem
            {...props}
            projectId={projectId}
            contractId={assignment.contractId}
            boundaries={boundaries}
            memberId={memberId}
            assignment={assignment}
            date={date}
          />
        ) : (
          <EmptyArea last={props.last} continues={props.continues} amount={props.amount} key={start?.toString()} />
        ),
      )}
    </section>
  );
});

AssignmentsRow.displayName = 'Timeline.AssignmentsRow';
