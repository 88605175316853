export const PROJECTS_TABLE_HIDDEN_COLUMNS = 'PROJECTS_TABLE_HIDDEN_COLUMNS';
export const PROJECTS_TABLE_COLUMN_ORDER = 'PROJECTS_TABLE_COLUMN_ORDER';

export enum ProjectsTableColumns {
  Project = 'project',
  Client = 'client',
  Type = 'type',
  PM = 'pm',
  File = 'file',
  ActiveContracts = 'activeContracts',
}
