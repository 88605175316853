import { SortByFn } from 'react-table';
import { isAfter } from 'date-fns';
import { useCallback } from 'react';
import { ContractDataFragment } from 'generated/types';

type ContractData = ContractDataFragment & { margin: number; forecastMargin: number };

export const useContractTableSorts = () => {
  const sortNameType = useCallback<SortByFn<ContractData>>((rowA, rowB) => {
    return rowA.original.name.localeCompare(rowB.original.name, 'en');
  }, []);

  const sortProjectNameType = useCallback<SortByFn<ContractData>>((rowA, rowB) => {
    return rowA.original.projectName.localeCompare(rowB.original.projectName, 'en');
  }, []);

  const sortPMType = useCallback<SortByFn<ContractData>>((rowA, rowB) => {
    return rowA.original.projectPM.localeCompare(rowB.original.projectPM, 'en');
  }, []);

  const sortEndDateType = useCallback<SortByFn<ContractData>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.end_date), new Date(rowB.original.end_date)) ? 1 : -1;
  }, []);

  const sortStartDateType = useCallback<SortByFn<ContractData>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.start_date), new Date(rowB.original.start_date)) ? 1 : -1;
  }, []);

  return {
    sortNameType,
    sortProjectNameType,
    sortPMType,
    sortStartDateType,
    sortEndDateType,
  };
};
