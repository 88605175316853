import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { endOfDay, startOfDay } from 'date-fns';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

import { AbsoluteSpinner, Autocomplete, DatePicker, LoadingButton, NumberTextField } from 'components';
import { CostRate, Currency, RateUnit, Scalars } from 'generated/types';
import { graphqlOnError, removeUTCTimezone, submitForm, valueToCurrency } from 'utils';
import { DashIcon } from 'icons';
import { useCurrenciesQuery } from 'generated/graphql';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';

import styles from './styles.module.scss';

export interface CostRateFormValues {
  unit: { id: RateUnit; name: string };
  start_date: string | Date;
  rate_amount: number | null;
  rateCurrency: Currency;
  end_date: string | null;
}

interface NewCostRateProps {
  onSubmit: (values: CostRateFormValues) => void | Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  data?: CostRate[];
  costRate?: CostRate | null;
  setSelectedCostRate: (costRate: CostRate) => void;
}

export const NewCostRate = ({
  submitLabel,
  onSubmit,
  onCancel,
  data,
  costRate,
  setSelectedCostRate,
}: NewCostRateProps) => {
  const { t } = useTranslation();
  const { costUnitId } = useParams<{ costUnitId: string }>();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const editingCostRate = data?.find((item) => item.id === costUnitId);

  useEffect(() => {
    if (!costRate && costUnitId && editingCostRate) {
      setSelectedCostRate({ ...editingCostRate, rate_amount: valueToCurrency(editingCostRate.rate_amount) });
    }
  }, [editingCostRate]);

  const { data: { currencies = [] as Currency[] } = {}, loading: currenciesLoading } = useCurrenciesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const getCurrencyLabel = (currency: Currency) =>
    `${currency?.code?.toUpperCase()} - ${t(`currency.${currency?.code}`)}`;

  const findCurrency = (id?: string | null) => currencies.find((currency) => currency.id === id);

  const initialCurrency = useMemo(
    () =>
      (editingCostRate?.rateCurrencyId
        ? findCurrency(editingCostRate?.rateCurrencyId)
        : findCurrency(userData?.company.primaryCurrencyId))!,
    [currencies],
  );

  const unitTypes = useMemo(
    () => [
      { id: RateUnit.Hour, name: t('rateUnit.hour') },
      { id: RateUnit.Day, name: t('rateUnit.day') },
      { id: RateUnit.Month, name: t('rateUnit.month') },
    ],
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        unit: Yup.object().nullable().required(t('forms.newCostRate.unitError')),
        rate_amount: Yup.number()
          .typeError(t('forms.newCostRate.costDecimal'))
          .required(t('forms.newCostRate.costError'))
          .test('maxDigitsAfterDecimal', t('forms.newCostRate.costDecimal'), (number) =>
            /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
          )
          .positive(t('forms.newCostRate.costPositiveError')),
        rateCurrency: Yup.object().required(t('forms.newCostRate.currencyError')),
        start_date: Yup.string().nullable().required(t('forms.newCostRate.effectiveFromRequired')),
        end_date: Yup.string().nullable(),
      }),
    [],
  );

  const handleSubmit = (values: CostRateFormValues, { setSubmitting }: FormikHelpers<CostRateFormValues>) => {
    submitForm(values, setSubmitting, onSubmit);
  };

  if (currenciesLoading) return <AbsoluteSpinner />;

  return (
    <>
      {costRate || !costUnitId ? (
        <Formik<CostRateFormValues>
          validationSchema={validationSchema}
          initialValues={{
            unit: unitTypes.find((unitType) => unitType.id === costRate?.unit) || unitTypes[2],
            rate_amount: costRate?.rate_amount || null,
            rateCurrency: initialCurrency,
            start_date: costRate?.start_date ? removeUTCTimezone(costRate?.start_date) : startOfDay(new Date()),
            end_date: costRate?.end_date ? removeUTCTimezone(costRate?.end_date) : null,
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, submitCount, setValues, handleBlur, handleChange, values, errors, touched }) => (
            <Form className="form">
              <div>
                <InputLabel className="required">{t('forms.newCostRate.unit')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { unit },
                    },
                  }: FieldProps<CostRateFormValues['unit'], CostRateFormValues>) => {
                    return (
                      <Autocomplete
                        className="mb-24"
                        name="unit"
                        value={unit}
                        onBlur={handleBlur}
                        placeholder={t('forms.newCostRate.unit')}
                        options={unitTypes}
                        error={touched.unit ? errors.unit : undefined}
                        onChange={(unit: CostRateFormValues['unit']) => {
                          setValues({
                            ...values,
                            unit,
                          });
                        }}
                      />
                    );
                  }}
                </Field>

                <div className={styles.twoItemsBox}>
                  <div className="flex-1">
                    <InputLabel>{t('forms.newCostRate.cost')}</InputLabel>
                    <NumberTextField
                      error={Boolean((submitCount || touched.rate_amount) && errors.rate_amount)}
                      helperText={(submitCount || touched.rate_amount) && t(errors.rate_amount!)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="rate_amount"
                      value={values.rate_amount}
                      placeholder="0"
                      className="mb-24"
                    />
                  </div>

                  <div className="flex-1">
                    <InputLabel required>{t('forms.newContract.currency')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { rateCurrency },
                        },
                      }: FieldProps<CostRateFormValues['rateCurrency'], CostRateFormValues>) => (
                        <Autocomplete
                          placeholder={t('forms.newContract.currency')}
                          className="mb-24"
                          value={rateCurrency}
                          name="rateCurrency"
                          onBlur={handleBlur}
                          disableClearable
                          error={touched.rateCurrency ? errors.rateCurrency : undefined}
                          getOptionLabel={getCurrencyLabel}
                          options={currencies}
                          onChange={(rateCurrency: CostRateFormValues['rateCurrency']) =>
                            setValues({ ...values, rateCurrency })
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="flex gap-16">
                  <div className="flex-1">
                    <InputLabel>{t('forms.newCostRate.effectiveFrom')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { start_date },
                          submitCount,
                        },
                      }: FieldProps<CostRateFormValues['start_date'], CostRateFormValues>) => {
                        return (
                          <DatePicker
                            value={start_date}
                            error={Boolean(submitCount && errors.start_date)}
                            helperText={!!submitCount && errors.start_date}
                            onChange={(start_date: Scalars['DateTime']) => {
                              setValues({
                                ...values,
                                start_date: startOfDay(start_date),
                              });
                            }}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className="pt-36">
                    <DashIcon />
                  </div>
                  <div className="flex-1">
                    <InputLabel>{t('forms.newCostRate.effectiveTo')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { end_date },
                          submitCount,
                        },
                      }: FieldProps<CostRateFormValues['end_date'], CostRateFormValues>) => {
                        return (
                          <DatePicker
                            value={end_date}
                            error={Boolean(submitCount && errors.end_date)}
                            helperText={!!submitCount && errors.end_date}
                            onChange={(end_date: Scalars['DateTime']) =>
                              setValues({
                                ...values,
                                end_date: end_date ? (endOfDay(end_date) as Scalars['DateTime']) : null,
                              })
                            }
                          />
                        );
                      }}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="controls">
                <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
                  {submitLabel ?? t('forms.newCostRate.create')}
                </LoadingButton>
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                  {t('forms.cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <AbsoluteSpinner />
      )}
    </>
  );
};
