import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { debounce } from '@material-ui/core';

import mixpanel from 'mixpanel-browser';
import { CommonFilterOptions, GetRouteProps, GroupByFilter, ModuleName, ProjectsTabs } from 'types';
import {
  ArchiveContract,
  ChangeFiltersData,
  CommonFilters,
  CommonFiltersSection,
  ConfirmIconButton,
  FilterRadioPopover,
  MobileSearch,
  Search,
  TableCountHeader,
} from 'components';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions, useRestoreContract } from 'hooks';
import {
  ActionsType,
  ContractListDetailsDataFragment,
  ContractMarginsDataFragment,
  ProjectType,
} from 'generated/types';
import { useContractsListDataQuery, useContractsMarginsQuery, useContractsQuery } from 'generated/graphql';
import { graphqlOnError, searchFilter } from 'utils';
import { useAuth } from 'contexts';
import { localStorageManager } from 'services';
import { CONTRACTS_GROUP_BY } from 'consts';
import { ApolloError } from '@apollo/client/errors';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { ContractsTable } from 'views/Projects/components/ContractsTable';
import { ArchiveIcon } from 'icons';

import styles from './styles.module.scss';

interface ContractsMarginsData {
  [key: string]: ContractMarginsDataFragment;
}
interface ContractsDetailsData {
  [key: string]: ContractListDetailsDataFragment;
}

const ContractsViewRoute = Route(
  {
    tab: Route.params.oneOf(...Object.values(ProjectsTabs)),
    search: Route.query.string,
    type: Route.query.array(Route.query.string),
  },
  ({ tab }) => `/projects/contract-view/${tab}`,
);

type Props = GetRouteProps<typeof ContractsViewRoute>;

const ContractsView = ({
  match: {
    query,
    query: { search, type },
    params: { tab },
  },
  link,
  history: { push },
}: Props) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { hasAccess } = usePermissions();
  const isActiveProjectsAccess = hasAccess(ActionsType.ViewActiveProjects);
  const isArchiveProjectsAccess = hasAccess(ActionsType.ViewActiveProjects);
  const { isMobileDevice } = useDeviceTypeByWidth();

  const [searchTerm, setSearchTerm] = useState(search);
  const [groupBy, setGroupBy] = useState<GroupByFilter | undefined>(
    (localStorageManager.getItem(CONTRACTS_GROUP_BY) as GroupByFilter) || undefined,
  );

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  const queryOptions = {
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    skip:
      (!isActiveProjectsAccess && tab !== ProjectsTabs.active) ||
      (!isArchiveProjectsAccess && tab === ProjectsTabs.archived),
    fetchPolicy: 'cache-and-network' as FetchPolicy,
    nextFetchPolicy: 'cache-first' as FetchPolicy,
  };

  const { data: { contracts = [] } = {}, loading } = useContractsQuery({
    ...queryOptions,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ProjectsTabs.archived,
      withCommission: false,
      filterData: {
        type: (type as ProjectType[]) || null,
      },
    },
  });

  const { data: { contractsListData = [] } = {} } = useContractsListDataQuery({
    ...queryOptions,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ProjectsTabs.archived,
      filterData: {
        type: (type as ProjectType[]) || null,
      },
    },
    skip: queryOptions.skip || !contracts.length,
  });

  const { data: { contractsMargins = [] } = {} } = useContractsMarginsQuery({
    ...queryOptions,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ProjectsTabs.archived,
    },
    skip: queryOptions.skip || !hasAccess(ActionsType.ViewProjectCosts) || !contracts.length,
  });

  const contractsMarginsData = useMemo(
    () => contractsMargins.reduce<ContractsMarginsData>((acc, item) => ({ ...acc, [item.contractId]: item }), {}),
    [contractsMargins],
  );
  const contractsListDetailsData = useMemo(
    () => contractsListData.reduce<ContractsDetailsData>((acc, item) => ({ ...acc, [item.contractId]: item }), {}),
    [contractsListData],
  );

  const filteredContracts = useMemo(() => {
    if (!contracts) return [];

    return contracts
      .filter((item) => searchFilter(search || '', item.name))
      .sort((a, b) => a.name.localeCompare(b.name, 'en'))
      .map((item) => ({
        ...item,
        margin: contractsMarginsData[item.id]?.margin,
        forecastMargin: contractsMarginsData[item.id]?.forecastMargin,
        activeAssignments: contractsListDetailsData[item.id]?.activeAssignments,
        currentCommission: contractsListDetailsData[item.id]?.currentCommission,
      }));
  }, [contracts, contractsListDetailsData, contractsMarginsData, search]);

  const onChangeFilters = (data: ChangeFiltersData) => {
    push(link({ tab, ...query, ...data }));
  };

  const changeQueryParams = useCallback(
    debounce((search?: string) => {
      push(link({ tab, ...query, search: search || undefined }));
    }, 200),
    [query, search, tab],
  );

  const groupByOptions = [
    GroupByFilter.none,
    GroupByFilter.type,
    GroupByFilter.project,
    GroupByFilter.projectManager,
  ].map((id) => ({
    id,
    name: t(`projects.filters.groupBy.${id}`),
  }));

  const onSearchChange = useCallback(
    (value?: string) => {
      setSearchTerm(value);
      changeQueryParams(value);
    },
    [changeQueryParams],
  );

  const handleRestoreContract = useRestoreContract();
  const getArchiveContractAction = useCallback(
    ({ id, projectId }) => (
      <ConfirmIconButton
        tooltipText={t('actions.restore')}
        onClick={() => handleRestoreContract(id, projectId)}
        confirmTitle={t('contracts.restoreContract')}
        confirmMessage={t('contracts.confirmRestoreContract')}
        confirmSubmitButtonTitle={t('contracts.restoreContract')}
      >
        <ArchiveIcon />
      </ConfirmIconButton>
    ),
    [],
  );
  const getActiveContractAction = useCallback(
    ({ id, projectId, isActiveAssignment }) => (
      <ArchiveContract id={id} projectId={projectId} isActiveAssignment={isActiveAssignment} />
    ),
    [],
  );

  const countLabel = loading
    ? ''
    : `${filteredContracts.length} ${
        filteredContracts.length === 1 ? t('contracts.contract') : t('contracts.contracts')
      }`;

  return (
    <>
      <TableCountHeader countLabel={countLabel}>
        {!isMobileDevice && <Search onChange={onSearchChange} value={searchTerm} className="mr-12" />}
        <FilterRadioPopover
          title={t('projects.groupBy')}
          showPrefix
          value={groupBy}
          noSelectOptionStyle
          options={groupByOptions}
          onChange={(value) => {
            mixpanel.track('Grouping updated', {
              'Screen name': ModuleName.contracts,
              Grouping: value || GroupByFilter.none,
            });
            localStorageManager.setItem(CONTRACTS_GROUP_BY, value);
            setGroupBy(value);
          }}
        />

        {isMobileDevice ? (
          <CommonFilters
            onChange={onChangeFilters}
            filterButtonClassName={styles.filterButton}
            filtersOptions={[CommonFilterOptions.type]}
            type={type as string[]}
          />
        ) : (
          <CommonFiltersSection
            onChange={onChangeFilters}
            filtersOptions={[CommonFilterOptions.type]}
            type={type as string[]}
          />
        )}
      </TableCountHeader>

      {isMobileDevice && <MobileSearch onChange={onSearchChange} value={searchTerm} className="px-8 pb-12" />}

      <div className="layout-content-wrapper">
        <ContractsTable
          loading={loading}
          contracts={filteredContracts}
          groupBy={groupBy}
          getTableActions={tab === ProjectsTabs.active ? getActiveContractAction : getArchiveContractAction}
        />
      </div>
    </>
  );
};

export default ContractsViewRoute(ContractsView);
