import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { debounce } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';
import {
  CommonFilterOptions,
  GetRouteProps,
  GroupByFilter,
  ModuleName,
  ProjectModals,
  ProjectsTabs,
  ScreenName,
} from 'types';
import {
  ChangeFiltersData,
  CommonFilters,
  CommonFiltersSection,
  ConfirmIconButton,
  FilterRadioPopover,
  MobileSearch,
  Search,
  TableCountHeader,
} from 'components';
import {
  useArchiveProject,
  useDeviceTypeByWidth,
  useErrorMsgBuilder,
  usePermissions,
  useRestoreProject,
  useTrackScreenView,
} from 'hooks';
import { ActionsType } from 'generated/types';
import { useManagedProjectsQuery } from 'generated/graphql';
import { graphqlOnError, searchFilter } from 'utils';
import { useAuth } from 'contexts';
import { localStorageManager } from 'services';
import { PROJECTS_GROUP_BY } from 'consts';
import { ApolloError } from '@apollo/client/errors';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { ProjectsTable } from 'views/Projects/components/ProjectsTable';
import { ArchiveIcon } from 'icons';

import styles from './styles.module.scss';

const ProjectsViewRoute = Route(
  {
    tab: Route.params.oneOf(...Object.values(ProjectsTabs)),
    search: Route.query.string,
    client: Route.query.array(Route.query.string),
    pm: Route.query.array(Route.query.string),
    modal: Route.query.oneOf(...Object.values(ProjectModals)),
  },
  ({ tab }) => `/projects/projects-view/${tab}`,
);

type Props = GetRouteProps<typeof ProjectsViewRoute>;

const ProjectsView = ({
  match: {
    query,
    query: { search, client, pm },
    params: { tab },
  },
  link,
  history: { push },
}: Props) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  useTrackScreenView(ModuleName.projects, ScreenName.list, { additionalProperties: { View: ModuleName.projects } });

  const { hasAccess } = usePermissions();
  const isActiveProjectsAccess = hasAccess(ActionsType.ViewActiveProjects);
  const isArchiveProjectsAccess = hasAccess(ActionsType.ViewActiveProjects);
  const { isMobileDevice } = useDeviceTypeByWidth();

  const [searchTerm, setSearchTerm] = useState(search);
  const [groupBy, setGroupBy] = useState<GroupByFilter | undefined>(
    (localStorageManager.getItem(PROJECTS_GROUP_BY) as GroupByFilter) || undefined,
  );

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  const queryOptions = {
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    skip:
      (!isActiveProjectsAccess && tab !== ProjectsTabs.active) ||
      (!isArchiveProjectsAccess && tab === ProjectsTabs.archived),
    fetchPolicy: 'cache-and-network' as FetchPolicy,
    nextFetchPolicy: 'cache-first' as FetchPolicy,
  };

  const { data: { managedProjects = [] } = {}, loading } = useManagedProjectsQuery({
    ...queryOptions,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ProjectsTabs.archived,
      filterData: {
        client: (client as string[]) || null,
        pm: (pm as string[]) || null,
      },
    },
  });

  const filteredProjects = useMemo(() => {
    if (!managedProjects) return [];

    return managedProjects
      .filter((item) => searchFilter(search || '', item.name, item?.client?.name ? item.client.name : null))
      .sort((a, b) => {
        if (a?.client && b?.client) return a.client.name.localeCompare(b.client.name);
        if (b?.client) return 1;
        return -1;
      });
  }, [managedProjects, search]);

  const onChangeFilters = (data: ChangeFiltersData) => {
    push(link({ tab, ...query, ...data }));
  };

  const changeQueryParams = useCallback(
    debounce((search?: string) => {
      push(link({ tab, ...query, search: search || undefined }));
    }, 200),
    [query, search, tab],
  );

  const groupByOptions = [GroupByFilter.none, GroupByFilter.projectManager, GroupByFilter.client].map((id) => ({
    id,
    name: t(`projects.filters.groupBy.${id}`),
  }));

  const onSearchChange = useCallback(
    (value?: string) => {
      setSearchTerm(value);
      changeQueryParams(value);
    },
    [changeQueryParams],
  );

  const handleRestoreProject = useRestoreProject();
  const getArchiveProjectAction = useCallback(
    (id: string) => (
      <ConfirmIconButton
        tooltipText={t('actions.restore')}
        onClick={() => handleRestoreProject(id)}
        confirmTitle={t('projects.restoreProject')}
        confirmMessage={t('projects.confirmRestoreProject')}
        confirmSubmitButtonTitle={t('projects.restoreProject')}
      >
        <ArchiveIcon />
      </ConfirmIconButton>
    ),
    [],
  );
  const handleArchiveProject = useArchiveProject();
  const getActiveProjectAction = useCallback(
    (id: string) => (
      <ConfirmIconButton
        tooltipText={t('actions.archive')}
        onClick={() => handleArchiveProject(id)}
        confirmTitle={t('projects.archiveProject')}
        confirmMessage={t('projects.archiveConfirmation')}
        confirmSubmitButtonTitle={t('projects.archiveProject')}
      >
        <ArchiveIcon />
      </ConfirmIconButton>
    ),
    [],
  );

  const countLabel = loading
    ? ''
    : `${filteredProjects.length} ${filteredProjects.length === 1 ? t('projects.project') : t('projects.projects')}`;

  return (
    <>
      <TableCountHeader countLabel={countLabel}>
        {!isMobileDevice && <Search onChange={onSearchChange} value={searchTerm} className="mr-12" />}
        <FilterRadioPopover
          title={t('projects.groupBy')}
          showPrefix
          value={groupBy}
          noSelectOptionStyle
          options={groupByOptions}
          onChange={(value) => {
            mixpanel.track('Grouping updated', {
              'Screen name': ModuleName.projects,
              Grouping: value || GroupByFilter.none,
            });
            localStorageManager.setItem(PROJECTS_GROUP_BY, value);
            setGroupBy(value);
          }}
        />

        {isMobileDevice ? (
          <CommonFilters
            onChange={onChangeFilters}
            filterButtonClassName={styles.filterButton}
            filtersOptions={[CommonFilterOptions.client, CommonFilterOptions.pm]}
            client={client as string[]}
            pm={pm as string[]}
          />
        ) : (
          <CommonFiltersSection
            onChange={onChangeFilters}
            filtersOptions={[CommonFilterOptions.client, CommonFilterOptions.pm]}
            client={client as string[]}
            pm={pm as string[]}
          />
        )}
      </TableCountHeader>

      {isMobileDevice && <MobileSearch onChange={onSearchChange} value={searchTerm} className="px-8 pb-12" />}

      <div className="layout-content-wrapper">
        <ProjectsTable
          loading={loading}
          projects={filteredProjects}
          groupBy={groupBy}
          getTableActions={tab === ProjectsTabs.active ? getActiveProjectAction : getArchiveProjectAction}
        />
      </div>
    </>
  );
};

export default ProjectsViewRoute(ProjectsView);
