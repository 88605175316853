import React, { FC } from 'react';
import { Link, Tooltip } from 'components';
import clsx from 'clsx';
import { links } from 'App';
import styles from './styles.module.scss';

interface Props {
  data: {
    contractId?: string;
    id: string;
    name: string;
    color: string;
    contractName?: string;
    role?: { name: string };
    seniority?: string;
  };
  className?: string;
  textClassName?: string;
  subtitleClassName?: string;
  withProjectLink?: boolean;
}

export const AssignmentInfo: FC<Props> = ({
  data: { contractId, id, name, role, seniority, color, contractName },
  className,
  textClassName,
  subtitleClassName,
  withProjectLink,
}) => (
  <div className={clsx(styles.container, className)}>
    <span className={styles.projectColor} style={{ backgroundColor: color }} />

    <div>
      {withProjectLink ? (
        <Link to={links.ProjectDetail({ id, contractId })}>
          <Tooltip title={name} placement="top" textClassName={clsx(styles.project, textClassName)}>
            {name}
          </Tooltip>
        </Link>
      ) : (
        <Tooltip title={name} placement="top" textClassName={clsx(styles.project, textClassName)}>
          {name}
        </Tooltip>
      )}

      {role && (
        <Tooltip
          title={seniority ? `${role.name}, ${seniority}` : role.name}
          placement="top"
          className={clsx(styles.subtitle, subtitleClassName)}
        >
          {seniority ? `${role.name}, ${seniority}` : role.name}
        </Tooltip>
      )}
      {contractName && (
        <Tooltip title={contractName} placement="top" className={clsx(styles.subtitle, subtitleClassName)}>
          {contractName}
        </Tooltip>
      )}
    </div>
  </div>
);
