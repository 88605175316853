import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { QueryOptions } from '@apollo/client';

import { links } from 'App';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { ContractFormValues, CustomToastWithLink } from 'components';
import { graphqlOnError } from 'utils';
import { ContractDocument, ContractsOptionsDocument, useCreateContractMutation } from 'generated/graphql';
import { CreateContractMutation } from 'generated/types';
import { getContractChangeData } from 'views/ProjectDetail/components/ContractSection/getContractChangeData';
import { getContractName } from 'views/ProjectDetail/components/ContractSection/getContractName';
import mixpanel from 'mixpanel-browser';
import { ProjectTrackType } from 'types';

interface Props {
  onClose?: () => void;
  refetchAction?: Array<string | DocumentNode | QueryOptions>;
  onCompleted?: (data: CreateContractMutation) => void;
}

export const useCreateContract = ({
  onClose,
  onCompleted,
  refetchAction,
}: Props): ((values: ContractFormValues) => Promise<void>) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [createContract] = useCreateContractMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted({ createContract }) {
      if (onCompleted) {
        onCompleted({ createContract });
        return;
      }

      const contractLink = links.ProjectDetail({ id: createContract.projectId, contractId: createContract.id });
      const contractName = createContract.name;
      const toastMessage = t('contracts.contractCreateSuccessfully');
      toast.success(CustomToastWithLink(contractLink, contractName, toastMessage));

      mixpanel.track('Contract created', {
        Type: ProjectTrackType[createContract.type],
        Unit: createContract.unit,
        'Budget set': !!createContract.cost_budget_amount,
        'Commission set': !!createContract.currentCommission,
      });
      onClose?.();
    },
    update(cache, { data }) {
      if (!data?.createContract) return;
      const createdContract = data?.createContract;

      cache.updateQuery(
        {
          query: ContractsOptionsDocument,
          variables: {
            companyId: userData!.company.id,
            filterData: { projectId: createdContract?.projectId },
            withProject: false,
            withDates: false,
            withClient: false,
          },
          overwrite: true,
        },
        (data) => (data?.contracts ? { contracts: [...data?.contracts, createdContract] } : null),
      );
      cache.updateQuery(
        {
          query: ContractsOptionsDocument,
          variables: {
            companyId: userData!.company.id,
            filterData: { projectId: createdContract?.projectId, isActive: true },
            withProject: false,
            withDates: false,
            withClient: false,
          },
          overwrite: true,
        },
        (data) => (data?.contracts ? { contracts: [...data?.contracts, createdContract] } : null),
      );
    },
  });

  return async (values: ContractFormValues) => {
    await createContract({
      variables: {
        companyId: userData!.company.id,
        projectId: values.project?.id || '',
        data: getContractChangeData({ ...values, name: getContractName(values, t) }),
      },
      refetchQueries: [
        ...(refetchAction || []),
        {
          query: ContractDocument,
          variables: { companyId: userData!.company.id, projectId: values.project?.id || '', withCommission: true },
        },
      ],
    });
  };
};
