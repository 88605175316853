import React, { FC } from 'react';
import clsx from 'clsx';
import { ActionsType, ContractDataFragment, ProjectDataFragment, ProjectType } from 'generated/types';
import { ConfirmModal, DropdownMenu } from 'components';
import { IconButton, MenuItem } from '@material-ui/core';
import { DrawerQueries } from 'types';
import {
  ActivityLog as ActivityLogIcon,
  ArchiveIcon,
  Document,
  EditIcon,
  InsightsLink,
  LeavesBalance as LeavesBalanceIcon,
  MenuIcon,
  ShareIcon,
} from 'icons';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import {
  useArchiveProject,
  useDeviceTypeByWidth,
  useIsOpen,
  usePermissions,
  useRestoreProject,
  useScreenWidth,
} from 'hooks';
import { SharedAccessSetting } from 'views/ProjectDetail/SharedAccessSetting';

import styles from './styles.module.scss';

const MIN_SCREEN_BUTTON_FULL_VIEW_WIDTH = 1360;
const RATE_CARD_BUTTON_WIDTH = 117;
const SHARE_PROJECT_BUTTON_WIDTH = 117;

interface Props {
  projectId: string;
  contract?: ContractDataFragment | null;
  project: ProjectDataFragment;
  handleDialogOpen: (
    mode:
      | DrawerQueries.rateCardMode
      | DrawerQueries.activityHistoryMode
      | DrawerQueries.leavesBalanceMode
      | DrawerQueries.editMode
      | DrawerQueries.shareProjectsMode,
  ) => void;
  onInsightsRedirect: () => void;
}

export const HeaderButtons: FC<Props> = ({ projectId, project, contract, handleDialogOpen, onInsightsRedirect }) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions({ projectId });
  const { isMobileDevice } = useDeviceTypeByWidth();
  const screenWidth = useScreenWidth();
  const [isOpenArchiveConfirm, onOpenArchiveConfirm, onCloseArchiveConfirm] = useIsOpen();
  const [isOpenRestoreConfirm, onOpenRestoreConfirm, onCloseRestoreConfirm] = useIsOpen();

  const handleRestoreProject = useRestoreProject(projectId);
  const handleArchiveProject = useArchiveProject(projectId);

  const isShowInsightButton = contract && !contract?.archived_at && !isMobileDevice;
  const isShowActiveProjectEditButton = !project.archived_at && hasAccess(ActionsType.EditActiveProjects);
  const isShowArchivedProjectEditButton = !!project.archived_at && hasAccess(ActionsType.EditArchivedProjects);
  const isShowActivityHistoryButton = hasAccess(ActionsType.ActivityHistory);
  const isShowShareProjectButton = hasAccess(ActionsType.ShareProject);
  const isShowArchiveProjectsButton = !project.archived_at && hasAccess(ActionsType.ArchiveProjects);
  const isShowRestoreProjectsButton = !!project.archived_at && hasAccess(ActionsType.RestoreProjects);
  const isShowLeaveBalanceButton = contract?.billableLeaves && !isMobileDevice;
  const isShowRateCardButton =
    hasAccess(ActionsType.ViewProjectRatecards) &&
    (contract?.type === ProjectType.TimeAndMaterial ||
      contract?.type === ProjectType.Retainer ||
      contract?.type === ProjectType.FixedPrice) &&
    !isMobileDevice;

  const minScreenWidth =
    MIN_SCREEN_BUTTON_FULL_VIEW_WIDTH +
    (isShowRateCardButton ? RATE_CARD_BUTTON_WIDTH : 0) +
    (isShowShareProjectButton ? SHARE_PROJECT_BUTTON_WIDTH : 0);

  const isSlimVersion = minScreenWidth > screenWidth;

  return (
    <>
      <div className={styles.headerButtonContainer}>
        <SharedAccessSetting
          projectId={projectId}
          onOpenAccessMenu={() => handleDialogOpen(DrawerQueries.shareProjectsMode)}
        />

        <DropdownMenu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          trigger={(handleClick, open) => (
            <IconButton
              className={clsx(styles.menuButton, open && styles.menuButtonOpened)}
              size="small"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          )}
        >
          {(handleClose) => [
            isSlimVersion && isShowShareProjectButton && (
              <MenuItem
                key="0"
                onClick={() => {
                  handleDialogOpen(DrawerQueries.shareProjectsMode);
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <ShareIcon className={styles.menuIcon} /> {t('shareProject.title')}
              </MenuItem>
            ),
            isSlimVersion && isShowRateCardButton && (
              <MenuItem
                key="1"
                onClick={() => {
                  handleDialogOpen(DrawerQueries.rateCardMode);
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <Document className={styles.menuIcon} /> {t('rateCard.title')}
              </MenuItem>
            ),
            isShowInsightButton && (
              <MenuItem
                key="2"
                onClick={() => {
                  onInsightsRedirect();
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <InsightsLink className={clsx(styles.menuIcon, styles.insightsIcon)} /> {t('insights.label')}
              </MenuItem>
            ),
            isShowLeaveBalanceButton && (
              <MenuItem
                key="3"
                onClick={() => {
                  handleDialogOpen(DrawerQueries.leavesBalanceMode);
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <LeavesBalanceIcon className={styles.menuIcon} /> {t('viewProjectDetail.leavesBalance.label')}
              </MenuItem>
            ),
            isShowActivityHistoryButton && (
              <MenuItem
                key="4"
                onClick={() => {
                  handleDialogOpen(DrawerQueries.activityHistoryMode);
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <ActivityLogIcon className={styles.menuIcon} /> {t('activityHistory.title')}
              </MenuItem>
            ),
            isShowArchiveProjectsButton && (
              <MenuItem
                key="5"
                onClick={() => {
                  onOpenArchiveConfirm();
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <ArchiveIcon className={styles.menuIcon} /> {t('projects.archiveProject')}
              </MenuItem>
            ),
            isShowRestoreProjectsButton && (
              <MenuItem
                key="6"
                onClick={() => {
                  onOpenRestoreConfirm();
                  handleClose();
                }}
                className={styles.menuItem}
              >
                <ArchiveIcon className={styles.menuIcon} /> {t('projects.restoreProject')}
              </MenuItem>
            ),
          ]}
        </DropdownMenu>

        {isShowRateCardButton && !isSlimVersion && (
          <Button
            variant="outlined"
            color="secondary"
            className={clsx(styles.headerButton, styles.rateCardButton)}
            startIcon={<Document className={styles.icon} />}
            onClick={() => handleDialogOpen(DrawerQueries.rateCardMode)}
          >
            {t('rateCard.title')}
          </Button>
        )}

        {(isShowActiveProjectEditButton || isShowArchivedProjectEditButton) && (
          <Button
            variant="outlined"
            color="secondary"
            className={styles.headerButton}
            startIcon={<EditIcon className={styles.icon} />}
            onClick={() => handleDialogOpen(DrawerQueries.editMode)}
          >
            {t('viewProjectDetail.edit')}
          </Button>
        )}

        {isShowShareProjectButton && !isSlimVersion && (
          <Button startIcon={<ShareIcon />} onClick={() => handleDialogOpen(DrawerQueries.shareProjectsMode)}>
            {t('shareProject.share')}
          </Button>
        )}
      </div>

      <ConfirmModal
        title={t('projects.archiveProject')}
        submitButtonTitle={t('projects.archiveProject')}
        isOpen={isOpenArchiveConfirm}
        onSubmit={() => {
          handleArchiveProject(projectId);
          onCloseArchiveConfirm();
        }}
        onClose={onCloseArchiveConfirm}
      >
        {t('projects.archiveConfirmation')}
      </ConfirmModal>

      <ConfirmModal
        title={t('projects.restoreProject')}
        submitButtonTitle={t('projects.restoreProject')}
        isOpen={isOpenRestoreConfirm}
        onSubmit={() => {
          handleRestoreProject(projectId);
          onCloseRestoreConfirm();
        }}
        onClose={onCloseRestoreConfirm}
      >
        {t('projects.confirmRestoreProject')}
      </ConfirmModal>
    </>
  );
};
