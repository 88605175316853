import React, { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Badge, Colors, Tooltip } from 'components';
import clsx from 'clsx';
import { ContractDataFragment } from 'generated/types';
import { getCurrencySymbol } from 'utils';

interface Props {
  contract: ContractDataFragment;
  clientName?: string;
}

export const ProjectHeader: FC<Props> = ({ contract, clientName }) => {
  const { t } = useTranslation();

  return (
    <div className="flex align-items-center">
      <span className={styles.projectColor} style={{ backgroundColor: contract?.projectColor || '' }} />
      <Tooltip
        title={contract?.projectName || ''}
        placement="bottom"
        textClassName={styles.projectName}
        alwaysShowTooltip
      >
        {contract?.projectName}
      </Tooltip>
      <span className={styles.divider} />
      <Tooltip title={clientName || ''} placement="bottom" textClassName={styles.clientName} alwaysShowTooltip>
        {clientName}
      </Tooltip>
      <span className={clsx(styles.clientName, 'mr-12')}>
        {`${clientName ? ', ' : ''}${getCurrencySymbol(contract?.fee_currency)}`}
      </span>
      {contract?.type && (
        <div className="mr-12 flex align-item-center">
          <Badge color={Colors.navy}>{t(`projectType.${contract.type}`)}</Badge>
        </div>
      )}
    </div>
  );
};
