import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';

export const ScenariosRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    contractId: Route.params.regex(idRegEx).optional,
  },
  ({ id, contractId }) => `/project/view/${id}/${contractId}/project-scenario`,
);

export const Scenarios = ScenariosRoute(lazy(() => import(/* webpackChunkName: "Scenarios" */ './Scenarios')));
