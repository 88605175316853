import React, { FC, useCallback, useMemo } from 'react';
import { fromUnixTime, getUnixTime, startOfWeek } from 'date-fns';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { client } from 'graphql-client';
import { GetRouteProps } from 'types/utils/router';
import { useSetAppTitle } from 'hooks/useSetAppTitle';
import {
  CommonActions,
  CreateAssignment,
  CreateAssignmentRequest,
  CreateEditAssignmentRequest,
  EditAssignment,
  EditAssignmentRequest,
  IntegrationModal,
  NewLeave,
  RequestTooltip,
  RightDrawer,
  TabItem,
  Tabs,
  ViewHeading,
} from 'components';
import { usePermissions } from 'hooks';
import { ResourcePlanningMembersDocument, ResourcePlanningProjectsDocument } from 'generated/graphql';

import type { ResourcePlanningRoute } from './index';
import { Timeline } from './components/Timeline';

import styles from './styles.module.scss';
import { ActionsType, ImportType } from 'generated/types';
import { ModalModeEnum } from 'types';
import { Button } from '@material-ui/core';
import { UploadFile } from 'icons';

type Props = GetRouteProps<typeof ResourcePlanningRoute>;

const ResourcePlaning: FC<Props> = ({
  history: { push },
  match: {
    query: { date = getUnixTime(startOfWeek(new Date(), { weekStartsOn: 1 })), memberId, projectId },
    query,
    params: { type, mode, id },
    params,
  },
  link,
}) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  useSetAppTitle(t('navigation.resourcePlanning'));
  const currentDate = useMemo(() => fromUnixTime(date), [date]);
  const changeDate = useCallback((date: Date) => push(link({ ...query, ...params, date: getUnixTime(date), type })), [
    type,
    query,
    params,
  ]);

  const onClose = () =>
    push(link({ ...query, ...params, mode: undefined, id: undefined, memberId: undefined, projectId: undefined }));

  const onAssignmentUpdate = useCallback(() => {
    client.refetchQueries({
      include: [ResourcePlanningMembersDocument, ResourcePlanningProjectsDocument],
    });
  }, []);
  return (
    <section className={styles.resourcePlanning}>
      <ViewHeading
        hasSmartBackBtn={false}
        label={t('resourcePlanning.label')}
        className={styles.viewHeading}
        navigation={
          <div className={clsx(styles.tabs)}>
            <Tabs>
              {hasAccess(ActionsType.ViewResourcePlanningMembers) && (
                <TabItem exact={type === 'project'} route={link({ date })}>
                  {t('resourcePlanning.team')}
                </TabItem>
              )}
              {hasAccess(ActionsType.ViewResourcePlanningProjects) && (
                <TabItem exact={type !== 'project'} route={link({ type: 'project', date })}>
                  {t('resourcePlanning.projects')}
                </TabItem>
              )}
            </Tabs>
          </div>
        }
      >
        <section className="flex gap-8">
          {hasAccess(ActionsType.CreateAssignments) && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<UploadFile />}
              onClick={() => push(link({ ...query, ...params, mode: ModalModeEnum.assigmentIntegration }))}
            >
              {t('resourcePlanning.importAssignments')}
            </Button>
          )}
          <CommonActions
            menuItemsOrder={['assignment', 'requestAssignment', 'leave', 'teamMember', 'client', 'project', 'contract']}
          />
        </section>
      </ViewHeading>

      <Timeline type={type ?? 'team'} onChange={changeDate} date={currentDate} />

      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.manage && !!id}
        onClose={onClose}
        title={t(`resourcePlanning.editAssignment`)}
      >
        <EditAssignment
          onDelete={onAssignmentUpdate}
          onUpdate={onAssignmentUpdate}
          memberDisabled
          projectDisabled
          id={id!}
          onCancel={onClose}
        />
      </RightDrawer>
      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.request && !!id}
        onClose={onClose}
        title={
          <div className="flex">
            {t('viewProjectDetail.assignments.editAssignment')} <RequestTooltip item={t('request.items.assignment')} />
          </div>
        }
      >
        <CreateEditAssignmentRequest id={id!} memberDisabled projectDisabled onCancel={onClose} />
      </RightDrawer>
      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.manage && !id}
        onClose={onClose}
        title={t(`resourcePlanning.addNewAssignment`)}
      >
        <CreateAssignment
          onCreate={onAssignmentUpdate}
          memberId={memberId}
          projectId={projectId}
          memberDisabled={!!memberId}
          projectDisabled={!!projectId}
          onCancel={onClose}
        />
      </RightDrawer>
      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.request && !id}
        onClose={onClose}
        title={
          <div className="flex">
            {t('resourcePlanning.create.assignment')} <RequestTooltip item={t('request.items.assignment')} />
          </div>
        }
      >
        <CreateAssignmentRequest
          onCreate={onAssignmentUpdate}
          memberId={memberId}
          projectId={projectId}
          memberDisabled={!!memberId}
          projectDisabled={!!projectId}
          onCancel={onClose}
        />
      </RightDrawer>
      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.requestEdit}
        onClose={onClose}
        title={<div className="flex">{t('resourcePlanning.edit.request')}</div>}
      >
        <EditAssignmentRequest id={id!} memberDisabled projectDisabled onCancel={onClose} />
      </RightDrawer>
      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.leave}
        onClose={onClose}
        title={t(`resourcePlanning.${memberId ? 'create.leave' : 'editLeave'}`)}
      >
        <NewLeave
          memberDisabled={!!id}
          submitLabel={t('actions.save')}
          id={id}
          memberId={memberId}
          onCancel={onClose}
        />
      </RightDrawer>
      <IntegrationModal
        title={t('resourcePlanning.importAssignments')}
        importType={ImportType.Assignment}
        isOpen={hasAccess(ActionsType.CreateAssignments) && mode === ModalModeEnum.assigmentIntegration}
        onClose={onClose}
      />
    </section>
  );
};

export default ResourcePlaning;
