import { useAuth } from 'contexts';
import { useActorPermissionsQuery } from 'generated/graphql';
import { useState } from 'react';
import { ActionsType } from 'generated/types';

interface Props {
  projectId?: string;
}

export function usePermissions({ projectId }: Props = {}) {
  const { userData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const { data: { actorPermissions = [] } = {}, loading } = useActorPermissionsQuery({
    onError() {
      setIsLoading(false);
    },
    onCompleted() {
      setIsLoading(false);
    },
    variables: {
      companyId: userData!.company.id,
      projectId: projectId,
    },
  });

  const actions = new Set(actorPermissions.map(({ action }) => action));

  const hasAccess = (action: ActionsType) => {
    if (!userData || !actions.size) return false;

    return actions.has(action);
  };

  return { hasAccess, isPermissionsLoading: isLoading, isPermissionsUpdatingLoading: loading };
}
