import React, { ChangeEvent, FC } from 'react';
import { Select } from 'components';

interface Props {
  value: string;
  onChange: (value: string) => void;
  exchangeRate?: number | null;
  exchangeRateCurrency?: string | null;
  contractCurrency?: string | null;
  showWhenLoading?: boolean;
}

const DEFAULT_SELECT_VALUE = 'default';

export const CurrencySelect: FC<Props> = ({
  value,
  onChange,
  exchangeRate,
  exchangeRateCurrency,
  contractCurrency,
  showWhenLoading,
}) => {
  const currencyOptions =
    contractCurrency && exchangeRateCurrency
      ? [
          {
            name: contractCurrency.toUpperCase(),
            id: contractCurrency.toUpperCase(),
          },
          {
            additionalText: `=${exchangeRate} ${contractCurrency.toUpperCase()}`,
            name: exchangeRateCurrency.toUpperCase(),
            id: exchangeRateCurrency.toUpperCase(),
          },
        ]
      : [];
  const showSelect = showWhenLoading ? exchangeRateCurrency || value : exchangeRateCurrency;

  return (
    <>
      {showSelect ? (
        <Select
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
          SelectProps={{
            renderValue: (value) =>
              value !== DEFAULT_SELECT_VALUE ? `${value}` : (contractCurrency || '').toUpperCase(),
          }}
          defaultText={(contractCurrency || '').toUpperCase()}
          options={currencyOptions}
        />
      ) : (
        ''
      )}
    </>
  );
};
