import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { QueryOptions } from '@apollo/client';

import { links } from 'App';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { CustomToastWithLink, ProjectFormValues } from 'components';
import { addTimezoneOffset, graphqlOnError } from 'utils';
import { useCreateProjectMutation } from 'generated/graphql';
import mixpanel from 'mixpanel-browser';
import { ManagerTrackAccess } from 'types';

export const useCreateProject = (
  onClose: () => void,
  refetchAction?: Array<string | DocumentNode | QueryOptions>,
): (({ pm, client, ...values }: ProjectFormValues) => Promise<void>) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [createProject] = useCreateProjectMutation({
    async onCompleted(data) {
      const projectLink = links.ProjectDetail({ id: data.createProject.id });
      const projectName = data.createProject.name;
      const toastMessage = t('projects.projectCreateSuccessfully');
      toast.success(CustomToastWithLink(projectLink, projectName, toastMessage));
      mixpanel.track('Project created', {
        'Manager access': ManagerTrackAccess[data.createProject.pmFinAccess!.accessLevel],
      });
      onClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: refetchAction,
  });

  return async ({ pm, client, pmFinAccess, pmFinAccessStart, pmFinAccessEnd, ...values }: ProjectFormValues) => {
    await createProject({
      variables: {
        data: {
          ...values,
          client: client?.id ?? '',
          pm: pm?.id ?? '',
          pmFinAccess: pmFinAccess.id,
          pmAccessStart: pmFinAccessStart ? addTimezoneOffset(new Date(pmFinAccessStart)) : undefined,
          pmAccessEnd: pmFinAccessEnd ? addTimezoneOffset(new Date(pmFinAccessEnd)) : undefined,
        },
        companyId: userData!.company.id,
      },
    });
  };
};
