import React, { ChangeEvent, FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  AbsoluteSpinner,
  AssignmentRate,
  Autocomplete,
  CreatableAutocomplete,
  DatePicker,
  LoadingButton,
  NumberTextField,
} from 'components';
import {
  useContractsOptionsQuery,
  useProjectsOptionsQuery,
  useTeamMembersForAssignmentsQuery,
} from 'generated/graphql';
import { getFullName, graphqlOnError, sortByField, submitForm } from 'utils';
import { useAuth } from 'contexts';
import { useCreatableProjectRoleData, useCreatableSeniorityData, useErrorMsgBuilder, usePermissions } from 'hooks';
import {
  ActionsType,
  Assignment,
  AssignmentCalculationType,
  Contract,
  Member,
  MemberAssignmentDataFragment,
  MemberSeniority,
  Project,
  ProjectType,
  Role,
} from 'generated/types';
import { ASC, FIRST_NAME, NAME, PROJECT } from 'consts';
import { CheckboxIcon, DashIcon, LockIcon } from 'icons';
import { isAfter } from 'date-fns';

import styles from './AssignmentForm.module.scss';

type BillingTypeOption = { id: AssignmentCalculationType; name: string };

export interface AssignmentFormValues {
  member: MemberAssignmentDataFragment | null;
  role: Pick<Role, 'id' | 'name'> | null;
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  project?: Pick<Project, 'id' | 'name' | 'color'> | null;
  contract?: Pick<Contract, 'type' | 'id' | 'name' | 'unit' | 'fee_currency' | 'projectId'> | null;
  allocationTimeAmount: number | string;
  billingType: BillingTypeOption | null;
  startDate: string | Date;
  endDate: string | Date;
  isMemberWithCostRates?: string;
  billable: boolean;
}

interface NewAssignmentProps {
  id?: string;
  initialValue?: AssignmentFormValues;
  memberId?: string;
  projectId?: string;
  contractId?: string;
  onSubmit: (values: AssignmentFormValues) => void;
  onCancel: () => void;
  memberDisabled?: boolean;
  projectDisabled?: boolean;
  contractDisabled?: boolean;
  submitLabel?: string;
  additionalButton?: ReactNode;
  additionalControlsContent?: ReactNode;
  showRequestDescription?: boolean;
  submitDisabled?: boolean;
}

const INITIAL_ALLOCATION_VALUE = 8.0;
export const BillingTypes: BillingTypeOption[] = [
  { id: AssignmentCalculationType.Allocation, name: 'forms.newAssignment.billingType.allocatedTime' },
  { id: AssignmentCalculationType.TimeLogs, name: 'forms.newAssignment.billingType.loggedTime' },
];

export const AssignmentForm: FC<NewAssignmentProps> = ({
  id,
  initialValue,
  memberId,
  projectId,
  contractId,
  onSubmit,
  onCancel,
  memberDisabled,
  projectDisabled,
  submitLabel,
  additionalButton,
  additionalControlsContent,
  showRequestDescription,
  submitDisabled,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions();

  const allocationBillingType = BillingTypes.find(
    (item) => item.id === AssignmentCalculationType.Allocation,
  ) as BillingTypeOption;
  const timeLogsBillingType = BillingTypes.find(
    (item) => item.id === AssignmentCalculationType.TimeLogs,
  ) as BillingTypeOption;

  const defaultInitialValue = useMemo(
    () => ({
      member: null,
      project: null,
      role: null,
      seniority: null,
      startDate: new Date(),
      endDate: new Date(),
      billingType: allocationBillingType,
      allocationTimeAmount: INITIAL_ALLOCATION_VALUE,
      billable: true,
    }),
    [],
  );

  const { seniorities, getCreatedSeniority } = useCreatableSeniorityData();
  const { roles, getCreatedProjectRole } = useCreatableProjectRoleData();

  const { data: { projects = [] } = {}, loading: projectsLoading } = useProjectsOptionsQuery({
    onError: (err) => graphqlOnError(err, tls(err.message)),
    variables: { companyId: userData!.company.id, isArchived: false },
    fetchPolicy: 'no-cache',
  });

  const { data: { contracts = [] } = {}, loading: contractsLoading } = useContractsOptionsQuery({
    onError: (err) => graphqlOnError(err, tls(err.message)),
    variables: { companyId: userData!.company.id, isArchived: false },
    fetchPolicy: 'no-cache',
  });

  const { data: { members = [] } = {}, loading: teamMembersLoading } = useTeamMembersForAssignmentsQuery({
    onError: (err) => graphqlOnError(err, tls(err.message)),
    variables: { companyId: userData!.company.id, isArchived: false },
  });

  const getMemberOptionName = ({ first_name, last_name }: Member) =>
    getFullName(first_name, last_name, t('notApplicable'));
  const getBillingTypeOptions = ({ name }: BillingTypeOption) => t(name);

  const sortedProjects = useMemo(() => sortByField(projects, ASC, PROJECT), [projects]);
  const sortedTeamMembers = useMemo(() => sortByField(members, ASC, FIRST_NAME), [members]);
  const sortedRoles = useMemo(() => sortByField(roles, ASC, NAME), [roles]);
  const sortedSeniorities = useMemo(() => sortByField(seniorities, ASC, NAME), [seniorities]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          member: Yup.object().nullable().required(t('forms.newAssignment.memberRequired')),
          project: Yup.object().nullable().required(t('forms.newAssignment.projectRequired')),
          role: Yup.object().nullable().required(t('forms.newAssignment.roleRequired')),
          seniority: Yup.object().nullable(),
          allocationTimeAmount: Yup.number()
            .typeError(t('forms.newAssignment.allocationDecimal'))
            .required(t('forms.newAssignment.allocationError'))
            .test('maxDigitsAfterDecimal', t('forms.newAssignment.allocationDecimal'), (number) =>
              /^\d+(\.\d{1,2})?$/.test(`${number}`),
            )
            .positive(t('forms.newAssignment.allocationPositiveError')),
          startDate: Yup.string().nullable().required(t('forms.newAssignment.startDateRequired')),
          endDate: Yup.string()
            .nullable()
            .required(t('forms.newAssignment.endDateRequired'))
            .test('endDateBeforeStartDate', t('validation.endAfterStartError'), function (endDate) {
              const { startDate } = this.parent;
              return startDate && endDate ? isAfter(new Date(endDate), new Date(startDate)) : true;
            }),
          billingType: Yup.object().nullable().required(t('validation.required')),
          billable: Yup.boolean().default(true),
        },
        [['member', 'isMemberWithCostRates']],
      ),
    [teamMembersLoading],
  );

  const assignmentMember = (memberId && members.find(({ id }) => id === memberId)) || null;
  const assignmentProject = (projectId && projects.find(({ id }) => id === projectId)) || null;
  const assignmentContract = (contractId && contracts.find(({ id }) => id === contractId)) || null;

  const handleSubmit = async (values: AssignmentFormValues, { setSubmitting }: FormikHelpers<AssignmentFormValues>) => {
    const role = await getCreatedProjectRole(values.role);
    const seniority = await getCreatedSeniority(values.seniority);
    await submitForm({ ...values, role, seniority }, setSubmitting, onSubmit);
  };

  if ((projectId && projectsLoading) || (contractId && contractsLoading) || (memberId && teamMembersLoading)) {
    return <AbsoluteSpinner />;
  }

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={!!(id || memberId || projectId)}
        validateOnChange
        initialValues={{
          ...(initialValue || defaultInitialValue),
          ...(assignmentMember && { member: assignmentMember }),
          ...(assignmentProject && { project: assignmentProject }),
          ...(assignmentContract && {
            contract: assignmentContract,
            billable:
              typeof initialValue?.billable === 'boolean'
                ? initialValue?.billable
                : assignmentContract.type !== ProjectType.NonBillable,
          }),
        }}
        onSubmit={(initValues, actions: FormikHelpers<AssignmentFormValues>) => handleSubmit(initValues, actions)}
      >
        {({ isSubmitting, submitCount, values, errors, touched, setValues, handleBlur }) => {
          const isTimeAndMaterialContract = values.contract?.type === ProjectType.TimeAndMaterial;
          const isContractRetainer = values.contract?.type === ProjectType.Retainer;
          const isContractNonBillable = values.contract?.type === ProjectType.NonBillable;
          const isShowRates = isTimeAndMaterialContract || isContractRetainer;

          const projectContracts = values.project?.id
            ? contracts.filter(({ projectId }) => projectId === values.project?.id).reverse()
            : [];

          useEffect(() => {
            if (typeof initialValue?.billable === 'undefined') {
              setValues({ ...values, billable: !isContractNonBillable });
            }
            if (!initialValue && isTimeAndMaterialContract && !projectId) {
              setValues({ ...values, billingType: timeLogsBillingType });
            }
          }, [values.contract]);

          useEffect(() => {
            if (!initialValue && isTimeAndMaterialContract && projectId) {
              setValues({ ...values, billingType: timeLogsBillingType });
            }
          }, []);

          const onChangeProject = (project: AssignmentFormValues['project']) => {
            const projectContracts = project?.id ? contracts.filter(({ projectId }) => projectId === project?.id) : [];
            const contract = !project ? null : projectContracts[projectContracts.length - 1];
            setValues({ ...values, project, contract });
          };

          return (
            <Form className="form">
              <div>
                <InputLabel className="required">{t('forms.newAssignment.teamMember')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { member },
                    },
                  }: FieldProps<AssignmentFormValues['member'], AssignmentFormValues>) => (
                    <Autocomplete
                      placeholder={t('forms.newAssignment.selectTeamMember')}
                      name="member"
                      onBlur={handleBlur}
                      disabled={memberDisabled}
                      className="mb-24"
                      getOptionLabel={getMemberOptionName}
                      value={member}
                      error={touched.member ? errors.member : undefined}
                      onChange={(member: AssignmentFormValues['member']) => setValues({ ...values, member })}
                      options={memberDisabled ? [] : sortedTeamMembers}
                    />
                  )}
                </Field>
                <InputLabel className="required">{t('forms.newAssignment.project')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { project },
                    },
                  }: FieldProps<Pick<AssignmentFormValues, 'project'>, AssignmentFormValues>) => (
                    <Autocomplete
                      placeholder={t('forms.newAssignment.selectProject')}
                      className="mb-24"
                      disabled={projectDisabled}
                      name="project"
                      onBlur={handleBlur}
                      value={project}
                      error={touched.project ? errors.project : undefined}
                      onChange={onChangeProject}
                      options={sortedProjects || []}
                    />
                  )}
                </Field>
                <InputLabel className="required">{t('forms.newAssignment.contract')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { contract },
                    },
                  }: FieldProps<Pick<AssignmentFormValues, 'contract'>, AssignmentFormValues>) => (
                    <Autocomplete
                      placeholder={t('forms.newAssignment.selectContract')}
                      className="mb-24"
                      disabled={(projectDisabled || !values.project) && (initialValue?.contract || assignmentContract)}
                      key={contract?.id || 'contract'}
                      name="contract"
                      onBlur={handleBlur}
                      value={contract}
                      error={touched.contract ? errors.contract : undefined}
                      onChange={(contract: AssignmentFormValues['contract']) => setValues({ ...values, contract })}
                      options={projectContracts || []}
                    />
                  )}
                </Field>
                <div className={styles.twoItemsBox}>
                  <div className="flex-1">
                    <InputLabel className="required">{t('forms.newAssignment.projectRole')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { role },
                        },
                      }: FieldProps<AssignmentFormValues['role'], AssignmentFormValues>) => (
                        <CreatableAutocomplete
                          placeholder={t('forms.newAssignment.projectRole')}
                          name="role"
                          onBlur={handleBlur}
                          value={role}
                          error={touched.role ? errors.role : undefined}
                          onChange={(role: AssignmentFormValues['role']) =>
                            setValues({
                              ...values,
                              role,
                            })
                          }
                          options={sortedRoles || []}
                          enableCreation={hasAccess(ActionsType.OtherSettings)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="flex-1">
                    <InputLabel>{t('forms.newAssignment.seniority')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { seniority },
                        },
                      }: FieldProps<AssignmentFormValues['seniority'], AssignmentFormValues>) => (
                        <CreatableAutocomplete
                          placeholder={t('forms.newAssignment.seniority')}
                          name="seniority"
                          onBlur={handleBlur}
                          value={seniority}
                          error={touched.seniority ? errors.seniority : undefined}
                          onChange={(seniority: AssignmentFormValues['seniority']) =>
                            setValues({
                              ...values,
                              seniority,
                            })
                          }
                          options={sortedSeniorities || []}
                          enableCreation={hasAccess(ActionsType.OtherSettings)}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="flex mb-24">
                  <div className="flex-1">
                    <InputLabel className="required">{t('forms.newAssignment.dateFrom')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { startDate },
                        },
                      }: FieldProps<Assignment['startDate'], AssignmentFormValues>) => {
                        return (
                          <DatePicker
                            value={startDate}
                            error={Boolean(submitCount && errors.startDate)}
                            helperText={!!submitCount && errors.startDate}
                            onChange={(startDate: Assignment['startDate']) => setValues({ ...values, startDate })}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className={styles.datePickersDash}>
                    <DashIcon />
                  </div>
                  <div className="flex-1">
                    <InputLabel className="required">{t('forms.newAssignment.dateTo')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { endDate },
                        },
                      }: FieldProps<Assignment['endDate'], AssignmentFormValues>) => {
                        return (
                          <DatePicker
                            value={endDate}
                            error={Boolean(submitCount && errors.endDate)}
                            helperText={!!submitCount && errors.endDate}
                            onChange={(endDate: Assignment['endDate']) => setValues({ ...values, endDate })}
                          />
                        );
                      }}
                    </Field>
                  </div>
                </div>

                {isShowRates && values.contract && values.role && (
                  <AssignmentRate
                    contractId={values.contract.id}
                    projectId={values.contract.projectId}
                    currency={values.contract.fee_currency || ''}
                    unit={values.contract.unit || ''}
                    role={values.role}
                    seniority={values.seniority}
                    startDate={values.startDate}
                    endDate={values.endDate}
                  />
                )}

                <div className={styles.twoItemsBox}>
                  <div className="w-100">
                    <InputLabel className="required">{t('forms.newAssignment.billingType.label')}</InputLabel>
                    <Field>
                      {({
                        form: {
                          values: { billingType },
                        },
                      }: FieldProps<AssignmentFormValues['billingType'], AssignmentFormValues>) => (
                        <Autocomplete
                          options={BillingTypes}
                          getOptionLabel={getBillingTypeOptions}
                          name="billingType"
                          onBlur={handleBlur}
                          value={billingType}
                          error={touched.billingType ? errors.billingType : undefined}
                          placeholder={t('forms.select')}
                          onChange={(billingType: AssignmentFormValues['billingType']) =>
                            setValues({ ...values, billingType })
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className="w-100">
                    <InputLabel className="required">{t('forms.newAssignment.allocation')}</InputLabel>
                    <Field
                      component={NumberTextField}
                      name="allocationTimeAmount"
                      placeholder="0"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setValues({ ...values, allocationTimeAmount: e.target.value })
                      }
                      error={!!submitCount && touched.allocationTimeAmount ? errors.allocationTimeAmount : undefined}
                      helperText={!!submitCount && errors.allocationTimeAmount}
                    />
                  </div>
                </div>

                <FormControlLabel
                  className={styles.checkboxLabel}
                  classes={{
                    label: clsx(
                      styles.checkboxLabelText,
                      values.billable && styles.active,
                      !!values.project && !isTimeAndMaterialContract && !isContractRetainer && styles.disable,
                    ),
                  }}
                  control={<Checkbox color={'primary'} checked={values.billable} icon={<CheckboxIcon />} />}
                  label={t('forms.newAssignment.billable')}
                  name="billable"
                  onChange={() => setValues({ ...values, billable: !values.billable })}
                  disabled={!!values.project && !isTimeAndMaterialContract && !isContractRetainer}
                />
              </div>
              <div className="controls">
                {additionalControlsContent}
                <div className="flex justify-content-between">
                  <div className="flex gap-8">
                    <LoadingButton type="submit" loading={isSubmitting} disabled={submitDisabled}>
                      {submitLabel ?? t('forms.newAssignment.create')}
                    </LoadingButton>
                    <Button variant="outlined" color="secondary" onClick={onCancel}>
                      {t('forms.cancel')}
                    </Button>
                  </div>
                  {additionalButton}
                </div>
                {showRequestDescription && (
                  <div className={styles.requestInfo}>
                    <LockIcon className={styles.lockIcon} />
                    <>{id ? t('request.editFormDescription') : t('request.createFormDescription')}</>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
