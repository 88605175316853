import { useCallback } from 'react';
import {
  ContractDocument,
  ContractsDocument,
  ContractsOptionsDocument,
  useRestoreContractMutation,
} from 'generated/graphql';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';

export const useRestoreContract = () => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [restoreContract] = useRestoreContractMutation({
    async onCompleted() {
      toast.success(t('contracts.restoredSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  return useCallback(
    (contractId: string, projectId: string) =>
      restoreContract({
        variables: {
          companyId: userData!.company.id,
          contractId,
          projectId,
        },
        refetchQueries: [
          ContractsOptionsDocument,
          ContractsDocument,
          {
            query: ContractDocument,
            variables: {
              companyId: userData!.company.id,
              contractId,
              projectId,
              withCommission: true,
            },
          },
        ],
      }),
    [],
  );
};
