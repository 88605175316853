import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogWrapper } from 'components';
import { BillableLeaveRuleDataFragment } from 'generated/types';
import { LeaveRules } from 'views/ProjectDetail/BillableLeaves/LeaveRules';

import styles from './styles.module.scss';

interface Props {
  contractId: string;
  projectId: string;
  leavesRules: BillableLeaveRuleDataFragment[];
  isOpen: boolean;
  onClose: () => void;
}

export const LeavesConfiguration: FC<Props> = ({ isOpen, onClose, leavesRules, contractId, projectId }) => {
  const { t } = useTranslation();

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      contentClassName={styles.dialogContent}
      className={styles.dialogPaper}
      title={t('viewProjectDetail.leavesBalance.billableLeavesConfiguration')}
      hideActions
    >
      <LeaveRules leavesRules={leavesRules} projectId={projectId} contractId={contractId} />
    </DialogWrapper>
  );
};
