import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AbsoluteSpinner,
  AssignmentsCell,
  Collapse,
  DropdownType,
  EmptyState,
  EndDateReminder,
  ExpandedColumn,
  ProjectInfoCell,
  SIZES,
  Table,
  TableActionCell,
  TableColumnSettingDrawer,
  Tooltip,
  UserInfo,
} from 'components';
import { GroupByFilter, TableCell } from 'types';
import {
  getAcronym,
  groupContractsByPM,
  groupContractsByProject,
  groupContractsByType,
  replaceAmountWithHiddenSymbols,
  sortByField,
  toShortFormat,
} from 'utils';
import {
  ACTIONS,
  ACTIVE_ASSIGNMENTS,
  ASC,
  ContractsTableColumns,
  CURRENT_COMMISSION,
  EMPTY_DATA_STATE,
  END_DATE,
  FORECAST_MARGIN,
  MARGIN,
  NAME,
  PM,
  START_DATE,
  TableColumnsWidth,
  TYPE,
} from 'consts';
import { links } from 'App';
import { ActionsType, ContractDataFragment } from 'generated/types';
import { useContractsTableSetting, useContractTableSorts, useIsOpen, usePermissions } from 'hooks';
import { SortingRule } from 'react-table';
import { roundPercent } from 'views/Insights/helpers';
import { IconButton } from '@material-ui/core';
import { SettingsActiveLink as SettingsIcon } from 'icons';
import { useSettings } from 'contexts';

import styles from './styles.module.scss';

export type ContractData = ContractDataFragment & {
  margin: number;
  forecastMargin: number;
  currentCommission?: number | null;
  activeAssignments?: number;
};
interface Props {
  loading?: boolean;
  contracts: ContractData[];
  groupBy?: GroupByFilter;
  getTableActions: (value: { id: string; projectId: string; isActiveAssignment: boolean }) => ReactNode;
}

export const ContractsTable: FC<Props> = ({ loading, contracts, groupBy, getTableActions }) => {
  const { t } = useTranslation();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [tableSortBy, setTableSortBy] = useState<SortingRule<ContractData>[] | null>(null);
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { isFinancialsHidden } = useSettings();

  const changeTableSortBy = useCallback((sortBy: SortingRule<ContractData>) => {
    setTableSortBy([sortBy]);
  }, []);

  const groupedContractsByType = groupBy === GroupByFilter.type ? groupContractsByType(contracts) : {};
  const groupedContractsByProject = groupBy === GroupByFilter.project ? groupContractsByProject(contracts) : {};
  const groupedContractsByPM = groupBy === GroupByFilter.projectManager ? groupContractsByPM(contracts) : {};

  const {
    defaultActiveColumns,
    columnsOrder,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  } = useContractsTableSetting();

  const { sortNameType, sortProjectNameType, sortPMType, sortStartDateType, sortEndDateType } = useContractTableSorts();

  const columns = useMemo<ExpandedColumn<ContractData>[]>(() => {
    const isGroupColumns = groupBy && groupBy !== GroupByFilter.none;

    return [
      {
        id: 'project',
        Header: (
          <span title={t('columns.contracts.project')} className={isGroupColumns ? 'pl-30' : 'pl-0'}>
            {t('columns.contracts.project')}
          </span>
        ),
        accessor: ({ projectName, projectColor }) => ({ projectName, projectColor }),
        sortable: true,
        sortType: sortProjectNameType,
        sticky: 'left',
        show: groupBy !== GroupByFilter.project,
        Cell: function project({ value: { projectName, projectColor } }: TableCell<ContractData>) {
          return (
            <ProjectInfoCell
              hasTooltip
              titleClassName={styles.projectCell}
              color={projectColor || ''}
              title={projectName}
            />
          );
        },
      },
      {
        id: 'contract',
        Header: <span title={t('columns.contracts.contract')}>{t('columns.contracts.contract')}</span>,
        accessor: ({ name }) => name,
        sortable: true,
        sortType: sortNameType,
        sticky: groupBy === GroupByFilter.project ? 'left' : undefined,
        show: activeColumns.includes(ContractsTableColumns.Contract) || groupBy === GroupByFilter.project,
        Cell: function contract({ value }: TableCell<string>) {
          return (
            <Tooltip title={value} placement="top" textClassName={styles.tooltipTitle}>
              {value}
            </Tooltip>
          );
        },
      },
      {
        id: PM,
        Header: t('columns.projects.projectManager')!,
        accessor: ({ projectPM, projectPMColor }) => ({ projectPM, projectPMColor }),
        sortable: true,
        sortType: sortPMType,
        show: activeColumns.includes(ContractsTableColumns.PM) && groupBy !== GroupByFilter.projectManager,
        Cell: function projectManager({ value: { projectPM, projectPMColor } }: TableCell<ContractData>) {
          return (
            <UserInfo
              title={projectPM}
              titleClassName={styles.projectManagerTitle}
              avatarTitle={getAcronym(...projectPM.split(' '))}
              color={projectPMColor}
            />
          );
        },
      },
      {
        Header: t('columns.contracts.contractType')!,
        accessor: TYPE,
        sortable: true,
        show: activeColumns.includes(ContractsTableColumns.Type) && groupBy !== GroupByFilter.type,
        Cell: function type({ value }) {
          return t(`projectType.${value}`);
        },
        minWidth: 150,
      },
      {
        Header: t('columns.contracts.startDate')!,
        accessor: START_DATE,
        sortable: true,
        sortType: sortStartDateType,
        show: activeColumns.includes(ContractsTableColumns.StartDate),
        Cell: function startDate({ value }) {
          return toShortFormat(new Date(value));
        },
        ...TableColumnsWidth.shortDate,
      },
      {
        Header: t('columns.contracts.endDate')!,
        accessor: END_DATE,
        sortable: true,
        sortType: sortEndDateType,
        show: activeColumns.includes(ContractsTableColumns.EndDate),
        Cell: function endDate({ value }) {
          return <EndDateReminder date={value} hideTag textClassName="text-14" />;
        },
        ...TableColumnsWidth.shortDate,
      },
      {
        Header: t('columns.contracts.team')!,
        sortable: true,
        accessor: ACTIVE_ASSIGNMENTS,
        show: activeColumns.includes(ContractsTableColumns.ActiveAssignments),
        Cell: function team({
          row: {
            original: { id, activeAssignments },
          },
        }) {
          return <AssignmentsCell contractId={id} activeItemsNumber={activeAssignments} type={DropdownType.team} />;
        },
        ...TableColumnsWidth.countButton,
      },
      {
        Header: t('columns.contracts.ytdMargin')!,
        accessor: MARGIN,
        sortable: true,
        show: hasAccess(ActionsType.ViewProjectCosts) && activeColumns.includes(ContractsTableColumns.Margin),
        Cell: function margin({ value }) {
          return (
            <>
              {typeof value === 'number'
                ? `${replaceAmountWithHiddenSymbols(roundPercent(value), isFinancialsHidden)}%`
                : EMPTY_DATA_STATE}
            </>
          );
        },
        ...TableColumnsWidth.percentage,
      },
      {
        Header: t('columns.contracts.yearForecastMargin')!,
        accessor: FORECAST_MARGIN,
        sortable: true,
        show: hasAccess(ActionsType.ViewProjectCosts) && activeColumns.includes(ContractsTableColumns.ForecastMargin),
        Cell: function forecastMargin({ value }) {
          return (
            <span className={styles.forecastedMargin}>
              {typeof value === 'number'
                ? `${replaceAmountWithHiddenSymbols(roundPercent(value), isFinancialsHidden)}%`
                : EMPTY_DATA_STATE}
            </span>
          );
        },
        ...TableColumnsWidth.percentage,
      },
      {
        Header: t('columns.contracts.currentCommission')!,
        accessor: CURRENT_COMMISSION,
        sortable: true,
        show:
          hasAccess(ActionsType.ViewProjectCosts) && activeColumns.includes(ContractsTableColumns.CurrentCommission),
        Cell: function currentCommission({ value }) {
          return (
            <span className={styles.forecastedMargin}>
              {typeof value === 'number'
                ? `${replaceAmountWithHiddenSymbols(roundPercent(value), isFinancialsHidden)}%`
                : EMPTY_DATA_STATE}
            </span>
          );
        },
        ...TableColumnsWidth.percentage,
      },
      {
        Header: (
          <Tooltip
            title={t('table.columnSettings.customiseColumns')!}
            placement="top"
            className={styles.settingTooltip}
            alwaysShowTooltip
          >
            <IconButton size="small" className="p-0" onClick={onOpen}>
              <SettingsIcon className={styles.settingIcon} />
            </IconButton>
          </Tooltip>
        ),
        id: ACTIONS,
        accessor: 'id',
        Cell: function action({
          row: {
            original: { id, projectId, activeAssignments },
          },
          isHovered,
        }) {
          return hasAccess(ActionsType.ArchiveProjects) ? (
            <TableActionCell isHovered={isHovered}>
              {getTableActions({ id, projectId, isActiveAssignment: !!activeAssignments })}
            </TableActionCell>
          ) : (
            ''
          );
        },
      },
    ];
  }, [groupBy, isPermissionsLoading, activeColumns, isFinancialsHidden, getTableActions]);

  const getRedirectToDetailsLink = (item: string | ContractData) => {
    if (typeof item === 'string') {
      return links.ProjectDetail({ id: item });
    } else {
      return links.ProjectDetail({ id: item.projectId, contractId: item.id });
    }
  };

  if (loading || isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!contracts.length) {
    return <EmptyState className={styles.emptyState} />;
  }

  if (!hasAccess(ActionsType.ViewActiveProjects)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  const renderTable = (data: ContractData[], insideAccordion?: boolean) => (
    <div className={styles.containerTable}>
      <Table
        columns={columns}
        getRedirectRowLink={getRedirectToDetailsLink}
        isRedirectRowLinkFullItemData
        data={data}
        onChangeTableSortBy={changeTableSortBy}
        sortByValue={tableSortBy}
        columnOrder={[ContractsTableColumns.Project, ...columnsOrder]}
        insideAccordion={insideAccordion}
      />
    </div>
  );

  return (
    <>
      {!groupBy || groupBy === GroupByFilter.none ? renderTable(sortByField(contracts, ASC, NAME)) : ''}

      {groupBy === GroupByFilter.type ? (
        <>
          {Object.keys(groupedContractsByType).map((key) => {
            const contractsAmount = groupedContractsByType[key].length;
            const type = groupedContractsByType[key][0].type;
            const title = contractsAmount ? t(`projectType.${type}`) ?? t(`projects.${key}`)! : '';

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <Tooltip title={title} placement="top" textClassName={styles.containerTitle}>
                        {title}
                      </Tooltip>
                      <span className={styles.containerChip}>{contractsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(sortByField(groupedContractsByType[key], ASC, TYPE) as ContractData[], true)}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}

      {groupBy === GroupByFilter.project ? (
        <>
          {Object.keys(groupedContractsByProject).map((key) => {
            const contractsAmount = groupedContractsByProject[key].length;
            const projectColor = groupedContractsByProject[key][0].projectColor;
            const projectName = groupedContractsByProject[key][0].projectName;

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <ProjectInfoCell
                        inCollapse
                        hasTooltip
                        titleClassName={styles.projectCell}
                        color={projectColor || ''}
                        title={projectName}
                      />
                      <span className={styles.containerChip}>{contractsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(sortByField(groupedContractsByProject[key], ASC, TYPE) as ContractData[], true)}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}

      {groupBy === GroupByFilter.projectManager ? (
        <>
          {Object.keys(groupedContractsByPM).map((key) => {
            const contractsAmount = groupedContractsByPM[key].length;
            const projectPM = groupedContractsByPM[key][0].projectPM;
            const projectPMColor = groupedContractsByPM[key][0].projectPMColor;

            return (
              <div key={key} className={styles.container}>
                <Collapse
                  header={
                    <>
                      <UserInfo
                        title={projectPM}
                        titleClassName={styles.projectManagerTitle}
                        avatarTitle={getAcronym(...projectPM.split(' '))}
                        color={projectPMColor}
                        size={SIZES.xs}
                      />
                      <span className={styles.containerChip}>{contractsAmount}</span>
                    </>
                  }
                  withBorders
                >
                  {renderTable(sortByField(groupedContractsByPM[key], ASC, TYPE) as ContractData[], true)}
                </Collapse>
              </div>
            );
          })}
        </>
      ) : (
        ''
      )}

      <TableColumnSettingDrawer
        getColumnTitle={(column) => t(`contracts.columnSettings.${column}`)}
        allColumnOptions={defaultActiveColumns}
        activeColumns={activeColumns}
        columnsOrder={columnsOrder}
        onSubmit={onChangeActiveColumns}
        onChangeOrder={onChangeColumnsOrder}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};
