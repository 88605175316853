import { ContractDataFragment } from 'generated/types';

const NO_TYPE = 'noType';

export const groupContractsByType = (data: ContractDataFragment[]) =>
  data
    .sort((a, b) => {
      return a.type.localeCompare(b.type, 'en');
    })
    .reduce((acc: Record<string, ContractDataFragment[]>, item) => {
      const type: string = item?.type ?? NO_TYPE;
      acc[type] = acc[type] || [];
      acc[type].push(item);
      return acc;
    }, {});

export const groupContractsByProject = (data: ContractDataFragment[]) =>
  data
    .sort((a, b) => {
      return a.projectName.localeCompare(b.projectName, 'en');
    })
    .reduce((acc: Record<string, ContractDataFragment[]>, item) => {
      acc[item.projectId] = acc[item.projectId] || [];
      acc[item.projectId].push(item);
      return acc;
    }, {});

export const groupContractsByPM = (data: ContractDataFragment[]) =>
  data
    .sort((a, b) => {
      return a.projectPM.localeCompare(b.projectPM, 'en');
    })
    .reduce((acc: Record<string, ContractDataFragment[]>, item) => {
      acc[item.projectPmId] = acc[item.projectPmId] || [];
      acc[item.projectPmId].push(item);
      return acc;
    }, {});
