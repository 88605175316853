import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { CommissionDataFragmentDoc, useCreateCommissionMutation } from 'generated/graphql';
import { CommissionForm, FormValues } from '../components/CommissionForm';
import { toast } from 'react-toastify';
import { Reference } from '@apollo/client';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { getCommissionsRefetchQueries } from '../utils/getCommissionsRefetchQueries';

interface Props {
  contractId: string;
  projectId: string;
  onClose: () => void;
}

export const CreateCommission: FC<Props> = ({ contractId, projectId, onClose }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [createCommission, { loading }] = useCreateCommissionMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('viewProjectDetail.commissions.createSuccessfully'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.createCommission) return;

      const createdCommissionRef = cache.writeFragment({
        data: data.createCommission,
        fragment: CommissionDataFragmentDoc,
      });

      cache.modify({
        fields: {
          commissions(items: Reference[] = []) {
            return [...items, createdCommissionRef];
          },
        },
      });
    },
    refetchQueries: getCommissionsRefetchQueries(userData!.company.id, projectId, contractId),
  });

  const onCreate = useCallback(async (values?: FormValues) => {
    await createCommission({
      variables: {
        data: {
          effectiveFrom: format(new Date(values?.effectiveFrom || ''), DEFAULT_DATE_FORMAT),
          commission: +(values?.commission || 0),
        },
        companyId: userData!.company.id,
        projectId,
        contractId,
      },
    });
  }, []);

  return <CommissionForm onSubmit={onCreate} loading={loading} onCancel={onClose} />;
};
