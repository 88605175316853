import { useCallback, useState } from 'react';
import { localStorageManager } from 'services';
import { PROJECTS_TABLE_COLUMN_ORDER, PROJECTS_TABLE_HIDDEN_COLUMNS, ProjectsTableColumns } from 'consts';

const defaultActiveColumns = [
  ProjectsTableColumns.Client,
  ProjectsTableColumns.PM,
  ProjectsTableColumns.ActiveContracts,
  ProjectsTableColumns.File,
];

export const useProjectsTableSetting = () => {
  const currentColumnOrder = (localStorageManager.getItem(
    PROJECTS_TABLE_COLUMN_ORDER,
  ) as unknown) as ProjectsTableColumns[];

  const [columnsOrder, setColumnsOrder] = useState<string[]>(currentColumnOrder || []);

  const onChangeColumnsOrder = useCallback((columns: string[]) => {
    setColumnsOrder(columns);
    localStorageManager.setItem(PROJECTS_TABLE_COLUMN_ORDER, columns);
  }, []);

  const hiddenColumnOptions = (localStorageManager.getItem(
    PROJECTS_TABLE_HIDDEN_COLUMNS,
  ) as unknown) as ProjectsTableColumns[];

  const currentColumnOptions = hiddenColumnOptions
    ? Object.values(ProjectsTableColumns).filter((column) => !hiddenColumnOptions?.includes(column))
    : defaultActiveColumns;

  const [activeColumns, setActiveColumns] = useState<string[]>(currentColumnOptions);

  const onChangeActiveColumns = useCallback((columns: string[]) => {
    setActiveColumns(columns);

    const hiddenColumns = Object.values(ProjectsTableColumns).filter((column) => !columns?.includes(column));
    localStorageManager.setItem(PROJECTS_TABLE_HIDDEN_COLUMNS, hiddenColumns);
  }, []);

  return {
    defaultActiveColumns,
    columnsOrder,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  };
};
