import { ContractAssignmentsListDocument, CommissionsDocument, MilestonesSectionDocument } from 'generated/graphql';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

export const getCommissionsRefetchQueries = (companyId: string, projectId: string, contractId: string) => {
  const start = format(startOfMonth(new Date()), DEFAULT_DATE_FORMAT);
  const end = format(endOfMonth(new Date()), DEFAULT_DATE_FORMAT);

  return [
    ContractAssignmentsListDocument,
    {
      query: CommissionsDocument,
      variables: { companyId, contractId },
    },
    {
      query: ContractAssignmentsListDocument,
      variables: {
        companyId,
        projectId,
        contractId,
        companyCurrency: false,
        paginationAssignmentData: { start, end },
      },
    },
    {
      query: ContractAssignmentsListDocument,
      variables: {
        companyId,
        projectId,
        contractId,
        companyCurrency: true,
        paginationAssignmentData: { start, end },
      },
    },
    {
      query: MilestonesSectionDocument,
      variables: {
        companyId,
        projectId,
        contractId,
        companyCurrency: true,
      },
    },
    {
      query: MilestonesSectionDocument,
      variables: {
        companyId,
        projectId,
        contractId,
        companyCurrency: false,
      },
    },
  ];
};
