import React, { FC, useCallback } from 'react';
import { useAssignmentsLazyQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { AssignmentsDropdown } from 'components';

export enum DropdownType {
  team = 'team',
  contracts = 'contracts',
}

export interface AssignmentsCellProps {
  memberId?: string;
  contractId?: string;
  activeItemsNumber?: number;
  type: DropdownType;
}

export const AssignmentsCell: FC<AssignmentsCellProps> = ({ activeItemsNumber = 0, memberId, contractId, type }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [fetchAssignments, { data, loading }] = useAssignmentsLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const onFetchAssignments = useCallback(() => {
    fetchAssignments({
      variables: {
        companyId: userData!.company.id,
        data: { memberId, contractId },
        filters: { isActive: true },
        withContracts: type === DropdownType.contracts,
      },
    });
  }, [memberId, contractId]);

  const filteredAssignments =
    data?.assignments.reduce((acc, rec) => {
      // duplicate - assignment with the same member and the same role
      const duplicate = acc.find((item) =>
        type === DropdownType.contracts
          ? item?.contract?.id === rec.contract?.id && item?.role?.id === rec.role.id
          : item?.member?.id === rec.member.id && item?.role?.id === rec.role.id,
      );
      if (duplicate) return acc;
      return [...acc, rec];
    }, [] as typeof data.assignments) ?? [];

  return (
    <AssignmentsDropdown
      loading={loading}
      data={filteredAssignments}
      activeItemsNumber={activeItemsNumber}
      onClick={onFetchAssignments}
      type={type}
    />
  );
};
