import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { BillableLeaveRuleDataFragmentDoc, useCreateBillableLeaveRuleMutation } from 'generated/graphql';
import { BillableLeaveRuleAccrualType } from 'generated/types';
import { FormValues, LeaveRuleForm } from '../components/LeaveRuleForm';
import { toast } from 'react-toastify';
import { Reference } from '@apollo/client';
import { format, parse } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  projectId: string;
  contractId: string;
  onClose: () => void;
  usedLeaveRulesId?: string[];
}

export const CreateLeaveRule: FC<Props> = ({ projectId, contractId, onClose, usedLeaveRulesId }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [createBillableLeaveRule, { loading }] = useCreateBillableLeaveRuleMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('viewProjectDetail.billableLeaves.createSuccessfully'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.createBillableLeaveRule) return;

      const createdClientRef = cache.writeFragment({
        data: data.createBillableLeaveRule,
        fragment: BillableLeaveRuleDataFragmentDoc,
      });

      cache.modify({
        fields: {
          billableLeavesRules(items: Reference[] = []) {
            return [...items, createdClientRef];
          },
        },
      });
    },
  });

  const onCreate = useCallback(async (values?: FormValues) => {
    const { accrualDate, accrualType } =
      values?.accrualDate?.id === BillableLeaveRuleAccrualType.AssignmentStartMonth
        ? { accrualDate: null, accrualType: BillableLeaveRuleAccrualType.AssignmentStartMonth }
        : {
            accrualDate: format(parse(values?.accrualDate?.id || '', 'MMMM', new Date()), DEFAULT_DATE_FORMAT),
            accrualType: BillableLeaveRuleAccrualType.Monthly,
          };

    await createBillableLeaveRule({
      variables: {
        data: {
          limit: +(values?.limit || 0),
          accrualDate,
          accrualType,
          leaveTypes: values?.leaveTypes?.map(({ id }) => id) || [],
        },
        companyId: userData!.company.id,
        projectId,
        contractId,
      },
    });
  }, []);

  return <LeaveRuleForm onSubmit={onCreate} loading={loading} onCancel={onClose} usedLeaveRulesId={usedLeaveRulesId} />;
};
