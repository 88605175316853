import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { PlusIcon } from 'icons';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { CreateLeaveRule } from '../CreateLeaveRule';
import { BillableLeaveRuleDataFragment } from 'generated/types';
import { LeaveRuleItem } from '../components/LeaveRuleItem';
import { LeaveRuleHeader } from '../components/LeaveRuleHeader';

interface Props {
  isBillableLeaves?: boolean;
  projectId: string;
  contractId: string;
  leavesRules: BillableLeaveRuleDataFragment[];
  loading?: boolean;
  onChangeIsLeavesUnsaved?: (value: boolean) => void;
  onChangeIsBillableLeaves?: () => void;
}

export const LeaveRules: FC<Props> = ({
  isBillableLeaves,
  leavesRules,
  loading,
  contractId,
  projectId,
  onChangeIsLeavesUnsaved,
  onChangeIsBillableLeaves,
}) => {
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const isEmptyList = !leavesRules.length && !loading;
  const cancelCreating = () => setIsCreating(false);
  const usedLeaveRulesId = useMemo(
    () => leavesRules.reduce<string[]>((acc, { leaveTypes }) => [...acc, ...leaveTypes.map(({ id }) => id)], []),
    [leavesRules],
  );
  const [editingLeaves, setEditingLeaves] = useState<string[]>([]);

  const onToggleEditing = useCallback((id: string) => {
    setEditingLeaves((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
  }, []);

  const onLogDeleted = useCallback(() => {
    if (!isCreating && leavesRules.length === 1) {
      onChangeIsBillableLeaves?.();
    }
  }, [isCreating, leavesRules]);

  useEffect(() => {
    onChangeIsLeavesUnsaved?.(!!editingLeaves.length || isCreating || isEmptyList);
  }, [editingLeaves, isCreating, isEmptyList, isBillableLeaves]);

  return (
    <>
      <div className={styles.box}>
        <LeaveRuleHeader />
        {[...leavesRules]
          .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
          .map((rule) => (
            <LeaveRuleItem
              key={rule.id}
              leaveRule={rule}
              usedLeaveRulesId={usedLeaveRulesId}
              onToggleEditing={onToggleEditing}
              onLogDeleted={onLogDeleted}
            />
          ))}
        {(isCreating || isEmptyList) && (
          <CreateLeaveRule
            projectId={projectId}
            contractId={contractId}
            onClose={cancelCreating}
            usedLeaveRulesId={usedLeaveRulesId}
          />
        )}
      </div>

      {!isCreating && !isEmptyList && (
        <Button
          variant="text"
          color="secondary"
          className={styles.button}
          startIcon={<PlusIcon className={styles.icon} />}
          onClick={() => setIsCreating(true)}
        >
          {t('viewProjectDetail.billableLeaves.addNewRule')}
        </Button>
      )}
    </>
  );
};
