import React, { MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { ProjectsLink, UsersIcon } from 'icons';
import { Member, Project, Role } from 'generated/types';
import Menu from '@material-ui/core/Menu';
import { DropdownType, LoadingButton } from 'components';

import { ProjectDropdownItem } from './components/ProjectDropdownItem';
import { TeamDropdownItem } from './components/TeamDropdownItem';

import styles from './styles.module.scss';

export interface AssignmentsDropdownProps {
  loading?: boolean;
  activeItemsNumber: number;
  onClick: () => void;
  type: DropdownType;
  data?: Array<{
    id: string;
    role: Pick<Role, 'name'>;
    member?: Pick<Member, 'id' | 'first_name' | 'last_name' | 'avatar_url'> | null;
    project?: Pick<Project, 'id' | 'name' | 'color'> | null;
  }>;
}

export const AssignmentsDropdown = ({
  activeItemsNumber = 0,
  onClick,
  data = [],
  loading = false,
  type,
}: AssignmentsDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleOpen = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (activeItemsNumber) {
      onClick();
      handleClick(e);
    }
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  return (
    <div className={styles.assignmentCell}>
      <LoadingButton
        size="medium"
        color="secondary"
        loading={loading}
        onClick={handleOpen}
        disableRipple={!activeItemsNumber}
        startIcon={
          type === DropdownType.contracts ? (
            <ProjectsLink className={styles.assignmentCellIcon} />
          ) : (
            <UsersIcon className={styles.assignmentCellIcon} />
          )
        }
        className={clsx(styles.assignmentCellButton, !activeItemsNumber && styles.disabled)}
      >
        {activeItemsNumber}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(anchorEl) && !loading}
        onClose={handleClose}
      >
        {data.map(({ id, member, project, role }) =>
          type === DropdownType.contracts
            ? project && <ProjectDropdownItem key={id} project={project} handleClose={handleClose} role={role.name} />
            : member && <TeamDropdownItem key={id} member={member} handleClose={handleClose} role={role.name} />,
        )}
      </Menu>
    </div>
  );
};
