import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';
import { AbsoluteSpinner, AssignmentForm, AssignmentFormValues, CustomToastWithLink, EmptyState } from 'components';
import {
  MemberProjectsDistributionDocument,
  ResourcePlanningMembersDocument,
  ResourcePlanningProjectsDocument,
  useCreateAssignmentMutation,
} from 'generated/graphql';
import { getAssignmentFormattedFormData, graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { ActionsType } from 'generated/types';
import { client } from 'graphql-client';
import { links } from 'App';
import { BillingTrackType, ModalModeEnum } from 'types';

interface CreateAssignmentProps {
  memberId?: string;
  projectId?: string;
  contractId?: string;
  onCancel: () => void;
  onCreate?: () => void;
  memberDisabled?: boolean;
  projectDisabled?: boolean;
  submitLabel?: string;
}

export const CreateAssignment: FC<CreateAssignmentProps> = ({
  memberId,
  projectId,
  contractId,
  onCancel,
  onCreate,
  memberDisabled,
  projectDisabled,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess, isPermissionsLoading } = usePermissions();

  const [createAssignment, { loading }] = useCreateAssignmentMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      const assignmentLink = links.ResourcePlanning({ mode: ModalModeEnum.manage, id: data.createAssignment.id });
      toast.success(
        CustomToastWithLink(
          assignmentLink,
          t('forms.newAssignment.assignment'),
          t('forms.newAssignment.createdSuccessfully'),
        ),
      );
      mixpanel.track('Assignment created', {
        'Billing type': BillingTrackType[data.createAssignment.bill_amount_calculation_type],
        'Seniority set': !!data.createAssignment.seniorityId,
        'Non billable': data.createAssignment.billable,
      });
      onCreate?.();
      client.refetchQueries({
        include: [
          ResourcePlanningMembersDocument,
          ResourcePlanningProjectsDocument,
          MemberProjectsDistributionDocument,
        ],
      });
      onCancel();
    },
  });

  const onCreateAssignment = useCallback((formValues: AssignmentFormValues) => {
    const data = {
      variables: {
        data: getAssignmentFormattedFormData(formValues),
        companyId: userData!.company.id,
      },
    };

    createAssignment(data);
  }, []);

  if (isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!hasAccess(ActionsType.CreateAssignments)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <AssignmentForm
      memberId={memberId}
      projectId={projectId}
      contractId={contractId}
      onCancel={onCancel}
      onSubmit={onCreateAssignment}
      memberDisabled={memberDisabled}
      projectDisabled={projectDisabled}
      submitLabel={t('actions.create')}
      submitDisabled={loading}
    />
  );
};
