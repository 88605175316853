import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { QueryOptions } from '@apollo/client';

import { Button, MenuItem } from '@material-ui/core';

import { client } from 'graphql-client';
import { PlusIcon, TriangleDown } from 'icons';
import { DropdownMenu, RequestTooltip, RightDrawer } from 'components/common';
import {
  CreateAssignment,
  CreateAssignmentRequest,
  NewClient,
  NewContract,
  NewLeave,
  NewProject,
  NewTeamMember,
} from 'components';
import { ResourcePlanningMembersDocument, ResourcePlanningProjectsDocument } from 'generated/graphql';

import { useCreateClient, useCreateMember, useCreateProject } from './hooks';

import assignmentIcon from './images/assignment.svg';
import leaveIcon from './images/leave.svg';
import clientIcon from './images/client.svg';
import projectIcon from './images/project.svg';
import memberIcon from './images/member.svg';
import contractIcon from 'icons/ProjectIcon.svg';
import { usePermissions } from 'hooks';
import { ActionsType } from 'generated/types';
import styles from './styles.module.scss';
import { useCreateContract } from './hooks/useCreateContract';

type FormType = 'client' | 'project' | 'contract' | 'teamMember' | 'assignment' | 'requestAssignment' | 'leave';

interface CommonActionsProps {
  menuItemsOrder: FormType[];
  refetchMembersAction?: Array<string | DocumentNode | QueryOptions>;
  refetchProjectsAction?: Array<string | DocumentNode | QueryOptions>;
  refetchContractsAction?: Array<string | DocumentNode | QueryOptions>;
}

type CommonDataType = {
  [key in FormType]: {
    title: string | ReactNode;
    form: ReactNode;
    menuItem: ReactNode;
    hidden?: boolean;
    isWideDrawer?: boolean;
  };
};

export const CommonActions = memo<CommonActionsProps>(
  ({ menuItemsOrder, refetchMembersAction, refetchProjectsAction, refetchContractsAction }) => {
    const { t } = useTranslation();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isHideDrawer, setIsHideDrawer] = useState(false);
    const [activeForm, setActiveForm] = useState<FormType>('client');
    const { hasAccess, isPermissionsLoading } = usePermissions();

    const onDrawerClose = () => setIsDrawerOpen(false);
    const onDrawerOpen = () => {
      setIsHideDrawer(false);
      setIsDrawerOpen(true);
    };

    const handleCreateClient = useCreateClient(onDrawerClose);
    const handleCreateProjects = useCreateProject(onDrawerClose, refetchProjectsAction);
    const handleCreateComponents = useCreateContract({ onClose: onDrawerClose, refetchAction: refetchContractsAction });
    const handleCreateUser = useCreateMember(onDrawerClose, refetchMembersAction);

    const onAssignmentUpdate = useCallback(() => {
      client.refetchQueries({
        include: [ResourcePlanningMembersDocument, ResourcePlanningProjectsDocument],
      });
    }, []);

    const commonData = useMemo<CommonDataType>(() => {
      return {
        client: {
          title: t('projects.createNewClient'),
          hidden: !hasAccess(ActionsType.CreateClients),
          form: <NewClient onSubmit={handleCreateClient} onCancel={onDrawerClose} />,
          menuItem: (
            <>
              <img alt={t('projects.createClient')} src={clientIcon} className="mr-12" />
              {t('projects.createClient')}
            </>
          ),
        },
        project: {
          title: t('projects.createNewProject'),
          hidden: !hasAccess(ActionsType.CreateProjects),
          form: <NewProject onSubmit={handleCreateProjects} onCancel={onDrawerClose} />,
          isWideDrawer: true,
          menuItem: (
            <>
              <img alt={t('projects.createProject')} src={projectIcon} className="mr-12" />
              {t('projects.createProject')}
            </>
          ),
        },
        contract: {
          title: t('contracts.createNewContract'),
          hidden: !hasAccess(ActionsType.CreateProjects),
          form: <NewContract onSubmit={handleCreateComponents} onCancel={onDrawerClose} />,
          isWideDrawer: true,
          menuItem: (
            <>
              <img alt={t('contracts.contract')} src={contractIcon} className="mr-12" />
              {t('contracts.contract')}
            </>
          ),
        },
        teamMember: {
          title: t('teams.teamMember'),
          hidden: !hasAccess(ActionsType.CreateMembers),
          form: <NewTeamMember onSubmit={handleCreateUser} onCancel={onDrawerClose} />,
          menuItem: (
            <>
              <img alt={t('teams.teamMember')} src={memberIcon} className="mr-12" />
              {t('teams.teamMember')}
            </>
          ),
        },
        assignment: {
          title: t(`resourcePlanning.create.assignment`),
          hidden: !hasAccess(ActionsType.CreateAssignments),
          form: <CreateAssignment onCreate={onAssignmentUpdate} onCancel={onDrawerClose} />,
          menuItem: (
            <>
              <img alt={t('resourcePlanning.createNewAssignment')} src={assignmentIcon} className="mr-12" />
              {t('resourcePlanning.createNewAssignment')}
            </>
          ),
        },
        requestAssignment: {
          title: (
            <div className="flex">
              {t(`resourcePlanning.create.assignment`)} <RequestTooltip item={t('request.items.assignment')} />
            </div>
          ),
          hidden: hasAccess(ActionsType.CreateAssignments) || !hasAccess(ActionsType.SubmitAssignmentRequest),
          form: (
            <CreateAssignmentRequest
              onCreate={onAssignmentUpdate}
              onCancel={onDrawerClose}
              onHide={() => setIsHideDrawer(true)}
            />
          ),
          menuItem: (
            <>
              <img alt={t('resourcePlanning.createNewAssignment')} src={assignmentIcon} className="mr-12" />
              {t('resourcePlanning.createNewAssignment')}
              <RequestTooltip item={t('request.items.assignment')} />
            </>
          ),
        },
        leave: {
          title: t(`resourcePlanning.create.leave`),
          hidden: !hasAccess(ActionsType.CreateLeaves),
          form: <NewLeave submitLabel={t('actions.create')} onCancel={onDrawerClose} />,
          menuItem: (
            <>
              <img alt={t('resourcePlanning.createNewLeave')} src={leaveIcon} className="mr-12" />
              {t('resourcePlanning.createNewLeave')}
            </>
          ),
        },
      };
    }, [isPermissionsLoading]);

    const hideAddButton =
      !hasAccess(ActionsType.CreateClients) &&
      !hasAccess(ActionsType.CreateProjects) &&
      !hasAccess(ActionsType.CreateMembers) &&
      !hasAccess(ActionsType.CreateAssignments) &&
      !hasAccess(ActionsType.SubmitAssignmentRequest) &&
      !hasAccess(ActionsType.CreateLeaves);

    if (hideAddButton || isPermissionsLoading) {
      return null;
    }

    return (
      <>
        <DropdownMenu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          trigger={(handleClick, open) => (
            <Button
              variant="contained"
              onClick={handleClick}
              startIcon={<PlusIcon />}
              endIcon={<TriangleDown className={clsx('dropdown-button-arrow', open && 'open')} />}
            >
              {t('projects.add')}
            </Button>
          )}
        >
          {(handleClose) =>
            menuItemsOrder.map((item) => {
              if (commonData[item].hidden) {
                return;
              }

              return (
                <MenuItem
                  key={`create-${item}`}
                  onClick={() => {
                    handleClose();
                    setActiveForm(item);
                    onDrawerOpen();
                  }}
                >
                  {commonData[item].menuItem}
                </MenuItem>
              );
            })
          }
        </DropdownMenu>
        <RightDrawer
          direction="right"
          open={isDrawerOpen}
          onClose={onDrawerClose}
          title={commonData[activeForm].title}
          paperClassName={clsx({
            [styles.displayNone]: isHideDrawer,
            [styles.wideDrawer]: commonData[activeForm].isWideDrawer,
          })}
        >
          {commonData[activeForm].form}
        </RightDrawer>
      </>
    );
  },
);

CommonActions.displayName = 'CommonActions';
