import React, { FC, useCallback, useMemo } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';
import { sample } from 'lodash';

import { AbsoluteSpinner, Autocomplete, CreatableAutocomplete, EmptyState, LoadingButton } from 'components';
import { ProjectPMAccessDate } from './components';
import { useProjectManagersQuery } from 'generated/graphql';
import { getFullName, graphqlOnError, removeUTCTimezone, sortByField, submitForm } from 'utils';
import { useCreatableClientData, useErrorMsgBuilder, usePermissions } from 'hooks';
import { useAuth } from 'contexts';
import { ActionsType, Client, CompanyUser, Member, ProjectAccess } from 'generated/types';
import { ASC, colorsPalette, NAME } from 'consts';
import { UsersBuilder } from 'utils/usersBuilder';

import { ColorPicker } from '../ColorPicker';
import { ScrollToError } from '../ScrollToError';

import styles from './styles.module.scss';

interface PMFinAccess {
  id: ProjectAccess;
  name: string;
  description: string;
}

export interface ProjectFormValues {
  name: string;
  client: Client | null;
  color: string;
  pm: Member | null;
  pmFinAccess: PMFinAccess;
  pmFinAccessStart: string | Date | null;
  pmFinAccessEnd: string | Date | null;
}

interface NewProjectProps {
  onSubmit: (values: ProjectFormValues) => void | Promise<void>;
  id?: string;
  client?: Client;
  pm?: Member;
  name?: string;
  color?: string;
  submitLabel?: string;
  pmFinAccessId?: ProjectAccess;
  pmFinAccessStart?: string;
  pmFinAccessEnd?: string;
  onCancel: () => void;
}

export const NewProject: FC<NewProjectProps> = ({
  id,
  name,
  pm,
  submitLabel,
  client,
  color,
  pmFinAccessId,
  pmFinAccessStart,
  pmFinAccessEnd,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId: id });
  const { hasAccess: hasGeneralAccess } = usePermissions();

  const pmFinAccessTypes = useMemo(
    () => [
      {
        id: ProjectAccess.NonAccess,
        name: t('forms.newProject.financialAccess.none'),
        description: t('forms.newProject.financialAccess.noneDescription'),
      },
      {
        id: ProjectAccess.RatesAccess,
        name: t('forms.newProject.financialAccess.ratesOnly'),
        description: t('forms.newProject.financialAccess.ratesOnlyDescription'),
      },
      {
        id: ProjectAccess.FullAccess,
        name: t('forms.newProject.financialAccess.fullAccess'),
        description: t('forms.newProject.financialAccess.fullAccessDescription'),
      },
    ],
    [],
  );

  const { clients, clientsLoading, getCreatedClient } = useCreatableClientData();

  const { data: { users = [] as Member[] } = {}, loading: projectManagersLoading } = useProjectManagersQuery({
    skip: !hasAccess(ActionsType.CreateProjects),
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const getManagersOptionName = ({ first_name, last_name }: Member) =>
    getFullName(first_name, last_name, t('notApplicable'));

  const sortedClient = useMemo(() => sortByField(clients, ASC, NAME), [clients]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        client: Yup.object().nullable().required(t('forms.newProject.clientRequiredError')),
        name: Yup.string()
          .required(t('forms.newProject.projectNameRequired'))
          .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
        pm: Yup.object().nullable().required(t('forms.newProject.projectManagerRequiredError')),
        pmFinAccess: Yup.object().nullable().required(t('forms.newProject.financialAccess.requiredError')),
        pmFinAccessStart: Yup.string().nullable(),
        pmFinAccessEnd: Yup.string().nullable(),
      }),
    [],
  );

  const projectManagers = useCallback(() => {
    const usersBuilder = new UsersBuilder(users as CompanyUser[]);

    usersBuilder.sortMembersByName();
    usersBuilder.buildRestoredUsers();
    usersBuilder.buildNonEmployeeUsers();

    return usersBuilder.getUsers().map(({ member }) => member);
  }, [users]);

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    const client = await getCreatedClient(values.client);
    submitForm({ ...values, client }, setSubmitting, onSubmit);
  }, []);

  if (isPermissionsLoading) return <AbsoluteSpinner />;

  if (
    !hasAccess(ActionsType.EditActiveProjects) &&
    !hasAccess(ActionsType.EditArchivedProjects) &&
    !hasAccess(ActionsType.CreateProjects)
  ) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <Formik<ProjectFormValues>
      validationSchema={validationSchema}
      initialValues={{
        client: client ?? null,
        pm: pm ?? null,
        name: name ?? '',
        color: color ?? sample(colorsPalette)!,
        pmFinAccess: pmFinAccessTypes.find((item) => item.id === pmFinAccessId) ?? pmFinAccessTypes[0],
        pmFinAccessStart: pmFinAccessStart ? removeUTCTimezone(pmFinAccessStart) : '',
        pmFinAccessEnd: pmFinAccessEnd ? removeUTCTimezone(pmFinAccessEnd) : '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitCount, touched, errors, values, setFieldValue, setValues, handleBlur }) => (
        <Form className="form">
          <div className="pb-24">
            <div className="flex">
              <ColorPicker color={values.color} onChange={(color: string) => setFieldValue('color', color)} />
              <div className="ml-16 flex-1">
                <InputLabel required>{t('forms.newProject.projectName')}</InputLabel>
                <Field component={TextField} name="name" type="text" className="mb-24" />
              </div>
            </div>

            <InputLabel required>{t('forms.newProject.client')}</InputLabel>
            <Field>
              {({
                form: {
                  values: { client },
                },
              }: FieldProps<ProjectFormValues['client'], ProjectFormValues>) => (
                <CreatableAutocomplete
                  placeholder={t('forms.newProject.selectClient')}
                  className="mb-24"
                  name="client"
                  onBlur={handleBlur}
                  value={client}
                  error={touched.client ? errors.client : undefined}
                  options={clientsLoading && client ? [client] : sortedClient}
                  onChange={(client: ProjectFormValues['client']) => setValues({ ...values, client })}
                  enableCreation={hasGeneralAccess(ActionsType.CreateClients)}
                />
              )}
            </Field>

            <div className={styles.pmBox}>
              <div className="flex-1">
                <InputLabel required>{t('forms.newProject.projectManagerLabel')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { pm },
                    },
                  }: FieldProps<ProjectFormValues['pm'], ProjectFormValues>) => (
                    <Autocomplete
                      value={pm}
                      error={touched.pm ? errors.pm : undefined}
                      name="pm"
                      onBlur={handleBlur}
                      placeholder={t('forms.newProject.projectManagerPlaceholder')}
                      getOptionLabel={getManagersOptionName}
                      options={projectManagersLoading && pm ? pm : projectManagers()}
                      onChange={(pm: ProjectFormValues['pm']) => setValues({ ...values, pm })}
                      disabled={!hasAccess(ActionsType.ShareProject)}
                    />
                  )}
                </Field>
              </div>

              {hasAccess(ActionsType.ShareProject) && (
                <div className="flex-1">
                  <InputLabel required>{t('forms.newProject.financialAccess.label')}</InputLabel>
                  <Field>
                    {({
                      form: {
                        values: { pmFinAccess },
                      },
                    }: FieldProps<ProjectFormValues['pmFinAccess'], ProjectFormValues>) => (
                      <Autocomplete
                        value={pmFinAccess}
                        error={touched.pmFinAccess ? errors.pmFinAccess : undefined}
                        name="pmFinAccess"
                        onBlur={handleBlur}
                        placeholder={t('forms.newProject.financialAccess.placeholder')}
                        options={pmFinAccessTypes}
                        renderOption={(item: ProjectFormValues['pmFinAccess']) => (
                          <div className="flex flex-column">
                            {item.name}
                            <span className={styles.selectOptionDescription}>{item.description}</span>
                          </div>
                        )}
                        onChange={(pmFinAccess: ProjectFormValues['pmFinAccess']) =>
                          setValues({ ...values, pmFinAccess })
                        }
                      />
                    )}
                  </Field>
                </div>
              )}
            </div>
            {hasAccess(ActionsType.ShareProject) && (
              <ProjectPMAccessDate submitCount={submitCount} errors={errors} setValues={setValues} values={values} />
            )}
          </div>
          <div className="controls">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('forms.create')}
            </LoadingButton>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {t('forms.cancel')}
            </Button>
          </div>
          <ScrollToError isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
