import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AbsoluteSpinner, AssignmentForm, AssignmentFormValues, EmptyState, RequestInfoModal } from 'components';
import { useAuth } from 'contexts';
import { useCreateRequest, useIsOpen, usePermissions } from 'hooks';
import { ActionsType, RequestAction, RequestDomains } from 'generated/types';
import { Button, IconButton, TextField } from '@material-ui/core';
import { ArrowDown, PlusIcon } from 'icons';
import { localStorageManager } from 'services';
import clsx from 'clsx';
import { IS_HIDE_REQUEST_CONFIRM } from 'consts';
import { getAssignmentRequestFormattedFormData } from 'utils';

import styles from './styles.module.scss';

interface CreateAssignmentProps {
  memberId?: string;
  projectId?: string;
  contractId?: string;
  onCancel: () => void;
  onHide?: () => void;
  onCreate?: () => void;
  memberDisabled?: boolean;
  projectDisabled?: boolean;
  submitLabel?: string;
}

export const CreateAssignmentRequest: FC<CreateAssignmentProps> = ({
  memberId,
  projectId,
  contractId,
  onCancel,
  onHide,
  onCreate,
  memberDisabled,
  projectDisabled,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [note, setNote] = useState<string>('');
  const [isShowNote, onShowNote, onHideNote] = useIsOpen(false);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen(false);

  const handleCloseConfirm = () => {
    onCloseConfirm();
    localStorageManager.setItem(IS_HIDE_REQUEST_CONFIRM, true);
  };

  const [createAssignmentRequest, { loading }] = useCreateRequest({
    onCompleted: () => {
      const isShowConfirm = !localStorageManager.getItem(IS_HIDE_REQUEST_CONFIRM);
      onCreate?.();
      onHide?.();
      isShowConfirm ? onOpenConfirm() : onCancel();
    },
  });

  const onCreateAssignment = useCallback(
    (formValues: AssignmentFormValues) => {
      const data = {
        variables: {
          data: {
            note: note,
            data: getAssignmentRequestFormattedFormData(formValues),
          },
          requestAction: RequestAction.Create,
          entityType: RequestDomains.Assignment,
          companyId: userData!.company.id,
        },
      };
      createAssignmentRequest(data);
    },
    [note],
  );

  if (isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!hasAccess(ActionsType.SubmitAssignmentRequest)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <AssignmentForm
        memberId={memberId}
        projectId={projectId}
        contractId={contractId}
        onCancel={onCancel}
        onSubmit={onCreateAssignment}
        memberDisabled={memberDisabled}
        projectDisabled={projectDisabled}
        submitLabel={t('request.button.sendRequest')}
        submitDisabled={loading}
        showRequestDescription
        additionalControlsContent={
          <div className={clsx('relative', !isShowNote && 'd-none')}>
            <TextField
              className={styles.noteTextarea}
              id="note-text-field"
              placeholder={t('request.notePlaceholder')}
              multiline
              rows={5}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <IconButton className={styles.hideNoteButton} onClick={onHideNote}>
              <ArrowDown />
            </IconButton>
          </div>
        }
        additionalButton={
          <Button
            variant="text"
            className={clsx(styles.noteButton, isShowNote && 'd-none')}
            classes={{
              startIcon: styles.addIcon,
            }}
            startIcon={note ? <ArrowDown className={styles.showNoteIcon} /> : <PlusIcon />}
            onClick={onShowNote}
          >
            {t('request.button.noteForAdmin')}
          </Button>
        }
      />

      <RequestInfoModal
        isOpen={isOpenConfirm}
        onClose={() => {
          handleCloseConfirm();
          onCancel();
        }}
      />
    </>
  );
};
