import React from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import { Button, ButtonGroup } from '@material-ui/core';
import { links } from 'App';
import { ProjectModals, ProjectsTabs, ProjectsViews, RouteProps } from 'types';
import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { CommonActions, IntegrationModal, TabItem, Tabs, ViewHeading } from 'components';
import { ContractsView, ProjectsView } from './parts';
import { usePermissions } from 'hooks';
import { ActionsType, ImportType } from 'generated/types';
import { UploadFile } from 'icons';

import clsx from 'clsx';
import styles from './styles.module.scss';

const ProjectsRoute = Route(
  {
    search: Route.query.string,
    view: Route.params.oneOf(...Object.values(ProjectsViews)).optional,
    tab: Route.params.oneOf(...Object.values(ProjectsTabs)).optional,
    client: Route.query.array(Route.query.string),
    pm: Route.query.array(Route.query.string),
    type: Route.query.array(Route.query.string),
    modal: Route.query.oneOf(...Object.values(ProjectModals)),
  },
  ({ view, tab }) => `/projects/${view}/${tab}`,
);

const Projects = ({
  match: {
    query,
    query: { modal },
    params,
    params: { view = ProjectsViews.projects },
  },
  link,
  history: { push },
}: RouteProps) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  useSetAppTitle(t('navigation.projects'));
  const onChangeView = (view: ProjectsViews) => push(link({ ...query, ...params, view }));
  const onCloseModal = () => push(link({ ...query, ...params, modal: undefined }));

  return (
    <>
      <ViewHeading hasSmartBackBtn={false} label={t('projects.label')} className={styles.viewHeading} />
      <div className="flex px-30 py-10">
        <div>
          <ButtonGroup aria-label="contract project switch button group" className="button-group">
            <Button
              color="inherit"
              variant="text"
              onClick={() => onChangeView(ProjectsViews.projects)}
              className={clsx('button-group-item', view === ProjectsViews.projects && 'button-group-item-active')}
            >
              {t('projects.projectsView')}
            </Button>
            <Button
              color="inherit"
              variant="text"
              onClick={() => onChangeView(ProjectsViews.contracts)}
              className={clsx('button-group-item', view === ProjectsViews.contracts && 'button-group-item-active')}
            >
              {t('contracts.contractsView')}
            </Button>
          </ButtonGroup>
        </div>

        <section className="flex gap-8 ml-auto">
          {hasAccess(ActionsType.CreateAssignments) && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<UploadFile />}
              onClick={() => push(link({ ...query, ...params, modal: ProjectModals.integration }))}
            >
              {view === ProjectsViews.projects ? t('projects.importProjects') : t('contracts.importContracts')}
            </Button>
          )}

          <CommonActions
            menuItemsOrder={['client', 'project', 'contract', 'assignment', 'requestAssignment', 'teamMember', 'leave']}
            refetchProjectsAction={['ManagedProjects']}
            refetchContractsAction={['Contracts', 'ContractsMargins', 'ContractsListData']}
            refetchMembersAction={['TeamMembers']}
          />
        </section>
      </div>
      <Tabs className="tabs-box">
        {hasAccess(ActionsType.ViewActiveProjects) && (
          <TabItem route={links.Projects({ view, tab: ProjectsTabs.active })}>{t('projects.active')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewArchivedProjects) && (
          <TabItem route={links.Projects({ view, tab: ProjectsTabs.archived })}>{t('projects.archived')}</TabItem>
        )}
      </Tabs>

      <Switch>
        <ProjectsView />
        <ContractsView />
      </Switch>

      <IntegrationModal
        title={view === ProjectsViews.projects ? t('projects.importProjects') : t('contracts.importContracts')}
        importType={view === ProjectsViews.projects ? ImportType.Project : ImportType.Contract}
        isOpen={hasAccess(ActionsType.CreateProjects) && modal === ProjectModals.integration}
        onClose={onCloseModal}
      />
    </>
  );
};

export default ProjectsRoute(Projects);
