import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';
import { ModalModeEnum } from 'types';

export const MilestonesRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    milestoneId: Route.params.regex(idRegEx).optional,
    contractId: Route.params.regex(idRegEx).optional,
    mode: Route.query.oneOf(...Object.values(ModalModeEnum)),
  },
  ({ id, milestoneId, contractId }) => `/project/view/${id}/${contractId}/milestones/${milestoneId}`,
);

export const Milestones = MilestonesRoute(lazy(() => import(/* webpackChunkName: "Milestones" */ './Milestones')));
