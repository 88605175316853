import { ContractFormValues } from 'components';
import { format } from 'date-fns';
import { MM_DD_YYYY } from 'consts';

export const getContractName = (values: ContractFormValues, t: (value: string) => string) => {
  return `${t(`projectType.${values.type.id}`)} — ${format(new Date(values.start_date), MM_DD_YYYY)}–${format(
    new Date(values.end_date),
    MM_DD_YYYY,
  )} — ${values.project?.name}`;
};
