export enum ModuleName {
  auth = 'auth',
  insights = 'insights',
  team = 'team',
  projects = 'projects',
  contracts = 'contracts',
  files = 'files',
  billing = 'billing',
  resourcePlanning = 'resource-planning',
  scenarios = 'scenarios',
  activity = 'activity',
  settings = 'settings',
  employeePortal = 'employee-portal',
}

export enum ScreenName {
  list = 'list',
  assignments = 'assignments',
  activityHistory = 'activity-history',
  financial = 'financial',
  timeTracking = 'time-tracking',
  skills = 'skills',
  overview = 'overview',
  expenses = 'expenses',
  files = 'files',
  billing = 'billing',
  milestones = 'milestones',
  team = 'team',
  projects = 'projects',
  scenario = 'scenario',
  scenarios = 'scenarios',
  requests = 'requests',
  users = 'users',
  integrations = 'integrations',
  projectSettings = 'project-settings',
  teamSettings = 'team-settings',
  resourceManagement = 'resource-management',
  utilization = 'utilization',
  compensation = 'compensation',
  bench = 'bench',
  login = 'login',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
}

export enum ProjectTrackType {
  fixedPrice = 'fixed-price',
  nonBillable = 'non-billable',
  retainer = 'retainer',
  timeAndMaterial = 'time-and-material',
}

export enum ManagerTrackAccess {
  fullAccess = 'full',
  nonAccess = 'none',
  ratesAccess = 'rates-only',
}

export enum BillingTrackType {
  allocation = 'allocated-time',
  timeLogs = 'logged-time',
}

export enum TimeTrackEntryType {
  'hrsPerDay' = 'hours-per-day',
  'totalHrs' = 'total',
}
