import React, { FC, useEffect, useMemo } from 'react';
import { Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  AbsoluteSpinner,
  EmptyState,
  NewProject,
  ProjectFormValues,
  RightDrawer,
  TabItem,
  Tabs,
  ViewHeading,
} from 'components';
import { links } from 'App';
import { useAuth } from 'contexts';
import { addTimezoneOffset, graphqlOnError } from 'utils';
import { ActionsType, Client, Member, ProjectType } from 'generated/types';
import {
  ProjectMembershipsDocument,
  useContractQuery,
  useEditProjectMutation,
  useExchangeRateQuery,
  useProjectByIdQuery,
} from 'generated/graphql';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions } from 'hooks';
import { ProjectDetailRoute, projectLinks, SharedProjects, useRedirectWithoutPermissions } from 'views/ProjectDetail';
import { TimeTracking } from './TimeTracking';
import { Redirect } from 'react-router-dom';
import { GetRouteProps } from 'types/utils/router';
import { client } from 'graphql-client';

import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { RateCard } from 'views/ProjectDetail/RateCard';
import { DrawerQueries, InsightsTabs } from 'types';
import { ActivityHistory } from '../ActivityHistory';
import { Assignments } from './Assignments';
import { Expenses } from './Expenses';
import { Scenarios } from './Scenarios';
import { Files } from './Files';
import { Billing } from './Billing';
import { LeavesBalance } from './LeavesBalance';
import { Milestones } from './Milestones';
import { HeaderButtons, Heading } from './components';
import { ContractSection } from './components/ContractSection';
import { ChartType } from 'views/Insights/Projects/consts';

import styles from './ProjectDetail.module.scss';
import clsx from 'clsx';

type Props = GetRouteProps<typeof ProjectDetailRoute>;

export const ProjectDetail: FC<Props> = ({
  match: {
    params: { id, contractId },
    params,
    query: { mode, leaveRule },
    query,
  },
  history: { push },
  link,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId: id });
  const { startAccessDate, endAccessDate, loadingProjectMembership } = useRedirectWithoutPermissions(id, push);
  const { isMobileDevice } = useDeviceTypeByWidth();
  const { data: { project } = {}, loading } = useProjectByIdQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId: id,
      withCommission: true,
    },
    skip: isPermissionsLoading,
  });
  useSetAppTitle(project && `${project?.name}`);

  const {
    data: { contract } = {},
    previousData: { contract: previousContract } = {},
    loading: loadingContract,
  } = useContractQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      contractId,
      projectId: id,
      withCommission: true,
    },
  });

  useEffect(() => {
    if (!contractId && contract?.id) {
      push(link({ ...params, ...query, contractId: contract.id }));
    }
  }, [contract?.id]);

  const isFixedPriceContract = contract?.type === ProjectType.FixedPrice;
  const isNonBillableContract = contract?.type === ProjectType.NonBillable;
  const isContractCurrencySameAsCompany = useMemo(() => {
    if (!contract?.feeCurrencyId) return false;

    return contract?.feeCurrencyId === userData?.company.primaryCurrencyId;
  }, [contract?.feeCurrencyId, userData?.company.primaryCurrencyId]);

  const { data: { exchangeRate } = {} } = useExchangeRateQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      baseCurrencyId: userData!.company.primaryCurrencyId || '',
      exchangeCurrencyId: contract?.feeCurrencyId || '',
    },
    skip: isContractCurrencySameAsCompany || loading || !contract?.feeCurrencyId,
  });

  const [editProject] = useEditProjectMutation({
    onCompleted() {
      toast.success(t('projects.projectEditSuccessfully'));
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(_, { data }) {
      if (data?.editProject.pm.id !== project?.pm.id) {
        client.refetchQueries({ include: [ProjectMembershipsDocument] });
      }
    },
    refetchQueries: ['contractAssignmentsList', 'profitability'],
  });

  const handleDialogOpen = (
    mode:
      | DrawerQueries.rateCardMode
      | DrawerQueries.activityHistoryMode
      | DrawerQueries.leavesBalanceMode
      | DrawerQueries.editMode
      | DrawerQueries.shareProjectsMode,
  ) => push(link({ ...params, ...query, mode }));
  const handleDialogClose = () => push(link({ ...params, ...query, mode: undefined, leaveRule: undefined }));

  const updateProject = async ({
    pm,
    client,
    pmFinAccess,
    pmFinAccessStart,
    pmFinAccessEnd,
    ...values
  }: ProjectFormValues) => {
    await editProject({
      variables: {
        data: {
          ...values,
          client: client?.id ?? '',
          pm: pm?.id ?? '',
          pmFinAccess: pmFinAccess.id,
          pmAccessStart: pmFinAccessStart ? addTimezoneOffset(new Date(pmFinAccessStart)) : null,
          pmAccessEnd: pmFinAccessEnd ? addTimezoneOffset(new Date(pmFinAccessEnd)) : null,
        },
        projectId: id,
        companyId: userData!.company.id,
      },
    });
  };

  if (loading || isPermissionsLoading || loadingContract || loadingProjectMembership) {
    return <AbsoluteSpinner />;
  }

  if (!project) {
    return <EmptyState />;
  }

  return (
    <>
      <ViewHeading
        headingClassName={styles.heading}
        className={styles.headingBox}
        label={<Heading project={project} />}
      >
        <HeaderButtons
          contract={contract}
          project={project}
          projectId={id}
          handleDialogOpen={handleDialogOpen}
          onInsightsRedirect={() =>
            push(
              links.Insights({
                tab: InsightsTabs.projects,
                type: ChartType.profitability,
                projectId: id,
                contractId: contract?.id,
              }),
            )
          }
        />
      </ViewHeading>

      <Tabs className={clsx('tabs-box', styles.tab)}>
        {isFixedPriceContract && hasAccess(ActionsType.ProjectMilestones) && (
          <TabItem route={projectLinks.Milestones({ id, contractId })}>
            {t('viewProjectDetail.milestones.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewProjectAssignments) && (
          <TabItem route={projectLinks.Assignments({ projectId: id, contractId })}>
            {t('viewProjectDetail.assignments.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewExpenses) && (
          <TabItem route={projectLinks.Expenses({ id, contractId })}>{t('viewProjectDetail.expenses.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewTimelogs) && (
          <TabItem exact={false} route={projectLinks.TimeTracking({ id, contractId })}>
            {t('timeTracking.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewDocuments) && (
          <TabItem route={projectLinks.Files({ id })}>{t('projectFile.label')}</TabItem>
        )}
        {!isNonBillableContract && hasAccess(ActionsType.BillingReport) && (
          <TabItem route={projectLinks.Billing({ id, contractId })}>{t('billing.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewScenarios) && !isMobileDevice && (
          <TabItem route={projectLinks.Scenarios({ id, contractId })}>{t('scenarios.title')}</TabItem>
        )}

        <ContractSection contract={contract || previousContract} />
      </Tabs>

      {!contract ? (
        <EmptyState className="mt-40" title="contracts.emptyState" />
      ) : (
        <Switch>
          {isFixedPriceContract && (
            <Milestones
              contract={contract}
              isProjectCurrencySameAsCompany={isContractCurrencySameAsCompany}
              exchangeRate={exchangeRate}
            />
          )}
          <Assignments
            startAccessDate={startAccessDate}
            endAccessDate={endAccessDate}
            exact
            contract={contract}
            isContractCurrencySameAsCompany={isContractCurrencySameAsCompany}
            exchangeRate={exchangeRate}
          />
          <Expenses exact contract={contract} />
          <TimeTracking />
          <Files />
          <Scenarios project={project} />
          {!isNonBillableContract && <Billing contract={contract} project={project} />}
          <Redirect
            to={
              isFixedPriceContract && hasAccess(ActionsType.ProjectMilestones)
                ? projectLinks.Milestones({ ...params })
                : projectLinks.Assignments({ projectId: id, contractId })
            }
          />
        </Switch>
      )}

      <RightDrawer
        direction="right"
        open={mode === DrawerQueries.editMode}
        onClose={handleDialogClose}
        title={t('projects.editProject')}
        paperClassName={styles.editProjectPaper}
      >
        <NewProject
          id={project.id}
          client={project.client as Client}
          name={project.name}
          pm={project.pm as Member}
          color={project.color}
          pmFinAccessId={project.pmFinAccess?.accessLevel}
          pmFinAccessStart={project.pmFinAccess?.startDate}
          pmFinAccessEnd={project.pmFinAccess?.endDate}
          onSubmit={updateProject}
          onCancel={handleDialogClose}
          submitLabel={t('actions.save')}
        />
      </RightDrawer>
      {contract && (
        <RateCard
          isOpen={mode === DrawerQueries.rateCardMode}
          onClose={handleDialogClose}
          contract={contract}
          clientName={project.client?.name}
        />
      )}
      {contractId && (
        <LeavesBalance
          isOpen={mode === DrawerQueries.leavesBalanceMode || !!leaveRule}
          onClose={handleDialogClose}
          projectId={id}
          contractId={contractId}
          defaultLeaveRule={leaveRule}
        />
      )}
      {contract && (
        <ActivityHistory
          isOpen={mode === DrawerQueries.activityHistoryMode}
          onClose={handleDialogClose}
          contractId={contract.id}
          currencyCode={contract.fee_currency || ''}
          isShowMemberField
        />
      )}
      <SharedProjects
        isOpen={mode === DrawerQueries.shareProjectsMode}
        onClose={handleDialogClose}
        projectId={project.id}
        pmId={project.pm.id}
        isNonBillableProject={contract?.type === ProjectType.NonBillable}
      />
    </>
  );
};
