import { useCallback } from 'react';
import { ManagedProjectsDocument, ProjectByIdDocument, useRestoreProjectMutation } from 'generated/graphql';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';

export const useRestoreProject = (id?: string) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [restoreProject] = useRestoreProjectMutation({
    async onCompleted() {
      toast.success(t('projects.projectsRestoredSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: [
      ManagedProjectsDocument,
      ...(id
        ? [
            {
              query: ProjectByIdDocument,
              variables: {
                companyId: userData!.company.id,
                projectId: id,
              },
            },
          ]
        : []),
    ],
  });

  return useCallback(
    (id: string) =>
      restoreProject({
        variables: {
          companyId: userData!.company.id,
          projectId: id,
        },
      }),
    [],
  );
};
