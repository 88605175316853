import { ReportItemDataFragment } from 'generated/types';
import { isAfter } from 'date-fns';

const NO_PROJECT = 'noProject';
const NO_PM = 'noPM';

export const groupReportsByProject = (data: ReportItemDataFragment[]) =>
  data
    .sort((a, b) => (a.contract?.projectName || '').localeCompare(b.contract?.projectName || '', 'en'))
    .reduce((acc: Record<string, ReportItemDataFragment[]>, item) => {
      const projectId: string = item?.contract?.projectId ?? NO_PROJECT;
      acc[projectId] = acc[projectId] || [];
      acc[projectId].push(item);
      return acc;
    }, {});

export const groupReportsByPM = (data: ReportItemDataFragment[]) =>
  data
    .sort((a, b) => {
      return (a.contract?.projectPM || '').localeCompare(b.contract?.projectPM || '', 'en');
    })
    .reduce((acc: Record<string, ReportItemDataFragment[]>, item) => {
      const pmId: string = item?.contract?.projectPmId ?? NO_PM;
      acc[pmId] = acc[pmId] || [];
      acc[pmId].push(item);
      return acc;
    }, {});

export const groupReportsByPeriod = (data: ReportItemDataFragment[]) =>
  data
    .sort((a, b) => (isAfter(new Date(a.startDate), new Date(b.startDate)) ? -1 : 1))
    .reduce((acc: Record<string, ReportItemDataFragment[]>, item) => {
      const period: string = item?.startDate?.slice(0, 7);
      acc[period] = acc[period] || [];
      acc[period].push(item);
      return acc;
    }, {});
